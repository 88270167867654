export function parseTime(time, cFormat = '{y}-{m}-{d} {h}:{i}:{s}') {
  if (arguments.length === 0) {
    return;
  }
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (('' + time).length === 10) time = Number.parseInt(time) * 1000;
    date = new Date(time);
  }
  const formatObject = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeString = format.replace(/{([adhimsy])+}/g, (result, key) => {
    let value = formatObject[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }
    return value || 0;
  });
  return timeString;
}

export function formatTime(time, option) {
  time = +time * 1000;
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }
  return option
    ? parseTime(time, option)
    : d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
}

export function formatDateTime(date) {
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? '0' + m : m;
  let d = date.getDate();
  d = d < 10 ? '0' + d : d;
  let h = date.getHours();
  h = h < 10 ? '0' + h : h;
  let minute = date.getMinutes();
  minute = minute < 10 ? '0' + minute : minute;
  let second = date.getSeconds();
  second = second < 10 ? '0' + second : second;
  return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
}

// 格式化时间
export function getQueryObject(url) {
  url = url == undefined ? window.location.href : url;
  const search = url.slice(Math.max(0, url.lastIndexOf('?') + 1));
  const object = {};
  const reg = /([^&=?]+)=([^&=?]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let value = decodeURIComponent($2);
    value = String(value);
    object[name] = value;
    return rs;
  });
  return object;
}

/**
 *get getByteLen
 * @param {Sting} val input value
 * @return {number} output value
 */
export function getByteLen(value) {
  let length_ = 0;
  for (const element of value) {
    length_ += element.match(/[^\u0000-\u00FF]/gi) != undefined ? 1 : 0.5;
  }
  return Math.floor(length_);
}

export function cleanArray(actual) {
  const newArray = [];
  for (const element of actual) {
    if (element) {
      newArray.push(element);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json) return '';
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return '';
      return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
    })
  ).join('&');
}

export function param2Obj(url) {
  const search = url.split('?')[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
}

export function html2Text(value) {
  const div = document.createElement('div');
  div.innerHTML = value;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  /* Merges two  objects,
       giving the last one precedence */

  if (typeof target !== 'object') {
    target = {};
  }
  if (Array.isArray(source)) {
    return [...source];
  }
  for (const property of Object.keys(source)) {
    const sourceProperty = source[property];
    if (typeof sourceProperty === 'object') {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  }
  return target;
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.slice(0, Math.max(0, nameIndex)) + classString.slice(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [
  {
    text: '今天',
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一周',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近一个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit('pick', [start, end]);
    },
  },
  {
    text: '最近三个月',
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit('pick', [start, end]);
    },
  },
];

export function getTime(type) {
  return type === 'start' ? Date.now() - 3600 * 1000 * 24 * 90 : new Date(new Date().toDateString());
}

export function debounce(function_, wait, immediate) {
  let timeout;
  let arguments_;
  let context;
  let timestamp;
  let result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = Date.now() - timestamp;

    // 上次被包装函数被调用时间间隔last小于设定时间间隔wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = undefined;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = function_.apply(context, arguments_);
        if (!timeout) context = arguments_ = undefined;
      }
    }
  };

  return function (...arguments__) {
    context = this;
    timestamp = Date.now();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = function_.apply(context, arguments__);
      context = arguments__ = undefined;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'shallowClone');
  }
  const targetObject = source.constructor === Array ? [] : {};
  for (const keys of Object.keys(source)) {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObject[keys] = deepClone(source[keys]);
    } else {
      targetObject[keys] = source[keys];
    }
  }
  return targetObject;
}

export function uniqueArr(array) {
  return [...new Set(array)];
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

export function getKeyByValue(object, searchValue) {
  for (const [key, value] of Object.entries(object)) {
    if (value === searchValue) {
      return key;
    }
  }
}
