import request from '@/utils/request';

export function getOperationAndMatters(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getOperationAndMatters',
    method: 'get',
  });
}

export function getResourceUsage(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getResourceUsage',
    method: 'get',
  });
}

export function getAccessGatewayIndicatorMaxNum(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getAccessGatewayIndicatorMaxNum',
    method: 'get',
  });
}

export function getPayLink(groupId, orderId) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getPayLink',
    method: 'get',
    params: {
      orderId: orderId,
    },
  });
}

export function getGatewayIndicatorWarning(groupId, start, end) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getGatewayIndicatorWarning',
    method: 'get',
    params: {
      start: start,
      end: end,
    },
  });
}

export function getOrderId(groupId, setMealType, orderType, number) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getOrderId',
    method: 'get',
    params: {
      setMealType: setMealType,
      orderType: orderType,
      number: number,
    },
  });
}

export function getEquipmentWarningData(groupId, start, end, warningRecordType) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getEquipmentWarningData',
    method: 'get',
    params: {
      start: start,
      end: end,
      warningRecordType: warningRecordType,
    },
  });
}

export function getTotalIotData(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getTotalIotData',
    method: 'get',
  });
}

export function getLeftAccessGatewayIndicatorByGroupId(groupId, gatewayId) {
  return request({
    url: `/iot/groups/${groupId}/projectOverview/getLeftAccessGatewayIndicatorByGroupId`,
    method: 'get',
    params: {
      gatewayId: gatewayId,
    },
  });
}

export function getAllResourcesWarningConfig(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getAllResourcesWarningConfig',
    method: 'get',
  });
}

export function getResourcesWarningConfig(groupId, configId) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/getResourcesWarningConfig',
    method: 'get',
    params: {
      configId: configId,
    },
  });
}

export function setResourcesWarningConfig(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/projectOverview/setResourcesWarningConfig',
    method: 'post',
    data: data,
  });
}
