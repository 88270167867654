<template>
  <!-- v-if="!obj.hidden" -->
  <a-menu-item v-if="!obj.hidden && obj.children == undefined" :key="obj.id" class="menu-class" @click="otherMenu(obj)">
    <template v-if="!parentObj" #icon>
      <iconFont v-if="obj.meta.icon" style="font-size: 24px" :type="obj.meta.icon" />
    </template>
    <router-link v-if="obj.name && obj.customType != 11" :to="{name: obj.name, params: routeParams()}">
      <span :title="obj.meta.title">{{ obj.meta.title }}</span>
    </router-link>
    <span v-else :title="obj.meta.title">
      {{ obj.meta.title }}
    </span>
  </a-menu-item>
  <a-sub-menu v-else-if="!obj.hidden" :key="obj.id" @click="clickMenus(obj)">
    <template #title>
      <span :title="obj.meta.title">{{ obj.meta.title }}</span>
    </template>
    <template v-if="!parentObj" #icon>
      <iconFont v-if="obj.meta.icon" style="font-size: 24px" :type="obj.meta.icon" />
    </template>
    <Menu v-for="child in obj.children" :key="child" :obj="child" :parent-obj="obj" />
  </a-sub-menu>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  name: 'Menu',
  props: {
    obj: Object,
    parentObj: Object,
  },
  computed: {
    ...mapGetters(['subSystem']),
  },
  created() {},
  methods: {
    getChildrenLength(parent) {
      if (parent.children == undefined) {
        return;
      }
      let length = 0;
      for (const children of parent.children) {
        if (!children.hidden) {
          length++;
        }
      }
      if (length == 1) {
        for (const children of parent.children) {
          if (!children.hidden) {
            let subLength = this.getChildrenLength(children);
            if (subLength > 1) {
              length = subLength;
            }
          }
        }
      }
      return length;
    },
    getOnlyChildren(parent) {
      if (parent.children == undefined) {
        return;
      }
      for (const children of parent.children) {
        if (!children.hidden) {
          return children;
        }
      }
    },
    otherMenu(menu) {
      if (menu.customType == 11) {
        this.toWithout(menu);
      } else {
        this.toGroupMenu(menu);
      }
    },
    toWithout(menu) {
      window.open(menu.linkUrl, '_blank');
    },
    toGroupMenu(menu) {
      if (!menu.meta.isGroupMenu || this.subSystem != 4) {
        return;
      }
      this.$store.dispatch('GenerateRoutes', {type: 'group', id: menu.groupId, checkHas: true}).then((response) => {
        if (!response) {
          this.$notification.warning({
            message: '该分组下没有可访问的功能菜单!',
          });
          return;
        }
        let name = this.$route.name;
        if (
          !this.hasRoute(name, {
            groupId: menu.groupId,
          })
        ) {
          name = 'any';
        }
        this.routePush(name, {
          groupId: menu.groupId,
        });
      });
    },
    clickMenus(obj) {
      this.$emit('forceUpdate');
    },
    routeParams(groupId) {
      if (this.$route.params) {
        let parameterObject = {};
        if (this.$route.params.firmId && this.$route.params.firmId != -1) {
          parameterObject = {firmId: this.$route.params.firmId};
        }
        if (this.$route.params.groupId && this.$route.params.groupId != -1) {
          parameterObject = {groupId: this.$route.params.groupId};
        }
        if (groupId) {
          parameterObject = {groupId: groupId};
        }
        return parameterObject;
      } else {
        return;
      }
    },
  },
};
</script>
