import store from '../store';
const getters = {
  token: (state) => state.user.token,
  isLogin: (state) => state.user.exp && state.user.exp - 600 > Date.now() / 1000,
  avatar: (state) => state.user.avatar,
  name: (state) => state.user.name,
  userId: (state) => state.user.userId,
  userType: (state) => state.user.userType,
  routes: (state) => state.user.menu,
  frontMenu: (state) => state.user.frontMenu,
  toptooltitle: (state) => state.app.toptooltitle,
  firmId: (state) => state.user.firmId,
  groupId: (state) => state.user.groupId,
  realname: (state) => state.user.realname,
  subSystem: (state) => state.app.subSystem,
  messageList: (state) => state.app.messageList,
  auditList: (state) => state.app.auditList,
  refreshToken: (state) => state.user.refreshToken,
  listRefreshFlag: (state) => state.app.listRefreshFlag,
  groupMenu: (state) => state.user.groupMenu,
  moveClass: (state) => state.app.moveClass,
  needRefreshMenu: (state) => state.app.needRefreshMenu,
  projectGroupId: (state) => state.user.projectGroupId,
  projectTypeId: (state) => state.user.projectTypeId,
  groupDisPlayName: (state) => state.user.groupDisPlayName,
  logoUrl: (state) => state.app.logoUrl,
  systemLogoUrl: (state) => state.app.systemLogoUrl,
  isShowMessage: (state) => state.user.isShowMessage,
  isMuteMessageSound: (state) => state.user.isMuteMessageSound,
  otherMessageOperate: (state) => state.user.otherMessageOperate,
  warningMessageOperate: (state) => state.user.warningMessageOperate,
  payStatus: (state) => state.app.payStatus,
  systemName: (state) => state.app.systemName,
  curTheme: (state) => state.app.curTheme,
  theme: (state) => state.user.theme,
  lastRoute: (state) => state.user.lastRoute,
  isExceedingGatewayIndicatorLimit: (state) => state.app.isExceedingGatewayIndicatorLimit,
  gatewayIndicatorMaxNum: (state) => state.app.gatewayIndicatorMaxNum,
  setMealEndAt: (state) => state.app.setMealEndAt,
  sendMessagesTotalNum: (state) => state.app.sendMessagesTotalNum,
  gatewayIndicatorExpired: (state) => state.app.gatewayIndicatorExpired,
  canEntryData: (state) => state.app.canEntryData,
  enableMonitor: (state) => state.app.enableMonitor,
  fromCorporationBigScreen: (state) => state.app.fromCorporationBigScreen,
};
export default getters;
