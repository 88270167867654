import request from '@/utils/request';

export function getShareList(groupId, status, projectName, equipmentName, page, size) {
  // 修改密码
  return request({
    url: '/iot/groups/' + groupId + '/shares',
    method: 'get',
    params: {
      status: status,
      projectName: projectName,
      equipmentName: equipmentName,
      page: page,
      size: size,
    },
  });
}

export function addShare(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/shares',
    method: 'post',
    data: data,
  });
}

export function deleteShare(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/shares/' + id,
    method: 'delete',
  });
}

export function getById(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/shares/' + id,
    method: 'get',
  });
}

export function update(groupId, id, data) {
  return request({
    url: '/iot/groups/' + groupId + '/shares/' + id,
    method: 'put',
    data: data,
  });
}

export function useShare(id, targetGroupId, data) {
  return request({
    url: '/iot/groups/' + targetGroupId + '/shares/' + id + '/useShare',
    method: 'post',
    params: {
      groupId: targetGroupId,
    },
    data: data,
  });
}

export function canShare(id, targetGroupId) {
  return request({
    url: '/iot/shares/' + id + '/canShare',
    method: 'get',
    params: {
      groupId: targetGroupId,
    },
  });
}

export function cancelShare(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/shares/' + id + '/cancelShare',
    method: 'put',
  });
}

export function getShareByCode(code) {
  return request({
    url: '/iot/shares/getByCode',
    method: 'get',
    params: {
      code: code,
    },
  });
}
