import request from '@/utils/request';
import axios from 'axios';

export default {
  getPrimaryIndicators: (groupId) => {
    return request({
      url: `/iot/groups/${groupId}/gateways/primaryIndicators`,
      method: 'get',
    });
  },
  getMaintainTaskStatistics: (groupId) => {
    return request({
      url: `/iot/groups/${groupId}/maintainTasks/maintainTaskStatistics`,
      method: 'get',
    });
  },
};

export function addGroupReport(groupId, data) {
  // 添加分组报表
  return request({
    url: '/iot/groups/' + groupId + '/groupReports',
    method: 'post',
    data: data,
  });
}
export function getGroupReportList(groupId) {
  // 获取分组报表
  return request({
    url: '/iot/groups/' + groupId + '/groupReports',
    method: 'get',
  });
}
export function updateGroupReportsOrders(groupId, ids) {
  return request({
    url: '/iot/groups/' + groupId + `/groupReports/batch/${ids}/updateOrders`,
    method: 'put',
  });
}
export function changeGroupReport(groupId, reportId, data) {
  // 修改分组报表
  return request({
    url: '/iot/groups/' + groupId + '/groupReports/' + reportId,
    method: 'put',
    data: data,
  });
}
export function removeGroupReport(groupId, reportId) {
  // 删除分组报表
  return request({
    url: '/iot/groups/' + groupId + '/groupReports/' + reportId,
    method: 'delete',
  });
}

// 查询分组管理分组树列表
export function getGroupListTree(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/listTree',
    method: 'get',
  });
}

// 查询获取分组下拉列表
export function getGroupSelectTree(groupId, includeMonitor = false, deep) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/selectTree',
    method: 'get',
    params: {includeMonitor: includeMonitor, deep: deep},
  });
}

export function getAutoPositionGroup(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getAutoPositionGroup',
    method: 'get',
  });
}

export function getGroupTreeByUser(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/currentUserGroupTree',
    method: 'get',
  });
}
export function getIncludeSubMenuGroupTree(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/groupTree/includeSubMenu',
    method: 'get',
  });
}

export function getGatewayById(groupId, ids) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways' + '/findByIdIn',
    method: 'get',
    params: {
      ids: ids,
    },
  });
}

export function groupFromCopy(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/groups' + '/groupFromCopy',
    method: 'post',
    data: data,
  });
}

export function getAuditGroup(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/auditGroup',
    method: 'get',
  });
}

export function findGroupIdHasSubMenuAndPermission(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/findGroupIdHasSubMenuAndPermission',
    method: 'get',
    params: {
      siteId,
    },
  });
}

export function getDrawingProperty(groupId) {
  return request({
    url: `/iot/groups/${groupId}/groups/drawing_property`,
    method: 'get',
  });
}

export function getFatherNameList(groupId, subGroupId) {
  return request({
    url: `/iot/groups/${groupId}/groups/${subGroupId}/fatherNameList`,
    method: 'get',
  });
}

export function getMonitorIdListWithIntegerName(groupId, indicatorId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getMonitorIdListWithIntegerName',
    method: 'get',
    params: {
      indicatorId,
    },
  });
}
export function getGatewayIndicatorListByIndicatorId(groupId, indicatorId) {
  return request({
    url: '/iot/groups/' + groupId + '/gatewayIndicator',
    method: 'get',
    params: {
      indicatorId,
    },
  });
}

export function getGatewayIndicatorListMapByGroups(groupId, groupIds, indicatorIds, indicatorCodes) {
  return request({
    url: '/iot/groups/' + groupId + '/gatewayIndicator/mapByGroups',
    method: 'get',
    params: {
      groupIds: groupIds.join(','),
      indicatorIds: indicatorIds.join(','),
      indicatorCodes: indicatorCodes ? indicatorCodes.join(',') : undefined,
    },
  });
}

export function getMonitorWithGatewayIndicatorsList(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitorWithGatewayIndicatorsList',
    method: 'get',
  });
}

export function getWaterYieldIndicators(groupId, waterStatusAndCalWay) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/waterYieldIndicators',
    method: 'get',
    params: {
      waterStatusAndCalWay: waterStatusAndCalWay,
    },
  });
}

export function getGroupsWithDesignProcessingCapacity(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getDesignProcessingCapacity',
    method: 'get',
  });
}

export function setDesignProcessingCapacityOfGroups(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/setDesignProcessingCapacity',
    method: 'post',
    data: data,
  });
}

export function queryGroupTreeAndGatewayIndicator(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/queryGroupTreeAndGatewayIndicator',
    method: 'get',
  });
}

// 根据分组id查询分组对应的groupTypeNode
export function getGroupTypeNodeByGroupId(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getGroupTypeNodeByGroupId',
    method: 'get',
  });
}

export function getIndicatorListMapByGroupIdsAndIndicators(groupId, groupIds, indicatorIds) {
  return request({
    url: '/iot/groups/' + groupId + '/gatewayIndicator/mapByGroups',
    method: 'get',
    params: {
      groupIds: groupIds == undefined ? undefined : groupIds.toString(),
      indicatorIds: indicatorIds == undefined ? undefined : indicatorIds.toString(),
    },
  });
}

export function getHistoricalRoute(groupId, ids, start, end) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getHistoricalRoute',
    method: 'get',
    params: {
      start: start,
      end: end,
      groupIds: ids.join(','),
    },
  });
}

export function getProjectGroup(groupId, selectId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/getProjectGroup',
    method: 'get',
    params: {
      selectId: selectId,
    },
  });
}

export function getGroupById(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + id,
    method: 'get',
  });
}
