<template>
  <a-modal
    :open="mapVisible"
    :mask-closable="false"
    title="地图"
    width="70%"
    centered
    @cancel="closeModal"
    @ok="
      () => {
        closeModal();
        submitPosition();
      }
    "
  >
    <Map
      v-if="mapType == 'point'"
      v-model:x-axis="internalXAxis"
      v-model:y-axis="internalYAxis"
      v-model:full-address="internalFullAddress"
      v-model:address="internalAddress"
    />
    <MapAreaComponent v-if="mapType == 'area'" v-model:area="internalArea" />
  </a-modal>
</template>
<script>
import Map from '@/components/map/mapComponent.vue';
import MapAreaComponent from '@/components/map/mapAreaComponent.vue';
export default {
  name: 'MapModal',
  emits: ['update:xAxis', 'update:yAxis', 'update:fullAddress', 'update:address', 'get-xAxis-yAxis', 'update:area', 'change'],
  components: {
    Map,
    MapAreaComponent,
  },
  props: {
    mapType: {
      type: String,
      default: 'point',
    },
    xAxis: Number,
    yAxis: Number,
    fullAddress: String,
    address: Array,
    mapVisible: Boolean,
    area: Array,
  },
  watch: {
    xAxis() {
      this.internalXAxis = this.xAxis;
    },
    yAxis() {
      this.internalYAxis = this.yAxis;
    },
    fullAddress() {
      this.internalFullAddress = this.fullAddress;
    },
    address() {
      this.internalAddress = this.address;
    },
    area() {
      this.internalArea = this.area;
    },
  },
  data() {
    return {
      internalXAxis: this.xAxis,
      internalYAxis: this.yAxis,
      internalFullAddress: this.fullAddress,
      internalAddress: this.address,
      internalArea: this.area,
    };
  },
  methods: {
    closeModal() {
      this.$emit('update:open', false);
    },
    submitPosition() {
      this.$emit('update:xAxis', this.internalXAxis);
      this.$emit('update:yAxis', this.internalYAxis);
      this.$emit('update:fullAddress', this.internalFullAddress);
      this.$emit('update:address', this.internalAddress);
      this.$emit('get-xAxis-yAxis', [this.internalXAxis, this.internalYAxis]);
      this.$emit('update:area', this.internalArea);
      this.$emit('change', {
        xAxis: this.internalXAxis,
        yAxis: this.internalYAxis,
        fullAddress: this.internalFullAddress,
        address: this.internalAddress,
        area: this.internalArea,
      });
    },
  },
};
</script>

<style scoped></style>
