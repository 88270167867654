import request from '@/utils/request';

export function add(data) {
  return request.postForm('/iot/projects', data);
}

export function update(id, data) {
  return request.putForm('/iot/projects/' + id, data);
}

export function deleteProjectById(id, name) {
  return request({
    url: '/iot/projects/' + id,
    method: 'delete',
    params: {
      name: name,
    },
  });
}

export function getProjectByGroupId(id) {
  return request({
    url: '/iot/projects/getProjectByGroupId',
    method: 'get',
    params: {
      groupId: id,
    },
  });
}

export function getProjectListByPage(type, currentPage, pageSize) {
  return request({
    url: '/iot/projects',
    method: 'get',
    params: {
      type: type,
      page: currentPage,
      size: pageSize,
    },
  });
}

export function getMyProjectList(currentPage, pageSize) {
  return request({
    url: '/iot/projects/my',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
    },
  });
}

export function getMyProjectGroupAll(resourceCode) {
  return request({
    url: '/iot/projects/my/byResourceCode',
    method: 'get',
    params: {
      resourceCode: resourceCode,
    },
  });
}

export function querySystemProjectList(projectName, owner, serialNumber, currentPage, pageSize) {
  return request({
    url: '/iot/projects/querySystemProjectList',
    method: 'get',
    params: {
      projectName: projectName,
      owner: owner,
      serialNumber: serialNumber,
      page: currentPage,
      size: pageSize,
    },
  });
}

export function getValidGatewaysSnByGroupId(groupId) {
  return request({
    url: '/iot/projects/getValidGatewaysSnByGroupId',
    method: 'get',
    params: {
      groupId: groupId,
    },
  });
}

export function setIsTemplate(id, isTemplate) {
  return request({
    url: `/iot/projects/${id}/isTemplate`,
    method: 'put',
    params: {
      isTemplate,
    },
  });
}

export function setIsDemo(id, isDemo) {
  return request({
    url: `/iot/projects/${id}/isDemo`,
    method: 'put',
    params: {
      isDemo,
    },
  });
}

export function setCanEntryData(id, canEntryData) {
  return request({
    url: `/iot/projects/${id}/canEntryData`,
    method: 'put',
    params: {
      canEntryData,
    },
  });
}

export function getTemplateFunctions(id) {
  return request({
    url: `/iot/projects/${id}/templateFunctions`,
    method: 'get',
  });
}

export function saveTemplateFunctions(id, data) {
  return request({
    url: `/iot/projects/${id}/templateFunctions`,
    method: 'put',
    data: data,
  });
}

export function detail(id) {
  return request({
    url: '/iot/projects/' + id,
    method: 'get',
  });
}

export function getNavProject(id) {
  return request({
    url: '/iot/projects/nav/' + id,
    method: 'get',
  });
}

export function getProjectIdByProjectGroupId(projectGroupId) {
  return request({
    url: '/iot/projects/getProjectIdByProjectGroupId',
    method: 'get',
    params: {
      projectGroupId: projectGroupId,
    },
  });
}

export function isExist(name, thisId) {
  return request({
    url: '/iot/projects/isExist',
    method: 'get',
    params: {
      name: name,
      thisId: thisId,
    },
  });
}

export function getProjectUserListByParameter(groupId, data) {
  // 条件查询项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users',
    method: 'get',
    params: data,
  });
}

export function addProjectUser(groupId, data) {
  // 新增厂或修改项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users',
    method: 'post',
    data: data,
  });
}
export function changeProjectUser(groupId, userId, data) {
  // 新增厂或修改项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users/' + userId,
    method: 'put',
    data: data,
  });
}

export function removeProjectUser(groupId, userId) {
  // 删除项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users/' + userId,
    method: 'delete',
  });
}

export function getProjectRole(groupId) {
  // 获取项目角色
  return request({
    url: '/iot/groups/' + groupId + '/users/roles',
    method: 'get',
  });
}

export function queryGroupListByProjectId(groupId, currentPage, pageSize) {
  return request({
    url: '/iot/groups/' + groupId + '/groups',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
      sort: 'orders,desc',
    },
  });
}

export function queryGroupListByLikeName(groupId, likeGroupName) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/likeName',
    method: 'get',
    params: {
      likeGroupName: likeGroupName,
    },
  });
}

export function querySiteListByProjectId(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/sites',
    method: 'get',
  });
}
export function queryGroupListByParams(groupId, {type, parentId, includeMonitor, deep}) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/byParams',
    method: 'get',
    params: {
      typeName: type,
      deep: deep,
      parentId: parentId,
      includeMonitor: includeMonitor,
    },
  });
}

export function addGroup(groupId, data) {
  return request.postForm('/iot/groups/' + groupId + '/groups', data);
}

export function importMonitors(projectGroupId, groupId, data, importRule) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/batch',
    method: 'post',
    data: data,
    params: {
      projectGroupId: projectGroupId,
      importRule: importRule,
    },
  });
}

export function getIdAndIdentification(groupId, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/gateway/' + gatewayId,
    method: 'get',
  });
}

export function deleteGroup(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + siteId,
    method: 'delete',
  });
}

export function updateGroup(groupId, siteId, data) {
  return request.putForm('/iot/groups/' + groupId + '/groups/' + siteId, data);
}

export function detailGroup(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + siteId,
    method: 'get',
  });
}

export function getSiteById(groupId, siteId, otherGroupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + siteId + '/includeFileAndImage',
    method: 'get',
    params: {
      otherGroupId,
    },
  });
}

export function getSonNumbers(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + siteId + '/sonNumbers',
    method: 'get',
  });
}

export function getSiteStatus(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + groupId + '/siteStatus',
    method: 'get',
  });
}

export function getGroupOfMode(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/getGroupOfMode',
    method: 'get',
  });
}

export function getMonitorListByGroupId(groupId, onlyDirectChild = false) {
  return request({
    url: '/iot/groups/' + groupId + '/getMonitorListByGroupId',
    method: 'get',
    params: {
      onlyDirectChild,
    },
  });
}

export function getMonitorAndGatewayIndicators(groupId, monitorId) {
  return request({
    url: '/iot/groups/' + groupId + '/getMonitorAndGatewayIndicators',
    method: 'get',
    params: {
      monitorId,
    },
  });
}

export function createMode(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/modes',
    method: 'post',
    data: data,
  });
}

export function createOneMode(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/mode',
    method: 'post',
    data: data,
  });
}

export function updateMode(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/modes/batch',
    method: 'put',
    data: data,
  });
}

export function isModeNameExist(groupId, modeName, modeId) {
  return request({
    url: '/iot/groups/' + groupId + '/isModeNameExist',
    method: 'get',
    params: {
      modeName: modeName,
      modeId: modeId,
    },
  });
}

export function deleteMode(groupId, modeId) {
  return request({
    url: '/iot/groups/' + groupId + '/modes/' + modeId,
    method: 'delete',
  });
}

export function moveGroup(groupId, parentId, isMonitor, moveIds) {
  return request({
    url: '/iot/groups/' + groupId + `/groups/batch/${moveIds}/orders`,
    method: 'put',
    params: {
      parentId,
      isMonitor: isMonitor,
    },
  });
}

export function updateGatewayIndicatorOrders(groupId, monitorId, gatewayIndicators) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + monitorId + '/gatewayIndicatorOrders',
    method: 'put',
    data: gatewayIndicators,
  });
}

export function getGatewayIndicatorsByProjectId(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/gatewayIndicators',
    method: 'get',
  });
}

export function getExportGatewayIndicatorsByGroupId(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/exportGatewayIndicators',
    method: 'get',
  });
}

export function getSite(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/site/' + siteId,
    method: 'get',
  });
}

export function queryMonitorList(likeMonitorName, siteId, groupId, currentPage, pageSize) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/includeParentNameAndIdList',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
      likeMonitorName: likeMonitorName,
      siteId: siteId,
    },
  });
}
export function queryAllMonitorList({groupId, siteId, likeMonitorName}) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/allIncludeParentNameAndIdList',
    method: 'get',
    params: {
      likeMonitorName: likeMonitorName,
      siteId: siteId,
    },
  });
}

export function queryMonitorTree(groupId, siteId, likeMonitorName) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/getMonitorTree',
    method: 'get',
    params: {
      siteId: siteId,
      likeMonitorName: likeMonitorName,
    },
  });
}

export function detailMonitor(groupId, monitorId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/' + monitorId,
    method: 'get',
  });
}

export function getIndicatorList(groupId, monitorId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/indicatorList/inMonitor/' + monitorId,
    method: 'get',
  });
}

export function queryAllMonitorIndicatorListByGroupId(groupId, selectedGroupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gatewayIndicator/getListWithWarningRecord',
    method: 'get',
    params: {
      selectedGroupId,
    },
  });
}

export function getIndicatorListBySite(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways//indicatorList/inSite/' + siteId,
    method: 'get',
  });
}

export function autoCreateMonitor(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/autoCreateMonitor',
    method: 'post',
  });
}

export function getGatewayTree(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/monitor/gatewayTree',
    method: 'get',
  });
}

export function updateGatewayIndicator(groupId, monitorId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + monitorId + '/monitor/indicators',
    method: 'put',
    data: data,
  });
}

export function checkIfCanMoveToLeft(groupId, monitorId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + monitorId + '/monitor/checkIfCanMoveToLeft',
    method: 'put',
    data: data,
  });
}

export function isGroupExist(groupId, id, name, parentId, isMonitor) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/isGroupExist',
    method: 'get',
    params: {
      siteId: id,
      name: name,
      parentId: parentId,
      isMonitor: isMonitor,
    },
  });
}

export function uploadFiles(groupId, file) {
  return request({
    method: 'post',
    url: '/iot/groups/' + groupId + '/groups/' + groupId + '/upload',
    headers: {},
    data: file,
  });
}
export function removeFile(groupId, fileId) {
  return request({
    method: 'delete',
    url: '/iot/groups/' + groupId + '/groups/' + groupId + '/removeFile/' + fileId,
  });
}

// 查询大屏的地图
export function bigScreen(groupId) {
  let url = `/iot/groups/${groupId}/bigScreen/map`;
  return request({
    url: url,
    method: 'get',
  });
}
// 查询农业大屏的地图
export function getSmartAgricultureMap(groupId) {
  let url = `/iot/groups/${groupId}/bigScreen/getSmartAgricultureMap`;
  return request({
    url: url,
    method: 'get',
  });
}
// 查询液位大屏地图
export function getPipeLineNetworkMap(groupId, projectIds) {
  let url = `/iot/groups/${groupId}/bigScreen/getPipeLineNetworkMap`;
  return request({
    url: url,
    method: 'get',
    params: {
      projectIds: projectIds == undefined ? undefined : projectIds.toString(),
    },
  });
}

export function getEmissionStandard(groupId, subGroupId) {
  let url = `/iot/groups/${groupId}/bigScreen/getEmissionStandard`;
  return request({
    url: url,
    method: 'get',
    params: {
      subGroupId,
    },
  });
}

// 地图站点任务
export function siteStatusOfMap(groupId, {siteId}) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/siteStatusOfMap',
    method: 'get',
    params: {
      siteId,
    },
  });
}

// 地图人员任务
export function userStatusOfMap(groupId, {userId}) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/userStatusOfMap',
    method: 'get',
    params: {
      userId,
    },
  });
}

// 查询大屏的运维任务
export function bigScreenMaintainTask(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/bigScreenMaintainTask',
    method: 'get',
  });
}

// 查询大屏的站点数量
export function numberOfSites(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/numberOfSites',
    method: 'get',
  });
}

// 查询大屏的摄像头
export function projectCameraList(groupId, currentPage, pageSize) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/projectCameraList',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
    },
  });
}

export function getProjectRoleByUserId(groupId, userId) {
  return request({
    url: '/iot/groups/' + groupId + '/roles/byUserId/' + userId,
    method: 'get',
  });
}

export function getProjectRoleListByParameter(groupId, data) {
  // 条件查询项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles',
    method: 'get',
    params: data,
  });
}

export function getProjectRoleById(groupId, roleId) {
  // 条件查询项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles/' + roleId,
    method: 'get',
  });
}

export function addProjectRole(groupId, data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles',
    method: 'post',
    data: data,
  });
}
export function changeProjectRole(groupId, roleId, data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles/' + roleId,
    method: 'put',
    data: data,
  });
}
export function removeProjectRole(groupId, roleId) {
  // 删除项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles/' + roleId,
    method: 'delete',
  });
}
export function existProjectRole(groupId, name, id) {
  // 删除项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles/isExist',
    method: 'get',
    params: {
      name,
      id,
    },
  });
}
export function getAllFunctions(groupId) {
  // 删除项目角色
  return request({
    url: '/iot/groups/' + groupId + '/roles/functions',
    method: 'get',
  });
}

export function getAllRoles(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/roles/all',
    method: 'get',
  });
}

export function getPermissionsByFunctionIds(groupId, functionIds) {
  return request({
    url: '/iot/groups/' + groupId + '/roles/permission',
    method: 'get',
    params: {
      functionIds: functionIds.join(','),
    },
  });
}

export function addFunctionToRole(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/roles/customFunctions',
    method: 'post',
    data: data,
  });
}

export function getDataReport(groupId, year, month, siteId, reportType, isSuperposition, code, day) {
  return request({
    url: '/iot/groups/' + groupId + '/dataReport/getSiteReport/' + siteId,
    method: 'get',
    params: {
      year: year,
      month: month,
      reportType: reportType,
      isSuperposition: isSuperposition,
      code: code,
      day: day,
    },
  });
}

export function getZoneReport(groupId, year, month, siteId, reportType, isSuperposition, code, current, pageSize) {
  return request({
    url: '/iot/groups/' + groupId + '/dataReport/getDataReport',
    method: 'get',
    params: {
      year: year,
      month: month,
      areaIdOp: siteId,
      reportType: reportType,
      isSuperposition: isSuperposition,
      code: code,
      page: current,
      size: pageSize,
    },
  });
}

export function getAllZoneReport(groupId, year, month, siteId, reportType, isSuperposition, code) {
  return request({
    url: '/iot/groups/' + groupId + '/dataReport/getDataReport',
    method: 'get',
    params: {
      year: year,
      month: month,
      areaIdOp: siteId,
      reportType: reportType,
      isSuperposition: isSuperposition,
      code: code,
    },
  });
}

export function getElecActualData(groupId, year, month, siteIdOp) {
  return request({
    url: '/iot/groups/' + groupId + '/powerConsumption/actualData/',
    method: 'get',
    params: {
      year: year,
      month: month,
      siteIdOp: siteIdOp,
    },
  });
}

export function addElecActualData(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/powerConsumption',
    method: 'post',
    data: data,
  });
}

export function getMudActualData(groupId, year, month, siteIdOp) {
  return request({
    url: '/iot/groups/' + groupId + '/soilTransportRecord/actualData',
    method: 'get',
    params: {
      year: year,
      month: month,
      siteIdOp: siteIdOp,
    },
  });
}

export function addMudActual(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/soilTransportRecord',
    method: 'post',
    data: data,
  });
}

// 获取处理量
export function getTotalWaterYield(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/getTotalWaterYield',
    method: 'get',
    params: data,
  });
}

// 成本分析
export function costAnalysis(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/bigScreen/costAnalysis',
    method: 'get',
  });
}

// 查询操作控制
export function getMaintainTaskControlConfig(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/tasks/getMaintainTaskControlConfig',
    method: 'get',
  });
}

// 修改操作控制
export function updateMaintainTaskControlConfig(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/tasks/updateMaintainTaskControlConfig',
    method: 'post',
    data: data,
  });
}

// 修改操作控制
export function updateAuditPath(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/' + data.id + '/updateAuditPath',
    method: 'put',
    data: data.auditUsers,
  });
}

// 修改路径预设
export function updateGroupDefaultRouteOrder(groupId, ids, startOrder) {
  return request({
    url: '/iot/groups/' + groupId + `/groups/batch/${ids}/defaultRouteOrder`,
    method: 'put',
    params: {
      startOrder,
    },
  });
}

// 水质阈值
export function waterQualityThresholdConfig(groupId) {
  return request({
    url: '/iot/projects/' + groupId + '/waterQualityThresholdConfig',
    method: 'get',
  });
}

// 更新水质阈值
export function updateWaterQualityThresholdConfig(groupId, data) {
  return request({
    url: '/iot/projects/' + groupId + '/waterQualityThresholdConfig',
    method: 'put',
    data: {
      aavOil: data.aavOil,
      atcc: data.atcc,
      bod5: data.bod5,
      cod: data.cod,
      hsb: data.hsb,
      las: data.las,
      nh3n: data.nh3n,
      oil: data.oil,
      ph: data.ph,
      ss: data.ss,
      tn: data.tn,
      tp: data.tp,
    },
  });
}

// 获取项目页面列表
export function getProjectBaseFunctions() {
  return request({
    url: '/iot/projects/baseFunctions',
    method: 'get',
  });
}

export function getGroupTypeNodeList(id) {
  return request({
    url: '/iot/groups/' + id + '/groups/groupTypeNode',
    method: 'get',
  });
}

export function getGroupTypeNodeListUsedInElectricalRanking(id) {
  return request({
    url: '/iot/groups/' + id + '/groups/groupTypeNode/usedInElectricalRanking',
    method: 'get',
  });
}

export function getGroupTypeNode(groupId, groupTypeNodeId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/groupTypeNode/' + groupTypeNodeId,
    method: 'get',
  });
}

export function isGroupTypeNodeUsedByGroup(groupId, groupTypeNodeId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/groupTypeNode/' + groupTypeNodeId + '/isUsedByGroup',
    method: 'get',
  });
}

export function getGroupTypeNodes(templateProjectId) {
  return request({
    url: '/iot/projects/groupTypeNodes',
    method: 'get',
    params: {
      templateProjectId: templateProjectId,
    },
  });
}

export function getTemplateProjectList() {
  return request({
    url: '/iot/projects/getTemplateProjectList',
    method: 'get',
  });
}

export function findAllByUser(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/findAllByUser',
    method: 'get',
  });
}

export function getGroupByType(groupId, type) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/findByType',
    method: 'get',
    params: {
      type: type,
    },
  });
}

// 编辑短信上限和接入设备上限
export function updateAccessGatewayAndMessageUpperLimit(
  groupId,
  accessGatewayIndicatorMaxNum,
  sendMessagesTotalNum,
  sendVoicesTotalNum
) {
  return request({
    url: '/iot/projects/' + groupId + '/updateAccessGatewayAndMessageUpperLimit',
    method: 'put',
    params: {
      accessGatewayIndicatorMaxNum: accessGatewayIndicatorMaxNum,
      sendMessagesTotalNum: sendMessagesTotalNum,
      sendVoicesTotalNum: sendVoicesTotalNum,
    },
  });
}

export function exitProject(groupId) {
  return request({
    url: '/iot/projects/exitProject',
    method: 'delete',
    params: {groupId: groupId},
  });
}

export function transferProject(form) {
  return request({
    url: '/iot/projects/transferProject',
    method: 'put',
    params: {projectGroupId: form.projectGroupId, mobile: form.mobile},
  });
}

export function accessCorporation(form) {
  return request({
    url: '/iot/projects/accessCorporation',
    method: 'put',
    params: {id: form.id, corporationCode: form.corporationCode},
  });
}

export function getMonitorTypeNode(id) {
  return request({
    url: '/iot/groups/' + id + '/groups/monitorTypeNode',
    method: 'get',
  });
}

export function updateDrawingMode(id, drawingMode) {
  return request({
    url: '/iot/projects/updateDrawingMode/' + id,
    method: 'put',
    params: {
      drawingMode,
    },
  });
}

export function updatePayStatus(id, payStatus) {
  return request({
    url: '/iot/projects/updatePayStatus/' + id,
    method: 'put',
    params: {payStatus: payStatus},
  });
}

export function getEquipmentTypeData(groupId) {
  let url = `/iot/groups/${groupId}/bigScreen/getEquipmentTypeData`;
  return request({
    url: url,
    method: 'get',
  });
}

// 查询设备状态
export function getEquipmentStatusData(groupId) {
  let url = `/iot/groups/${groupId}/bigScreen/getEquipmentStatusData`;
  return request({
    url: url,
    method: 'get',
  });
}

// 查询供热大屏传感器数量
export function getMonitorNumbersGroupByGroup(groupId) {
  let url = `/iot/groups/${groupId}/bigScreen/getMonitorNumbersGroupByGroup`;
  return request({
    url: url,
    method: 'get',
  });
}

export function getProjectGroupBaseList(currentPage, groupName) {
  let url = `/iot/projects/projectGroupBaseList`;
  return request({
    url: url,
    method: 'get',
    params: {
      page: currentPage,
      size: 10,
      groupName: groupName,
    },
  });
}

// 自定义大屏
export function onlineGatewayCount(groupId) {
  let url = `/iot/groups/${groupId}/customBigScreens/onlineGatewayCount`;
  return request({
    url: url,
    method: 'get',
  });
}

export function getCustomBigScreenListByProjectId(groupId, likeName) {
  let url = `/iot/groups/${groupId}/customBigScreens/list`;
  return request({
    url: url,
    method: 'get',
    params: {
      likeName,
      detailId: detailId,
    },
  });
}
export function isCustomBigScreenNameExist(groupId, parentRealFunctionId, customBigScreenId, name) {
  return request({
    url: `/iot/groups/${groupId}/customBigScreens/isExist`,
    method: 'get',
    params: {
      groupId,
      parentRealFunctionId,
      customBigScreenId,
      name,
    },
  });
}
export function isSubCustomBigScreenNameExist(groupId, name, parentId) {
  return request({
    url: `/iot/groups/${groupId}/customBigScreens/isSubBigScreenNameExist`,
    method: 'get',
    params: {
      groupId,
      name,
      parentId,
    },
  });
}
export function deleteCustomBigScreenListById(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/' + id,
    method: 'delete',
  });
}
export function addCustomBigScreen(groupId, parentRealFunctionId, data, subSystem) {
  let url = `/iot/groups/${groupId}/customBigScreens`;
  return request({
    url: url,
    method: 'post',
    params: {
      parentRealFunctionId,
      subSystem,
    },
    data: data,
  });
}
export function updateCustomBigScreen(groupId, id, parentRealFunctionId, data) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id;
  return request({
    url: url,
    method: 'put',
    params: {
      parentRealFunctionId,
    },
    data: data,
  });
}
export function addSubCustomBigScreen(groupId, data, subSystem) {
  let url = `/iot/groups/${groupId}/customBigScreens/subBigScreen`;
  return request({
    url: url,
    method: 'post',
    params: {
      subSystem,
    },
    data: data,
  });
}
export function updateSubCustomBigScreen(groupId, id, data) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id + '/subBigScreen';
  return request({
    url: url,
    method: 'put',
    data: data,
  });
}

export function detailCustomBigScreen(groupId, id) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id;
  return request({
    url: url,
    method: 'get',
  });
}

export function getSecondaryMenuList(groupId, id) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id + '/getSecondaryMenuList';
  return request({
    url: url,
    method: 'get',
  });
}

export function getComponentDetail(groupId, id) {
  let url = `/iot/groups/${groupId}/customBigScreenComponents/` + id;
  return request({
    url: url,
    method: 'get',
  });
}

export function getComponentList(groupId, id) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id + '/getComponentList';
  return request({
    url: url,
    method: 'get',
  });
}
export function getComponentListByRealFunctionId(groupId, id) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + 'getComponentListByRealFunctionId';
  return request({
    url: url,
    method: 'get',
    params: {
      realFunctionId: id,
    },
  });
}

export function saveComponentList(groupId, id, data) {
  let url = `/iot/groups/${groupId}/customBigScreens/` + id + '/saveComponentList';
  return request({
    url: url,
    method: 'put',
    data: data,
  });
}
export function getGroupCoordinateByParams(
  groupId,
  customBigscreenId,
  type,
  {selectGroupId, equipmentName, serialNumber, status, sentinelMode, filterByLabel, showLabelList}
) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/' + customBigscreenId + '/getGroupCoordinateByParams',
    method: 'get',
    params: {
      type: type,
      status: status,
      selectGroupId: selectGroupId,
      sentinelMode: sentinelMode,
      equipmentName: equipmentName,
      serialNumber: serialNumber,
      filterByLabel: filterByLabel,
      showLabelList: showLabelList ? showLabelList.toString() : undefined,
    },
  });
}

export function getGroupStatusByParams(groupId, type, {selectGroupId, equipmentName, serialNumber, status, sentinelMode}) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/getGroupStatusByParams',
    method: 'get',
    params: {
      selectGroupId: selectGroupId,
      status: status,
      sentinelMode: sentinelMode,
      equipmentName: equipmentName,
      serialNumber: serialNumber,
      type: type,
    },
  });
}

export function getProjectAndGroupByUser() {
  return request({
    url: '/iot/projects/getProjectAndGroupByUser',
    method: 'get',
  });
}

export function getCompleteOptionsByParam(groupId, string, param) {
  return request({
    url: `/iot/groups/${groupId}/customBigScreens/getCompleteOptionsByParam`,
    method: 'get',
    params: {
      string,
      param,
    },
  });
}

export function getDemoProjectDetail(id) {
  let url = `/iot/projects/getDemoProjectDetail/` + id;
  return request({
    url: url,
    method: 'get',
  });
}

export function addUserToDemoProject(id) {
  let url = `/iot/projects/addUserToDemoProject/` + id;
  return request({
    url: url,
    method: 'post',
  });
}
export function getCustomBigScreenWarningList(groupId, customBigScreenId, statisticalMethod, timeRange, groupIds, warningLabelIds) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/' + customBigScreenId + '/getWarningList';
  return request({
    url: url,
    method: 'get',
    params: {
      statisticalMethod,
      timeRange,
      groupIds: groupIds == undefined ? undefined : groupIds.toString(),
      warningLabelIds: warningLabelIds == undefined ? undefined : warningLabelIds.toString(),
    },
  });
}
export function getProjectLabelList(groupId, customBigScreenId, componentId, selectedLabelList) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/' + customBigScreenId + '/getProjectLabelList';
  return request({
    url: url,
    method: 'get',
    params: {
      componentId,
      selectedLabelList,
    },
  });
}
export function getMaintenanceTaskList(groupId, customBigScreenId, statisticalMethod, timeRange, groupIds) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/' + customBigScreenId + '/getMaintenanceTaskList';
  return request({
    url: url,
    method: 'get',
    params: {
      statisticalMethod,
      timeRange,
      groupIds: groupIds == undefined ? undefined : groupIds.toString(),
    },
  });
}
export function getGroupListByGroupTypeNodeIds(groupId, groupTypeNodeIds, labelList) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/getGroupListByGroupTypeNodeIds',
    method: 'get',
    params: {
      groupTypeNodeIds: groupTypeNodeIds == undefined ? '[]' : groupTypeNodeIds.toString(),
      labelList: labelList == undefined ? '[]' : labelList.toString(),
    },
  });
}
export function getByRealFunctionId(groupId, realFunctionId) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/byRealFunctionId',
    method: 'get',
    params: {
      realFunctionId,
    },
  });
}
export function getProjectsByCorporationId(groupId, corporationId) {
  let url = '/iot/groups/' + groupId + '/customBigScreenComponents/getProjectsByCorporationId';
  return request({
    url: url,
    method: 'get',
    params: {
      corporationId,
    },
  });
}

export function getLatestFormDataByConfigIds(groupId, customFormConfigIds) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getLatestFormDataByConfigIds';
  return request({
    url: url,
    method: 'get',
    params: {
      customFormConfigIds: customFormConfigIds.toString(),
    },
  });
}

export function querySolidWasteSituationStatistics(groupId, realFunctionIds) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/querySolidWasteSituationStatistics';
  return request({
    url: url,
    method: 'get',
    params: {
      realFunctionIds: realFunctionIds.toString(),
    },
  });
}

export function getRealFunctionByTitleList(groupId, titleList) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/realFunctionByTitleList',
    method: 'get',
    params: {
      titleList: titleList.toString(),
    },
  });
}
export function getRealFunctionByProjectIdAndTitle(groupId, projectId, title) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/realFunctionByProjectIdAndTitle',
    method: 'get',
    params: {
      projectId,
      title,
    },
  });
}
export function getRealFunctionByProjectIdAndName(groupId, projectId, name) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/realFunctionByProjectIdAndName',
    method: 'get',
    params: {
      projectId,
      name,
    },
  });
}
export function getRealFunctionById(groupId, realFunctionId) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/realFunctionById',
    method: 'get',
    params: {
      realFunctionId,
    },
  });
}
export function getRealFunctionByProjectIdAndId(groupId, projectId, realFunctionId) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/getRealFunctionByProjectIdAndId',
    method: 'get',
    params: {
      projectId,
      realFunctionId,
    },
  });
}
export function getGroupWithFormOptions(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/getGroupInfoFormOptions',
    method: 'get',
  });
}
export function getGroupInfoFormData(groupId, formParams) {
  return request({
    url: '/iot/groups/' + groupId + '/customBigScreens/getGroupInfoFormData',
    method: 'get',
    params: {
      formParams,
    },
  });
}
export function getSelfAndSonGroupIds(groupId, groupIds) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getSelfAndSonGroupIds';
  return request({
    url: url,
    method: 'get',
    params: {
      groupIds: groupIds == undefined ? undefined : groupIds.toString(),
    },
  });
}

export function getGatewayIndicatorsByGroupId(groupId, selectGroupId, gatewayIndicatorIdList) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getGatewayIndicatorsByGroupId';
  return request({
    url: url,
    method: 'get',
    params: {
      selectGroupId,
      gatewayIndicatorIdList: gatewayIndicatorIdList.toString(),
    },
  });
}

export function getMonitorDataTableGroupList(groupId, subGroupIds, customBigScreenId, indicatorIdList) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/' + customBigScreenId + '/getMonitorDataTableGroupList';
  return request({
    url: url,
    method: 'get',
    params: {
      subGroupIds: subGroupIds == undefined ? undefined : subGroupIds.toString(),
      indicatorIdList: indicatorIdList == undefined ? undefined : indicatorIdList.toString(),
    },
  });
}
export function getParentBigScreenList(groupId) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getParentBigScreenList';
  return request({
    url: url,
    method: 'get',
  });
}
