<template>
  <div>
    <a-row :gutter="[{xxl: 10, xl: 4}]">
      <a-col style="display: flex; flex-wrap: nowrap">
        <a-form-item name="locationMode">
          <a-radio-group v-model:value="form.locationMode" :disabled="disabled">
            <a-radio :value="0">手动定位</a-radio>
            <a-radio :value="1">自动定位</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="form.locationMode == 1" name="gatewayId">
          <a-select
            v-model:value="form.gatewayId"
            show-search
            style="width: 130px"
            :field-names="{label: 'alias', value: 'id'}"
            :filter-option="(input, option) => option.alias.toLowerCase().includes(input.toLowerCase())"
            :disabled="disabled"
            :options="gateways"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import {reactive, ref, watch} from 'vue';
import {getGateways} from '@/api/projectGateway';
import {getLastValueByGatewayAndMetrics} from '@/api/iot';
export default {
  name: 'LocationMode',
  components: {
    VNodes: (_, {attrs}) => {
      return attrs.vnodes;
    },
  },
  props: {
    modelValue: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form.gatewayId = this.modelValue.gatewayId;
        this.form.locationMode = this.modelValue.locationMode;
      },
    },
  },
  created() {
    if (this.$route.params.groupId != undefined) {
      getGateways(this.$route.params.groupId).then((res) => {
        this.verifyAndGetData(res, (data) => {
          for (const item of data) {
            this.gatewayMap.set(item.id, item);
          }
          this.gateways = data;
        });
      });
    }
  },
  setup(props, context) {
    const form = reactive({
      locationMode:
        props.modelValue !== undefined ? (props.modelValue.locationMode !== undefined ? props.modelValue.locationMode : 0) : 0,
      gatewayId:
        props.modelValue !== undefined
          ? props.modelValue.gatewayId !== undefined
            ? props.modelValue.gatewayId
            : undefined
          : undefined,
      locateByReportData: 0,
    });
    const gatewayMap = reactive(new Map());
    watch(
      () => [form.locationMode, form.gatewayId, form.locateByReportData],
      () => {
        if (form.locationMode == 1) {
          if (form.gatewayId != undefined) {
            const gateway = gatewayMap.get(form.gatewayId);
            form.locateByReportData = gateway.locateByReportData;
            if (form.locateByReportData == 0) {
              form.locateByReportData == 1;
            }
            form.lonIdentification = gateway.lonIdentification;
            form.latIdentification = gateway.latIdentification;
          }
        } else {
          form.locateByReportData = 0;
          form.gatewayId = undefined;
          form.lonIdentification = undefined;
          form.latIdentification = undefined;
        }
        form.update = true;
        context.emit('update:modelValue', form);
      }
    );
    return {form, gatewayMap};
  },
  data() {
    return {
      gateways: [],
    };
  },
  methods: {},
};
</script>

<style scoped></style>
