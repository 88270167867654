import request from '@/utils/request';
import Qs from 'qs';

export function queryAppByParam(data) {
  // 根据条件获取应用列表
  return request({
    url: '/gateway/app/queryAppByParam',
    method: 'post',
    data: data,
  });
}

export function add(data) {
  // 新增应用
  return request({
    url: '/gateway/app/saveApp',
    method: 'post',
    data: Qs.stringify(data),
  });
}

export function detail(data) {
  // 根据id查询应用详情
  return request({
    url: '/gateway/app/detailApp',
    method: 'get',
    params: data,
  });
}

export function update(data) {
  // 修改应用
  return request({
    url: '/gateway/app/updateApp',
    method: 'post',
    data: Qs.stringify(data),
  });
}

export function remove(data) {
  // 删除应用
  return request({
    url: '/gateway/app/deleteApp',
    method: 'get',
    params: data,
  });
}

export function checkAppName(data) {
  // 验证应用名称
  return request({
    url: '/gateway/app/checkAppName',
    method: 'post',
    data: data,
  });
}

export function queryAppCheckList(data) {
  // 应用列表
  return request({
    url: '/gateway/app/queryAppCheckList',
    method: 'post',
    data: data,
  });
}
