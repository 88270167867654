import request from '@/utils/request';
import store from '../store';

export function gatewayFirmlistByPage(likeGatewayFirmName, currentPage, pageSize) {
  return request({
    url: '/iot/gatewayFirms',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
      likeGatewayFirmName: likeGatewayFirmName,
    },
  });
}

export function gatewayFirmlistByUserId(currentPage, pageSize) {
  return request({
    url: '/iot/users/gatewayFirms',
    method: 'get',
    params: {
      page: currentPage,
      size: pageSize,
      userId: store.getters.userId,
    },
  });
}

export function allGatewayFirmListByUserId() {
  return request({
    url: '/iot/users/allGatewayFirmListByUserId',
    method: 'get',
    params: {
      userId: store.getters.userId,
    },
  });
}

export function gatewayFirmByUserId(id) {
  return request({
    url: '/iot/users/gatewayFirms/' + id,
    method: 'get',
  });
}

export function add(data) {
  return request({
    url: '/iot/gatewayFirms',
    method: 'post',
    data: data,
  });
}

export function update(id, data) {
  return request({
    url: '/iot/gatewayFirms/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteGatewayFirm(id) {
  return request({
    url: '/iot/gatewayFirms/' + id,
    method: 'delete',
  });
}

export function detail(id) {
  return request({
    url: '/iot/gatewayFirms/' + id,
    method: 'get',
  });
}

export function isExist(gatewayFirmName, firmId) {
  return request({
    url: '/iot/gatewayFirms/isExist',
    method: 'get',
    params: {
      gatewayFirmName: gatewayFirmName,
      firmId: firmId,
    },
  });
}

export function isIdentificationCodeExist(identificationCode, firmId) {
  return request({
    url: '/iot/gatewayFirms/isIdentificationCodeExist',
    method: 'get',
    params: {
      identificationCode: identificationCode,
      firmId: firmId,
    },
  });
}

export function getFirmUserListByParam(firmId, data) {
  // 条件查询厂商用户
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/users',
    method: 'get',
    params: data,
  });
}

export function addFirmUser(firmId, data) {
  // 新增厂或修改厂商用户
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/users',
    method: 'post',
    data: data,
  });
}

export function changeFirmUser(firmId, userId, data) {
  // 新增厂或修改厂商用户
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/users/' + userId,
    method: 'put',
    data: data,
  });
}

export function removeFirmUser(firmId, userId) {
  // 删除厂商用户
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/users/' + userId,
    method: 'delete',
  });
}
export function getFirmRoles(firmId) {
  // 删除厂商用户
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/users/roles',
    method: 'get',
  });
}

export function addDevice(firmId, sn, nt, modelId, id) {
  // 电信注册
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/' + id + '/registerDevice',
    method: 'put',
    params: {
      sn: sn,
      nt: nt,
      modelId: modelId,
    },
  });
}

export function deleteDevice(firmId, gatewayId) {
  // 删除电信注册
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/' + gatewayId + '/unregisterDevice',
    method: 'put',
  });
}

export function getFirmFunctions(firmId) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles/functions',
    method: 'get',
  });
}

export function getFirmRoleById(firmId, roleId) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles/' + roleId,
    method: 'get',
  });
}

export function addFirmRole(firmId, data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles',
    method: 'post',
    data: data,
  });
}

export function changeFirmRole(firmId, roleId, data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles/' + roleId,
    method: 'put',
    data: data,
  });
}
export function removeFirmRole(firmId, roleId) {
  // 删除项目角色
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles/' + roleId,
    method: 'delete',
  });
}

export function existFirmRole(firmId, name, id) {
  // 删除项目角色
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles/isExist',
    method: 'get',
    params: {
      name,
      id,
    },
  });
}

export function getFirmRoleListByParameter(firmId, data) {
  // 条件查询项目角色
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/roles',
    method: 'get',
    params: data,
  });
}
