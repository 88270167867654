import request from '@/utils/request';

export function queryCorporationList({corporationName, corporationOwner, corporationOwnerMobile, byCurUser, page, size}) {
  return request({
    url: '/iot/corporations',
    method: 'get',
    params: {
      corporationName,
      corporationOwner,
      corporationOwnerMobile,
      byCurUser,
      page,
      size,
    },
  });
}

export function addCorporation(data) {
  return request.postForm('/iot/corporations', data);
}

export function changeCorporation(id, data) {
  return request.putForm('/iot/corporations/' + id, data);
}

export function deleteCorporation(id) {
  return request({
    url: '/iot/corporations/' + id,
    method: 'delete',
  });
}

export function getCorporationById(id) {
  return request({
    url: '/iot/corporations/' + id,
    method: 'get',
  });
}

export function addCorporationUser(groupId, data) {
  // 新增厂或修改项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users/corporations',
    method: 'post',
    data: data,
  });
}
export function changeCorporationUser(groupId, userId, data) {
  // 新增厂或修改项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users/' + userId + '/corporations',
    method: 'put',
    data: data,
  });
}

export function removeCorporationUser(groupId, userId) {
  // 删除项目用户
  return request({
    url: '/iot/groups/' + groupId + '/users/' + userId + '/corporations',
    method: 'delete',
  });
}

export function getUnitTree(groupId, likeName) {
  return request({
    url: `/iot/groups/${groupId}/units/listTree`,
    method: 'get',
    params: {likeName},
  });
}

export function addUnit(groupId, data) {
  return request.postForm('/iot/groups/' + groupId + '/units', data);
}

export function changeUnit(groupId, id, data) {
  return request.putForm('/iot/groups/' + groupId + '/units/' + id, data);
}

export function getUnitTypeNode(groupId, groupTypeNodeId) {
  return request({
    url: '/iot/groups/' + groupId + '/units/groupTypeNode/' + groupTypeNodeId,
    method: 'get',
  });
}

export function getGroupTypeNodeList(id) {
  return request({
    url: '/iot/groups/' + id + '/units/groupTypeNode',
    method: 'get',
  });
}

export function getUnitById(groupId, siteId, otherGroupId) {
  return request({
    url: '/iot/groups/' + groupId + '/units/' + siteId + '/includeFileAndImage',
    method: 'get',
    params: {
      otherGroupId,
    },
  });
}

export function isUnitExist(groupId, id, name, parentId) {
  return request({
    url: '/iot/groups/' + groupId + '/units/isExist',
    method: 'get',
    params: {
      id: id,
      name: name,
      parentId: parentId,
    },
  });
}

export function querUnitListByParams(groupId, {type}) {
  return request({
    url: '/iot/groups/' + groupId + '/units/byParams',
    method: 'get',
    params: {
      typeName: type,
    },
  });
}

export function getSonNumbers(groupId, siteId) {
  return request({
    url: '/iot/groups/' + groupId + '/units/' + siteId + '/sonNumbers',
    method: 'get',
  });
}

export function deleteUnit(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/units/${id}`,
    method: 'delete',
  });
}

export function moveUnit(groupId, id, toTop) {
  return request({
    url: `/iot/groups/${groupId}/units/${id}/move`,
    method: 'put',
    params: {toTop},
  });
}

export function getAllLabels(groupId, likeName, selectedLabelList) {
  return request({
    url: `/iot/groups/${groupId}/labels`,
    method: 'get',
    params: {
      likeName,
      selectedLabelList: selectedLabelList ? selectedLabelList.toString() : undefined,
    },
  });
}

export function addLabel(groupId, data) {
  return request({
    url: `/iot/groups/${groupId}/labels`,
    method: 'post',
    data: data,
  });
}

export function updateLabel(groupId, id, data) {
  return request({
    url: `/iot/groups/${groupId}/labels/${id}`,
    method: 'put',
    data: data,
  });
}

export function deleteLabel(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/labels/${id}`,
    method: 'delete',
  });
}

export function existsLabel(groupId, title, id) {
  return request({
    url: `/iot/groups/${groupId}/labels/exists`,
    method: 'get',
    params: {title, id},
  });
}

export function updateLabelOrders(groupId, ids) {
  return request({
    url: `/iot/groups/${groupId}/labels/batch/${ids}/orders`,
    method: 'put',
  });
}

export function getProjectsInCorporation(id, {unitId, name, labelId, status, owner, page, size}) {
  return request({
    url: `/iot/corporations/${id}/projects`,
    method: 'get',
    params: {unitId, name, labelId, status, owner, page, size},
  });
}

export function applyIntoCorporation(groupId, id, parentId) {
  return request({
    url: `/iot/corporations/${groupId}/projects/${id}/apply`,
    method: 'put',
    params: {parentId},
  });
}

export function exitIntoCorporation(groupId, id) {
  return request({
    url: `/iot/corporations/${groupId}/projects/${id}/exit`,
    method: 'put',
  });
}

export function getFunctions(groupId) {
  return request({
    url: `/iot/corporations/${groupId}/functions`,
    method: 'get',
  });
}

export function updateFunctions(groupId, data) {
  return request({
    url: `/iot/corporations/${groupId}/functions`,
    method: 'post',
    data: data,
  });
}
export function getProjectDataOptions(groupId, customBigScreenId) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getProjectDataOptions';
  return request({
    url: url,
    method: 'get',
  });
}
export function getCameraListWithToken(groupId, groupIdList) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getVideoSurveillanceList';
  return request({
    url: url,
    method: 'get',
    params: {
      groupIdList,
    },
  });
}
export function getProjectGroupTypeNodeOptions(groupId, labelList) {
  let url = '/iot/groups/' + groupId + '/customBigScreens/getProjectGroupTypeNodeOptions';
  return request({
    url: url,
    method: 'get',
    params: {
      labelList: labelList == undefined ? '[]' : labelList.toString(),
    },
  });
}
