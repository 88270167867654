<template>
  <a-modal v-model:open="visible" :title="type" width="360px" destroy-on-close :mask-closable="false" :ant-modal-footer="false">
    <template #footer>
      <div style="width: 100%; text-align: center">
        <a-button type="primary" :loading="loading" @click="buyResources">立即购买</a-button>
      </div>
    </template>
    <template v-if="!buyMessage">
      <a-table :columns="columns" :data-source="data" :row-selection="rowSelection" :pagination="false" />
      <div
        v-if="type == '监测项续费'"
        style="wdith: 100%; justify-content: center; height: 60px; display: flex; align-items: center"
      >
        {{ data[rowSelection != undefined ? rowSelection.selectedRowKeys[0] : 0].annualFee }}
        <a-input-number v-model:value="number" style="margin-left: 16px; width: 120px" :min="1" :max="100" />
      </div>
    </template>
    <template v-else>
      <div style="height: 60px; display: flex; align-items: center">
        1000条（80元）
        <a-input-number v-model:value="number" style="margin-left: 16px; width: 120px" :min="1" :max="100" />
      </div>
    </template>
  </a-modal>
</template>

<script>
import {getOrderId, getPayLink} from '@/api/projectOverview';
import {getProjectByGroupId} from '@/api/project';
import {Modal} from 'ant-design-vue';
import {mapGetters} from 'vuex';
import {isPositiveInteger} from '@/utils/validate';
export default {
  props: {
    type: String,
    currentSetMeal: Number,
    targetId: [String, Number],
  },
  computed: {
    ...mapGetters(['groupId', 'gatewayIndicatorMaxNum', 'sendMessagesTotalNum', 'setMealEndAt']),
  },
  data() {
    // 续费
    const RENEWAL_FEE = 'GATEWAY_INDICATOR_RENEW';
    // 升级配置
    const UPGRADE_CONFIGURATION = 'GATEWAY_INDICATOR_UPGRADE_CONFIGURATION';
    // 购买短信
    const MESSAGE_ORDER = 'MESSAGE';
    return {
      RENEWAL_FEE,
      UPGRADE_CONFIGURATION,
      MESSAGE_ORDER,
      columns: [
        {
          title: '监测项个数',
          dataIndex: 'num',
          key: 'num',
        },
        {
          title: '年费',
          dataIndex: 'annualFee',
          key: 'annualFee',
        },
      ],
      data: this.$gatewayIndicatorSetMeal.map((a) => {
        return {
          key: a.value,
          num: a.quantity,
          annualFee: a.charge,
        };
      }),
      isSelectRow: true,
      buyMessage: false,
      number: 1,
      rowSelection: {
        type: 'radio',
        selectedRowKeys: [],
        onChange: this.selectRow,
      },
      orderType: undefined,
      visible: true,
      loading: false,
    };
  },
  created() {
    if (this.targetId == undefined) {
      this.initSetMealMenu(this.gatewayIndicatorMaxNum, this.setMealEndAt);
    } else {
      getProjectByGroupId(this.targetId).then((res) => {
        this.initSetMealMenu(res.data.accessGatewayIndicatorMaxNum, res.data.setMealEndAt);
      });
    }
  },
  methods: {
    initSetMealMenu(gatewayIndicatorMaxNum, setMealEndAt) {
      if (this.type == '升级监测项配置') {
        this.data = this.data.filter((a) => a.num > gatewayIndicatorMaxNum);
        this.rowSelection.selectedRowKeys = [this.data[0].key];
        this.buyMessage = false;
        this.orderType = this.UPGRADE_CONFIGURATION;
      } else if (this.type == '监测项续费') {
        if (gatewayIndicatorMaxNum == this.$gatewayIndicatorInitialSize) {
          this.rowSelection.selectedRowKeys = [this.data[0].key];
        } else {
          this.data = this.data.filter((a) => a.num == gatewayIndicatorMaxNum);
          this.data[0].endAt = this.$dayjs(setMealEndAt).format('YYYY-MM-DD');
          this.columns[0].title = '当前资源';
          this.columns.push({
            title: '到期时间',
            dataIndex: 'endAt',
            key: 'endAt',
          });
          this.rowSelection = undefined;
        }
        this.buyMessage = false;
        this.orderType = this.RENEWAL_FEE;
      } else if (this.type == '购买短信资源') {
        this.buyMessage = true;
        this.orderType = this.MESSAGE_ORDER;
      }
    },
    buyResources() {
      if (!isPositiveInteger(this.number)) {
        Modal.error({
          content: '购买数量只能为大于0的整数',
        });
        return;
      }
      if (this.number > 100) {
        Modal.error({
          content: '购买数量不能超过100',
        });
        return;
      }
      this.loading = true;
      getOrderId(
        this.targetId == undefined ? this.groupId : this.targetId,
        this.rowSelection?.selectedRowKeys[0],
        this.orderType,
        this.number
      ).then((res) => {
        this.loading = false;
        if (res.code == this.$SUCCESS_CODE) {
          getPayLink(this.targetId == undefined ? this.groupId : this.targetId, res.data).then((res1) => {
            if (res1.code == this.$SUCCESS_CODE) {
              this.visible = false;
              window.open(res1.data + '&token=' + this.$store.getters.token);
            } else {
              this.$notification.error({
                message: res1.message,
              });
            }
          });
        } else {
          this.$notification.error({
            message: res.message,
          });
        }
      });
    },
    selectRow(selectedRowKeys) {
      console.log(selectedRowKeys);
      this.rowSelection.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>

<style></style>
