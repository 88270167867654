<template>
  <div class="menu-content">
    <div
      :class="[
        'menuList',
        ($store.getters.subSystem == $SUBSYSTEM_PROJECT && !isProject()) || $store.getters.fromCorporationBigScreen != undefined
          ? 'menuList1'
          : '',
      ]"
    >
      <a-menu
        ref="menu"
        mode="inline"
        :selected-keys="currentMenu"
        :open-keys="openMenu"
        style="height: calc(100% - 16px); padding-bottom: 16px"
        @openChange="onOpenChange"
      >
        <Menu v-for="item in routes" :key="item.id" :obj="item" @forceUpdate="forceUpdate" />
      </a-menu>
    </div>
    <div
      v-if="$store.getters.subSystem == $SUBSYSTEM_PROJECT && !isProject() && $store.getters.fromCorporationBigScreen == undefined"
      class="back"
    >
      <div class="back-content" @click="backProject()">
        <iconFont type="icon-fanhui" />&nbsp;&nbsp;&nbsp; <span style="cursor: pointer">返回</span>
      </div>
    </div>
    <div
      v-if="
        $store.getters.fromCorporationBigScreen != undefined &&
        $store.getters.fromCorporationBigScreen.sonProject != undefined &&
        $store.getters.fromCorporationBigScreen.sonProject.id == $route.params.groupId
      "
      class="back"
    >
      <div class="back-content" @click="backCorporationBigScreen()">
        <iconFont type="icon-fanhui" />&nbsp;&nbsp;&nbsp;
        <span style="cursor: pointer">{{ '返回"' + $store.getters.fromCorporationBigScreen.title + '"' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import router from '@/router';
import store from '@/store';
import Menu from './menu';
import {getRealFunctionById} from '@/api/project';
export default {
  components: {
    Menu,
  },
  data() {
    return {
      inLineOpenMenu: [],
      openMenu: [],
      rootSubmenuKeys: [],
      hasSiteInspection: false,
      currentMenu: [],
      collapsed: false,
    };
  },
  watch: {
    '$route.path'(newValue, oldValue) {
      this.routeMapMenuFunc(newValue);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.routeMapMenuFunc(this.$route.path);
    });
  },
  created() {},
  methods: {
    forceUpdate() {
      if (this.needRefreshMenu) {
        this.$store.commit('SET_NEED_REFRESH_MENU', false);
      }
    },
    backProject() {
      const groupId = this.projectGroupId;
      this.$store.dispatch('GenerateRoutes', {type: 'project', id: groupId}).then(() => {
        if (this.frontMenu[4] != undefined) {
          this.routePush('any', {groupId: groupId});
        } else if (this.frontMenu[7] != undefined) {
          const groupId = this.frontMenu[7] ? this.frontMenu[7][0].groupId : undefined;
          this.routePush('any', {groupId: groupId});
        } else {
          this.$notification.warning({
            message: '没有可访问的菜单!',
          });
        }
      });
    },
    backCorporationBigScreen() {
      let groupId = this.$store.getters.fromCorporationBigScreen.groupId;
      getRealFunctionById(groupId, this.$store.getters.fromCorporationBigScreen.id).then((realFunction) => {
        if (realFunction.data != undefined) {
          this.$store
            .dispatch('GenerateRoutes', {
              type:
                this.$store.getters.fromCorporationBigScreen.subSystem == this.$SUBSYSTEM_CORPORATION ? 'corporation' : 'project',
              id: groupId,
              checkHas: true,
            })
            .then(() => {
              this.routePush(realFunction.data.name, {
                groupId: groupId,
              });
            });
          this.$store.commit('SET_FROM_CORPORATION_BIG_SCREEN');
        }
      });
    },
    routeMapMenuFunc(url, menuList) {
      if (this.$route.meta.notrequireAuth) {
        return;
      }
      // 菜单高亮
      this.currentMenu = [this.$route.meta.id];
      if (!this.collapsed) {
        this.openMenu = this.$route.matched.map((v) => v.meta.id);
      }
    },
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find((key) => !this.openMenu.includes(key));
      if (!this.rootSubmenuKeys.includes(latestOpenKey)) {
        this.inLineOpenMenu = openKeys;
      } else {
        this.inLineOpenMenu = latestOpenKey ? [latestOpenKey] : [];
      }
      if (!this.collapsed) {
        this.openMenu = [...this.inLineOpenMenu];
      }
    },
    onShow(subSystem) {
      if (subSystem === 0) return false;
      return subSystem == this.subSystem ? true : false;
    },
    isCurRoute(route) {
      if (route.redirect == '/404') {
        return true;
      }
      if (this.$route.params.firmId == -1 && route.meta.firmId == undefined && this.$route.params.groupId == -1) {
        return true;
      }
      if (route.meta.firmId == this.$route.params.firmId && route.meta.groupId == this.$route.params.groupId) {
        return true;
      }
      return false;
    },
    setPush(array, object) {
      if (!array.includes(object)) {
        array.push(object);
      }
    },
    getSubSystem() {
      if (!this.frontMenu[this.subSystem]) {
        return [];
      }
      return JSON.parse(JSON.stringify(this.frontMenu[this.subSystem]));
    },
    getRoutes() {
      const returnRouteList = this.getSubSystem();
      for (const item of returnRouteList) {
        if (!item.hidden) {
          this.rootSubmenuKeys.push(item.id);
        }
      }
      this.findAndSetGroupMenu(returnRouteList);
      return returnRouteList;
    },
    findAndSetGroupMenu(routes = []) {
      for (const item of routes) {
        if (item.children?.length > 0) {
          this.findAndSetGroupMenu(item.children);
        } else {
          if (this.setGroupMenu(item, routes)) {
            return;
          }
        }
      }
    },
    setGroupMenu(item, list) {
      if (this.groupMenu == undefined || this.groupMenu == 'refresh') {
        return false;
      }
      if (item.meta != undefined && item.meta.code == 'siteInspection') {
        item.children = this.groupMenu;
        item.spread = true;
        return true;
      }
      return false;
    },
  },
  computed: {
    ...mapGetters(['subSystem', 'groupMenu', 'projectGroupId', 'needRefreshMenu', 'frontMenu']),
    routes: {
      cache: false,
      get: function () {
        return this.getRoutes();
      },
    },
  },
};
</script>
<style lang="less" scoped>
.subMenuOpen {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #232323;
  line-height: 16px;
  font-weight: 400;
}
.menu-content {
  height: 100%;
  width: 100%;
  overflow: hidden auto;
}
.menuList {
  .ant-menu-light {
    box-sizing: content-box;
  }
  height: 100%;
  width: 100%;
  &.menuList1 {
    height: calc(100% - 50px);
    overflow: hidden auto;
  }
}

.back {
  margin-left: 5%;
  width: 90%;
  height: 50px;
  border-top: 1px solid var(--border-color);
}
.back-content {
  margin-top: 15px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}
.menuRight {
  .move-enter-from {
    transform: translateX(250px);
    opacity: 0;
  }
  .move-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.9, 0.8, 1);
  }
  .move-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.8, 0.9, 1);
  }
  .move-leave-to {
    transform: translateX(-250px);
    opacity: 0;
  }
}
.menuLeft {
  .move-enter-from {
    transform: translateX(-250px);
    opacity: 0;
  }
  .move-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.9, 0.8, 1);
  }
  .move-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.8, 0.9, 1);
  }
  .move-leave-to {
    transform: translateX(250px);
    opacity: 0;
  }
}
</style>
