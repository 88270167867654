<template>
  <div id="" @keyup.enter="executeConditionalQuery">
    <router-view :key="key" v-slot="{Component}">
      <keep-alive :include="cachedViews">
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
import store from '../../../store';
import {computed, h} from 'vue';

export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.name !== undefined ? this.$route.name + Date.now() : this.$route + Date.now();
    },
    // 缓存组件，注意需要使用组件名称. by zhangjq
    cachedViews() {
      return ['gisapp'];
    },
  },
  methods: {
    executeConditionalQuery() {
      XPathResult = document.evaluate(".//button/span[text()='查 询']", document, undefined, XPathResult.ANY_TYPE);
      if (XPathResult) {
        const next = XPathResult.iterateNext();
        if (next) {
          next.click();
        }
      }
    },
  },
};
</script>

<style scoped>
.app-main {
  /*50 = navbar  */
  /* min-height: 100vh; */
  position: relative;
  overflow: scroll;
  /* height: 100%; */
}
</style>
