import request from '@/utils/request';

export function changePassword(data) {
  // 修改密码
  return request({
    url: '/iot/users/changePassword',
    method: 'put',
    data: data,
  });
}

export function resetPassword() {
  return request({
    url: '/iot/users/resetPassword',
    method: 'get',
  });
}

export function getUserTypeList() {
  return request({
    url: '/iot/users/getUserTypeList',
    method: 'get',
  });
}

export function addUser(data) {
  return request({
    url: '/iot/users',
    method: 'post',
    data: data,
  });
}

export function addNoMobileUser(data) {
  return request({
    url: '/iot/users/addNoMobileUser',
    method: 'post',
    data: data,
  });
}

export function updateUsername(data) {
  return request({
    url: '/iot/users/updateUsername',
    method: 'post',
    data: data,
  });
}

export function updateSystemUser(id, data) {
  return request({
    url: '/iot/systemUsers/' + id,
    method: 'put',
    data: data,
  });
}

export function updateCreatedProjectNumber(id, data) {
  // 修改创建项目个数
  return request({
    url: '/iot/systemUsers/' + id + '/updateCreatedProjectNumber',
    method: 'put',
    data: data,
  });
}

export function updateUser(data) {
  return request({
    url: '/iot/users',
    method: 'put',
    data: data,
  });
}

export function noMobileToMobile(data) {
  return request({
    url: '/iot/users/noMobileToMobile',
    method: 'put',
    data: data,
  });
}

export function updateUserName(data) {
  return request({
    url: '/iot/users/updateName',
    method: 'put',
    data: data,
  });
}

export function updateAvatar(data) {
  return request({
    url: '/iot/users/updateAvatar',
    method: 'put',
    data: data,
  });
}

export function updateIsSilentMode(data) {
  return request({
    url: '/iot/users/updateIsSilentMode',
    method: 'put',
    params: {
      isSilentMode: data,
    },
  });
}

export function updateTheme(theme) {
  return request({
    url: '/iot/users/updateTheme',
    method: 'put',
    params: {
      theme: theme,
    },
  });
}

export function updateIsReadGuide(data) {
  return request({
    url: '/iot/users/updateIsReadGuide',
    method: 'put',
    params: {
      isReadGuide: data,
    },
  });
}

export function userList(searchMobile, searchName, currentPage, pageSize) {
  return request({
    url: '/iot/systemUsers',
    method: 'get',
    params: {
      mobile: searchMobile,
      realName: searchName,
      page: currentPage,
      size: pageSize,
    },
  });
}

export function getUserByIds(ids) {
  return request({
    url: '/iot/users/all?userIds=' + encodeURIComponent(JSON.stringify(ids)),
    method: 'get',
  });
}

export function getUserByRealnames(names) {
  return request({
    url: '/iot/users/realnames?realnames=' + encodeURIComponent(JSON.stringify(names)),
    method: 'get',
  });
}

export function getUserByRealnameLike(name) {
  return request({
    url: '/iot/users/realname?realname=' + name,
    method: 'get',
  });
}

export function findTryOutUserList(
  searchMobile,
  searchName,
  searchCompany,
  searchProvince,
  searchCity,
  searchDistrict,
  searchStartTime,
  searchEndTime,
  currentPage,
  pageSize
) {
  return request({
    url: '/iot/tryOutUser',
    method: 'get',
    params: {
      mobile: searchMobile,
      realName: searchName,
      company: searchCompany,
      province: searchProvince,
      city: searchCity,
      district: searchDistrict,
      startTime: searchStartTime,
      endTime: searchEndTime,
      page: currentPage,
      size: pageSize,
    },
  });
}

export function changeMobile(data) {
  return request({
    url: '/iot/users/changeMobile',
    method: 'put',
    data: data,
  });
}

export function adminChangeMobile(id, data) {
  return request({
    url: '/iot/systemUsers/adminChangeMobile/' + id,
    method: 'put',
    data: data,
  });
}

export function checkUserName(data) {
  return request({
    url: '/gateway/users/checkUserName',
    method: 'get',
    params: data,
  });
}

export function userDetail(id) {
  return request({
    url: '/iot/users/' + id,
    method: 'get',
  });
}

export function userInfo(data) {
  return request({
    url: '/iot/users/userInfo',
    method: 'get',
    params: data,
  });
}

export function checkImageCode(data) {
  // 图形验证码实时校验
  return request({
    url: '/api/iot/verify/checkImageCode',
    method: 'get',
    params: data,
  });
}

export function isUserExist(data) {
  // 手机号验重实时校验
  return request({
    url: '/iot/users/isUserExist',
    method: 'get',
    params: data,
  });
}

export function updateNoMobileUser(data) {
  return request({
    url: '/iot/users/updateNoMobileUser',
    method: 'post',
    data: data,
  });
}

export function getRealname(mobile) {
  return request({
    url: '/iot/users/getRealname',
    method: 'get',
    params: {
      mobile: mobile,
    },
  });
}
export function getLoginInfo() {
  return request({
    url: '/iot/system/getLoginInfo',
    method: 'get',
  });
}

export function checkPhoneCode(data) {
  // 手机验证码实时校验
  return request({
    url: '/iot/users/checkPhoneCode',
    method: 'get',
    params: data,
  });
}

export function getPhoneCode(data) {
  // 手机校验码
  return request({
    url: '/iot/users/getPhoneCode',
    method: 'get',
    params: data,
  });
}

export function getRealnameByMobile(data) {
  // 根据手机号获取用户姓名
  return request({
    url: '/iot/users/getRealnameByMobile',
    method: 'get',
    params: data,
  });
}

export function getPermission() {
  // 获取权限
  return request({
    url: '/iot/system/getPermission',
    method: 'get',
  });
}
export function getRoles() {
  // 获取角色
  return request({
    url: '/iot/system/getRoles',
    method: 'get',
  });
}

export function updateIsMuteMessageSound(data) {
  return request({
    url: '/iot/users/updateIsMuteMessageSound',
    method: 'put',
    params: {
      isMuteMessageSound: data,
    },
  });
}

export function updateIsShowMessage(data) {
  return request({
    url: '/iot/users/updateIsShowMessage',
    method: 'put',
    params: {
      isShowMessage: data,
    },
  });
}

export function updateOtherMessageOperate(data) {
  return request({
    url: '/iot/users/updateOtherMessageOperate',
    method: 'put',
    params: {
      updateOtherMessageOperate: data,
    },
  });
}

export function updateWarningMessageOperate(data) {
  return request({
    url: '/iot/users/updateWarningMessageOperate',
    method: 'put',
    params: {
      warningMessageOperate: data,
    },
  });
}

export function updateEquipmentMalfunctionMessageOperate(data) {
  return request({
    url: '/iot/users/updateEquipmentMalfunctionMessageOperate',
    method: 'put',
    params: {
      equipmentMalfunctionMessageOperate: data,
    },
  });
}

export function updateEquipmentMaintenanceMessageOperate(data) {
  return request({
    url: '/iot/users/updateEquipmentMaintenanceMessageOperate',
    method: 'put',
    params: {
      equipmentMaintenanceMessageOperate: data,
    },
  });
}

export function hasGroupPagePermission(groupId, resourceCode, permission) {
  return request({
    url: `/iot/users/${groupId}/hasGroupPagePermission`,
    method: 'get',
    params: {
      resourceCode: resourceCode,
      permission: permission,
    },
  });
}
