import request from '@/utils/request';

export function add(firmId, data) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways',
    method: 'post',
    data: data,
  });
}

export function update(firmId, id, data) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteOne(firmId, id) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/' + id,
    method: 'delete',
  });
}

export function queryGatewayList(
  firmId,
  serialNumber,
  gatewayTypeModel,
  gatewayTypeName,
  iccid,
  batchOrContract,
  onlineStatues,
  exceptionStatuses,
  page,
  size,
  isExport
) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways',
    method: 'get',
    params: {
      serialNumber: serialNumber,
      gatewayTypeModel: gatewayTypeModel,
      gatewayTypeName: gatewayTypeName,
      iccid: iccid,
      batchOrContract: batchOrContract,
      onlineStatues: onlineStatues?.toString(),
      exceptionStatuses: exceptionStatuses?.toString(),
      page: page,
      size: size,
      isExport: isExport,
    },
  });
}

export function getLatestDataPoint(firmId, ids) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/getLatestDataPoint',
    method: 'get',
    params: {
      gatewayIds: ids.toString(),
    },
  });
}

export function detail(firmId, id) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/' + id,
    method: 'get',
  });
}

export function isSerialNumberExist(firmId, serialNumber, gatewayId) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/isSerialNumberExist',
    method: 'get',
    params: {serialNumber: serialNumber, gatewayId: gatewayId},
  });
}

export function getTripleDetail(firmId, id) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/tripleDetail',
    method: 'get',
    params: {gatewayId: id},
  });
}

export function distributeGateway(firmId, id, mobile) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/distributeGateway',
    method: 'get',
    params: {gatewayId: id, mobile: mobile},
  });
}

export function ifExist(plcDeviceId, thisId) {
  return request({
    url: '/iot/gateway/checIfExist',
    method: 'get',
    params: {plcDeviceId: plcDeviceId, thisId: thisId},
  });
}

export function getGatewayBySerialNumber(serialNumber) {
  return request({
    url: '/iot/gateway/api/findGatewayId',
    method: 'get',
    params: {serialNumber: serialNumber},
  });
}

export function getNotInProjectGatewayBySerialNumber(serialNumber) {
  return request({
    url: `/iot/gateway/api/findNotInProjectAndSupportAnonymityGatewayId`,
    method: 'get',
    params: {serialNumber: serialNumber},
  });
}

export function createAndRegistryGateway(firmId, gateway, platformName, platformModel) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/createAndRegistry',
    method: 'post',
    data: gateway,
    params: {
      platformName: platformName,
      platformModel: platformModel,
    },
  });
}

export function importGateways(firmId, formData, importRule) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/gateways/importGateways',
    method: 'post',
    data: formData,
    params: {importRule},
  });
}

export function addScanCodeGateway(firmId, data) {
  return request({
    url: `/iot/gatewayFirms/${firmId}/gateways/scanCode`,
    method: 'post',
    data: data,
  });
}

export function getIndexById(firmId, id) {
  return request({
    url: `/iot/gatewayFirms/${firmId}/gateways/${id}/getIndexById`,
    method: 'get',
  });
}

export function getGatewayIdBySerialNumber(firmId, serialNumber) {
  return request({
    url: `/iot/gatewayFirms/${firmId}/gateways/getGatewayIdBySerialNumber`,
    method: 'get',
    params: {
      serialNumber: serialNumber,
    },
  });
}
