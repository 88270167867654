import axios from 'axios';
import store from '../store';
import {Modal, notification} from 'ant-design-vue';

import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {createVNode} from 'vue';
import {exception, time} from 'vue-gtag';
import {isArray} from 'lodash';
import {stream} from 'xlsx';
import dayjs from 'dayjs';
import customVisitor from './axiosVisitorUtil';
// 创建axios实例
const service = axios.create({
  // baseURL: isPro ? 'process.prod.BASE_API' : '',// api 的 base_url
  timeout: 50_000, // 请求超时时间
  formSerializer: {
    visitor: customVisitor,
  },
});

// request拦截器
service.interceptors.request.use(
  async (config) => {
    if (store.getters.token) {
      if (!store.getters.isLogin) {
        await store.dispatch('RefreshLogin');
      }
      // 让每个请求携带自定义token 请根据实际情况自行修改
      config.headers['Authorization'] = 'Bearer ' + store.getters.token;
    }
    if (config.params != undefined) {
      for (const key in config.params) {
        if (key != undefined) {
          if (config.params[key] === '') {
            config.params[key] = undefined;
          } else if (dayjs.isDayjs(config.params[key])) {
            // axios不能正确处理dayjs，统一转为ISOString， ISOString已经处理成后端需要的格式,见main.js
            config.params[key] = config.params[key].toISOString();
          }
        }
      }
    }

    // 处理提交的图片文件是预览对象的问题。只处理了一层，如果图片在内部的化，需要改进
    if (config.data && config.headers.hasContentType('multipart/form-data')) {
      for (const prop in config.data) {
        if (config.data.hasOwnProperty(prop)) {
          const value = config.data[prop];

          if (value && value.originFileObj && (value.originFileObj instanceof File || value.originFileObj instanceof Blob)) {
            config.data[prop] = value.originFileObj;
          }

          if (isArray(value)) {
            for (let i = 0; i < value.length; i++) {
              if (value[i].originFileObj && (value[i].originFileObj instanceof File || value[i].originFileObj instanceof Blob)) {
                value[i] = value[i].originFileObj;
              }
            }
          }
        }
      }
    }
    config.metadata = {startTime: Date.now()};
    return config;
  },
  (error) => {
    // Do something with request error
    console.error(error); // for debug
    exception({
      description: error,
      fatal: false,
    });
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  (response) => {
    if (!response.config.cache || response.config.forceUpdate) {
      let duration = Date.now() - response.config.metadata.startTime;
      time({
        name: response.config.url,
        value: duration,
        event_category: '请求时长',
      });
    }
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const headers = response.headers;
    const data = response.data;

    if (
      headers['content-type'].toLowerCase() === 'application/octet-stream;charset=utf-8' ||
      headers['content-type'].toLowerCase() === 'application/vnd.ms-excel;charset=utf-8'
    ) {
      if (headers['content-size'] && headers['total-pages']) {
        data.contentSize = Number.parseInt(headers['content-size']);
        data.totalPages = Number.parseInt(headers['total-pages']);
      }
      return data;
    }
    if (/^{.*}{.*}$/.test(data)) {
      notification.error({
        message: '请求超限!',
      });
      console.error('请求超限!');
      return JSON.parse(data.split('}{')[0] + '}');
    }
    if (data.code === 50_001) {
      Modal.error({
        title: '登出提醒',
        icon: createVNode(ExclamationCircleOutlined),
        content: '您的登录状态已过期,请重新登录!',
        okText: '重新登录',
        onOk() {
          return store.dispatch('LogOut').then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        },
      });
    } else if (data.code === 50002) {
      notification.warn({
        message: data.message,
      });
    } else if (data.code !== 20_000 && data.code !== 40_000) {
      notification.error({
        message: '当前系统繁忙,请稍后重试!',
      });
      exception({
        description: data.message,
        fatal: false,
      });
      console.error('code:' + data.code + ',message:' + data.message);
    } else {
      return data;
    }
  },
  (error) => {
    exception({
      description: error,
      fatal: false,
    });
    console.error(error); // for debug
    if (error.response.status === 504 || error.response.status === 502) {
      notification.error({
        message: '系统维护中,请稍后再试!',
      });
    } else {
      notification.error({
        message: '系统异常,请联系管理员!',
      });
    }
    return Promise.reject(error);
  }
);

export default service;
