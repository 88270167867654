/**
 * If the array is an array and none of its elements are visitable, then it's a flat array.
 *
 * @param {Array<any>} arr - The array to check
 * @param {object} helpers - axios expose util func
 *
 * @returns {boolean}
 */
function isFlatArray(arr, helpers) {
  return helpers.isArray(arr) && !arr.some((element) => helpers.isVisitable(element));
}

function isFileList(arr, helpers) {
  return helpers.isArray(arr) && arr.every((element) => isFile(el));
}

function isFile(el) {
  return el instanceof File;
}

/**
 * Determines whether a string ends with the characters of a specified string
 *
 * @param {String} str
 * @param {String} searchString
 * @param {Number} [position= 0]
 *
 * @returns {boolean}
 */
const endsWith = (str, searchString, position) => {
  str = String(str);
  if (position === undefined || position > str.length) {
    position = str.length;
  }
  position -= searchString.length;
  const lastIndex = str.indexOf(searchString, position);
  return lastIndex !== -1 && lastIndex === position;
};

/**
 * Returns new array from array like object or null if failed
 *
 * @param {*} [thing]
 * @param {object} helpers - axios expose util func
 *
 * @returns {?Array}
 */
const toArray = (thing, helpers) => {
  if (!thing) return;
  if (helpers.isArray(thing)) return thing;
  let i = thing.length;
  if (!helpers.isNumber(i)) return;
  const arr = Array.from({length: i});
  while (i-- > 0) {
    arr[i] = thing[i];
  }
  return arr;
};

/**
 * Iterate over an Array or an Object invoking a function for each item.
 *
 * If `obj` is an Array callback will be called passing
 * the value, index, and complete array for each item.
 *
 * If 'obj' is an Object callback will be called passing
 * the value, key, and complete object for each property.
 *
 * @param {Object|Array} obj The object to iterate
 * @param {Function} fn The callback to invoke for each item
 *
 * @param {Boolean} [allOwnKeys = false]
 * @returns {any}
 */
function forEach(obj, fn, helpers, {allOwnKeys = false} = {}) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  let i;
  let l;

  // Force an array if not already something iterable
  if (typeof obj !== 'object') {
    /* eslint no-param-reassign:0*/
    obj = [obj];
  }

  if (helpers.isArray(obj)) {
    // Iterate over array values
    for (i = 0, l = obj.length; i < l; i++) {
      fn.call(this, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    const keys = allOwnKeys ? Object.getOwnPropertyNames(obj) : Object.keys(obj);
    const len = keys.length;
    let key;

    for (i = 0; i < len; i++) {
      key = keys[i];
      fn.call(this, obj[key], key, obj);
    }
  }
}

/**
 * It removes the brackets from the end of a string
 *
 * @param {string} key - The key of the parameter.
 *
 * @returns {string} the key without the brackets.
 */
function removeBrackets(key) {
  return endsWith(key, '[]') ? key.slice(0, -2) : key;
}

/**
 * It takes a path, a key, and a boolean, and returns a string
 *
 * @param {string} path - The path to the current key.
 * @param {string} key - The key of the current object being iterated over.
 *
 * @returns {string} The path to the current key.
 */
function renderKey(path, key) {
  if (!path) return key;
  return [...path, key]
    .map(function each(token, i) {
      return removeBrackets(token);
    })
    .join('.');
}

function customVisitor(value, key, path, helpers) {
  let arr = value;

  if (value && typeof value === 'object') {
    if (endsWith(key, '{}')) {
      // eslint-disable-next-line no-param-reassign
      key = metaTokens ? key : key.slice(0, -2);
      // eslint-disable-next-line no-param-reassign
      value = JSON.stringify(value);
    } else if (
      helpers.isArray(value) ||
      isFileList(value, helpers) ||
      ((helpers.isFileList(value) || endsWith(key, '[]')) && (arr = toArray(value, helpers)))
    ) {
      // eslint-disable-next-line no-param-reassign
      key = removeBrackets(key);
      const arrayPath = [];
      if (path) {
        arrayPath.push(...path);
      }
      arrayPath.push(key);
      for (const [index, el] of arr.entries()) {
        if (helpers.isVisitable(el) && !isFile(el) && !helpers.isFile(el)) {
          if (helpers.isArray(arr)) {
            arrayPath[arrayPath.length - 1] = `${key}[${index}]`;
          }
          forEach.call(
            this,
            el,
            function each(subEl, subKey) {
              if (!(helpers.isUndefined(subEl) || subEl === null)) {
                customVisitor.call(this, subEl, helpers.isString(subKey) ? subKey.trim() : subKey, arrayPath, helpers);
              }
            },
            helpers
          );
        } else {
          !(helpers.isUndefined(el) || el === null) && this.append(arrayPath.join('.') + '[]', helpers.convertValue(el));
        }
      }
      return false;
    } else if (helpers.isPlainObject(value) && path) {
      key = removeBrackets(key);
      if (!isFile(value)) {
        forEach.call(
          this,
          value,
          function each(subEl, subKey) {
            if (!(helpers.isUndefined(subEl) || subEl === null)) {
              customVisitor.call(this, subEl, helpers.isString(subKey) ? subKey.trim() : subKey, [...path, key], helpers);
            }
          },
          helpers
        );
      } else {
        !(helpers.isUndefined(value) || value === null) && this.append(renderKey(path, key), helpers.convertValue(el));
      }
      return false;
    }
  }

  if (helpers.isVisitable(value)) {
    return true;
  }
  this.append(renderKey(path, key), helpers.convertValue(value));

  return false;
}

export default customVisitor;
