<template>
  <div id="map-container" class="map">
    <a-card
      :body-style="{
        'max-height': '380px',
        'overflow-y': 'scroll',
        'padding-top': 0,
      }"
      class="result-panel"
    >
      <template #title>
        <template v-if="mode === 'search' || mode === 'result'">
          <a-input-group compact style="display: flex; padding: 8px" @keyup.enter="doSearch">
            <a-auto-complete
              v-model:value="query"
              placeholder="输入关键词"
              style="flex: 1; margin: 1px"
              :options="tips"
              :default-active-first-option="false"
              @search="autoComplete"
              @select="onSelect"
            />
            <iconFont type="icon-sousuo1" style="font-size: 24px; padding-left: 6px; padding-top: 4px" @click="doSearch" />
            <iconFont type="icon-quxiao1" style="font-size: 32px" @click="clearQuery" />
          </a-input-group>
        </template>
        <template v-if="mode === 'result'">
          <div class="search-bar">
            <a-button type="link" style="margin-right: 6px" @click="reset">
              <template #icon>
                <RollbackOutlined style="color: #48e7ff" />
              </template>
            </a-button>
            <a-input read-only class="text" :value="'搜索 ' + query + ' 共 ' + (searching ? '...' : total) + ' 条结果'" />
          </div>
        </template>
      </template>
      <a-list
        v-if="mode === 'result'"
        :data-source="results"
        :loading="searching"
        item-layout="vertical"
        size="small"
        class="result-list"
      >
        <template #renderItem="{item}">
          <a-list-item>
            <a-list-item-meta :description="'地址：' + item.address">
              <template #title>
                <span style="cursor: pointer" @click="focus(item)">{{ item.name }}</span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
        <template #footer>
          <a-pagination
            v-if="total > 0"
            v-model:current="currentPage"
            v-model:page-size="currentPageSize"
            :total="total"
            size="small"
            show-less-items
            :page-size-options="['30', '50', '100']"
            @change="
              (current, pageSize) => {
                currentPage = current;
                if (currentPageSize != pageSize) {
                  currentPageSize = pageSize;
                  currentPage = 1;
                }
                ps.setPageIndex(currentPage);
                ps.setPageSize(currentPageSize);
                search(false);
              }
            "
          />
        </template>
      </a-list>
    </a-card>
    <div class="info ant-card ant-card-bordered">
      <a-form layout="inline">
        <a-col>
          <a-row>
            <a-col>
              <a-form-item v-if="xAxis" label="经纬度">
                <p style="width: 180px">（{{ positionText }}）</p>
              </a-form-item>
            </a-col>
            <a-col>
              <a-form-item label="卫星图">
                <a-switch v-model:checked="satelliteChecked" @change="satelliteIsVisible()" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-form-item v-if="fullAddress" label="当前位置" :wrapper-col="{span: 18}">
              <p style="width: 260px">{{ fullAddress }}</p>
            </a-form-item>
          </a-row>
        </a-col>
      </a-form>
      <a-form />
    </div>
    <div id="current-position">
      <a-button id="position-button" type="link" shape="circle" @click="currentLocation()">
        <template #icon>
          <AimOutlined style="color: #707070" />
        </template>
      </a-button>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import {RollbackOutlined, AimOutlined} from '@ant-design/icons-vue';
import {city} from '@/components/divform/city';
export default {
  components: {RollbackOutlined, AimOutlined},
  props: {
    xAxis: {
      type: Number,
      default: () => {
        return 116.397_496;
      },
    },
    yAxis: {
      type: Number,
      default: () => {
        return 39.907_962;
      },
    },
    fullAddress: {
      type: String,
    },
    address: {
      type: Array,
      default: () => {
        return ['11', '1101', '110101'];
      },
    },
  },
  inject: ['curTheme'],
  data() {
    return {
      mode: 'search',
      center: undefined,
      zoom: 10,
      query: '',
      searching: false,
      tips: [],
      results: [],
      markerList: [],
      // 用户当前地址定位的坐标
      locationPoint: undefined,
      currentPage: 1,
      currentPageSize: 30,
      total: 0,
      satelliteChecked: false,
    };
  },
  computed: {
    positionText() {
      return this.xAxis ? `${this.xAxis}, ${this.yAxis}` : '';
    },
    position() {
      return [this.xAxis, this.yAxis];
    },
  },
  watch: {
    xAxis() {
      this.currentMarker.setPosition(this.position);
      this.map.setCenter(this.position);
    },
    yAxis() {
      this.currentMarker.setPosition(this.position);
      this.map.setCenter(this.position);
    },
  },
  created() {},
  mounted() {
    AMapLoader.load({})
      .then((AMap) => {
        this.map = new AMap.Map('map-container', {
          zoom: 11, // 级别
          center: [116.397_428, 39.909_23],
          viewMode: '3D',
          showBuildingBlock: false,
        });
        if (this.curTheme === 'light') {
          this.map.setMapStyle('amap://styles/e4fc8194679f35fc57c8bbe6b0a2bd8e');
        } else {
          this.map.setMapStyle('amap://styles/a09a958ae86e5d6271b7780237106eeb');
        }

        this.currentMarker = new AMap.Marker({
          icon: '/static/pc/img/当前坐标点.svg',
          offset: new AMap.Pixel(-16, -28),
          zIndex: 101,
        });
        this.changePosition(this.position);
        this.currentMarker.setPosition(this.position);
        this.geocoder = new AMap.Geocoder({
          city: '010',
        });
        this.getAddressInfo(this.position, true);
        this.satellite = new AMap.TileLayer.Satellite();
        this.map.add(this.currentMarker);
        this.map.add(this.markerList);
        this.ps = new AMap.PlaceSearch({
          pageSize: this.currentPageSize,
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          citylimit: false,
        });
        this.ac = new AMap.AutoComplete();
        this.map.on('click', (event) => {
          this.onMapClick(event);
        });
        this.generateDefaultFullAddress();
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    executeConditionalQuery() {
      XPathResult = document.evaluate(".//button/span[text()='搜 索']", document, undefined, XPathResult.ANY_TYPE);
      XPathResult.iterateNext().click();
    },
    getAddressInfo(pos, isOnlyAddress) {
      this.geocoder.getAddress(pos, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          if (!isOnlyAddress) {
            this.$emit('update:fullAddress', result.regeocode.formattedAddress);
          }
          const addressComponent = result.regeocode.addressComponent;
          let addressInfo = {};
          addressInfo.province = addressComponent.province;
          addressInfo.city = addressComponent.city == '' ? addressComponent.province : addressComponent.city;
          addressInfo.district = addressComponent.district;
          const address = this.getAddress(addressInfo);
          this.$emit('update:address', address);
        } else {
          console.error('根据经纬度查询地址失败');
        }
      });
    },
    satelliteIsVisible() {
      if (this.satelliteChecked) {
        this.map.addLayer(this.satellite);
      } else {
        this.map.removeLayer(this.satellite);
      }
    },
    generateDefaultFullAddress() {
      if (this.fullAddress === undefined) {
        this.getAddressInfo(this.position, false);
      }
    },
    changePosition(pos) {
      this.$emit('update:xAxis', pos[0]);
      this.$emit('update:yAxis', pos[1]);
    },
    onMapClick(event) {
      if (event.lnglat) {
        this.changePosition([event.lnglat.lng, event.lnglat.lat]);
        this.getAddressInfo(event.lnglat, false);
      }
    },
    clearQuery() {
      this.query = undefined;
      this.reset();
    },
    doSearch() {
      if (this.query) {
        this.search(true);
      }
    },
    onSelect() {
      this.search(true);
    },
    async search(clear = false) {
      this.mode = 'result';
      await this._ready;
      if (clear) {
        this.results = [];
        this.total = 0;
        this.currentPage = 1;
        this.ps.setPageIndex(this.currentPage);
      }
      this.searching = true;
      const {query} = this;
      this.ps.search(query, (status, result) => {
        this.searching = false;
        if (query !== this.query) return;
        this.map.remove(this.markerList);
        this.markerList = [];
        if (status === 'complete' && result.poiList) {
          this.results = result.poiList.pois;
          this.total = this.total = result.poiList.count;
          if (this.total === 1) {
            this.focus(this.results[0]);
          } else {
            for (const result of this.results) {
              const marker = new AMap.Marker({
                icon: '/static/pc/img/坐标点.svg',
                offset: new AMap.Pixel(-16, -28),
              });
              const pos = [result.location.lng, result.location.lat];
              marker.setPosition(pos);
              marker.on('click', (event) => {
                this.focus(result);
                this.getAddressInfo(event.lnglat, false);
              });
              this.markerList.push(marker);
            }
            if (this.markerList.length > 0) {
              this.map.add(this.markerList);
            }
          }
        } else {
          this.results = [];
          this.total = 0;
        }
      });
    },
    async autoComplete(kw) {
      if (kw == undefined) {
        this.tips = [];
      } else {
        this.ac.search(kw, (status, result) => {
          if (kw !== this.query) return;
          this.tips =
            status === 'complete' && result.tips
              ? [...new Set(result.tips.map((tip) => tip.name))].map((item) => {
                  return {value: item};
                })
              : [];
        });
      }
    },
    focus(poi) {
      const pos = [poi.location.lng, poi.location.lat];
      this.center = [...pos];
      const fullAddress = poi.name + '(' + poi.address + ')';
      this.$emit('update:fullAddress', fullAddress);
      this.changePosition(pos);
      this.getAddressInfo(pos, true);
    },
    reset() {
      this.results = [];
      this.tips = [];
      this.total = 0;
      this.mode = 'search';
      this.map.remove(this.markerList);
    },
    currentLocation() {
      if (this.locationPoint === undefined) {
        AMapLoader.load({}).then((AMap) => {
          const geolocation = new AMap.Geolocation({
            enableHighAccuracy: true,
            timeout: 10_000,
          });
          geolocation.getCurrentPosition((status, result) => {
            if (status == 'complete') {
              this.locationPoint = [result.position.lng, result.position.lat];
              this.locateCurrentPos();
            } else {
              const citySearch = new AMap.CitySearch();
              citySearch.getLocalCity((status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  const cityIpArray = result.rectangle.split(';');
                  const xAxis =
                    (Number.parseFloat(cityIpArray[0].split(',')[0]) + Number.parseFloat(cityIpArray[1].split(',')[0])) / 2;
                  const yAxis =
                    (Number.parseFloat(cityIpArray[0].split(',')[1]) + Number.parseFloat(cityIpArray[1].split(',')[1])) / 2;
                  this.locationPoint = [xAxis, yAxis];
                  this.locateCurrentPos();
                }
              });
            }
          });
        });
      } else {
        this.locateCurrentPos();
      }
    },
    locateCurrentPos() {
      this.changePosition(this.locationPoint);
      this.getAddressInfo(this.locationPoint, false);
    },
    getAddress(addressInfo) {
      let address = [];
      const cityData = city();
      for (const province of cityData) {
        if (province.label == addressInfo.province) {
          address.push(province.value);
          for (const city of province.children) {
            if (city.label == addressInfo.city) {
              address.push(city.value);
              for (const district of city.children) {
                if (district.label == addressInfo.district) {
                  address.push(district.value);
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
      return address;
    },
  },
};
</script>

<style lang="less" scoped>
#map-container {
  width: 100%;
  height: 500px;
}

.result-panel {
  position: absolute;
  z-index: 9999;
  left: 10px;
  top: 10px;
  width: 400px;
  display: flex;
  flex-direction: column;

  .search-bar {
    display: flex;
    align-items: center;
    z-index: 9999;
    .text {
      text-overflow: ellipsis;
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      border: 0px;
      font-size: 15px;
    }
  }

  .result-list.ant-list-loading {
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
}
.info {
  position: absolute;
  z-index: 9999;
  right: 10px;
  top: 10px;
  padding-left: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
}
#current-position {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
  width: 50px;
  height: 50px;
  #position-button {
    width: 30px;
    height: 32px;
    background-color: var(--body-background);
  }
}

/deep/ .ant-card-head {
  padding-right: 12px;
}

/deep/ .ant-list-item-meta {
  margin-bottom: 0px;
}

/deep/ .ant-list-item-meta-title {
  margin-bottom: 0px;
}
</style>
