<template>
  <a-style-provider :transformers="[legacyLogicalPropertiesTransformer]">
    <div id="app">
      <a-config-provider :locale="zhCN" :theme="curThemeObj">
        <!-- #3734 开始 //不知道原因，加入以下代码问题消失。antdv更新后，验证后，删除 -->
        <a-row style="height: 0px">
          <a-col>
            <a-row> <a-col></a-col> </a-row
          ></a-col>
          <a-col>
            <a-row>
              <a-col></a-col>
            </a-row>
          </a-col>
        </a-row>
        <!-- #3734 结束 -->

        <router-view />
      </a-config-provider>
    </div>
  </a-style-provider>
</template>

<script>
import {legacyLogicalPropertiesTransformer} from 'ant-design-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import {getTheme} from '@/themes/themes';
import {mapGetters} from 'vuex';
import {computed} from 'vue';

export default {
  name: 'App',
  data() {
    return {
      zhCN,
      time: undefined,
    };
  },
  computed: {
    ...mapGetters(['curTheme']),
    curThemeObj() {
      return getTheme(this.curTheme);
    },
  },
  provide() {
    return {
      curTheme: computed(() => this.curTheme),
    };
  },

  methods: {
    legacyLogicalPropertiesTransformer() {
      return legacyLogicalPropertiesTransformer;
    },
  },
  created() {
    // 当前主题可能是刷新后从session等恢复的，需要重新设置一下。
    // this.$store.commit('SET_CUR_THEME', this.curTheme);

    window.onload = () => {
      const iconConfigs = require('../static/iconConfig.json');
      for (const iconConfig of iconConfigs) {
        let svgNS = 'http://www.w3.org/2000/svg';
        let symbol = document.createElementNS(svgNS, 'symbol');
        symbol.setAttribute('id', 'icon-' + iconConfig.title);
        symbol.setAttribute('viewBox', '0 0 24 24');
        let path = document.createElementNS(svgNS, 'path');
        path.setAttribute('d', iconConfig.d);
        path.setAttribute('fill', '#48E7FF');
        path.setAttribute('fill-rule', 'nonzero');
        if (iconConfig.transform) {
          path.setAttribute('transform', iconConfig.transform);
        }
        symbol.append(path);
        document.querySelectorAll('svg')[0].append(symbol);
      }
    };
  },
};
</script>
<style>
html {
  font-size: 100px;
}
</style>
