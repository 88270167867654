import request from '@/utils/request';

export function getCustomDomainConfigList(loginSystemName, domainName, page, size) {
  return request({
    url: '/iot/customDomainConfigs',
    method: 'get',
    params: {
      loginSystemName: loginSystemName,
      domainName: domainName,
      page: page,
      size: size,
    },
  });
}

export function getCustomDomainConfigById(id) {
  return request({
    url: '/iot/customDomainConfigs/' + id,
    method: 'get',
  });
}

export function getAllDomainNames(id) {
  return request({
    url: '/iot/customDomainConfigs/getAllDomainNames',
    method: 'get',
  });
}

export function addCustomDomainConfig(data) {
  return request.postForm('/iot/customDomainConfigs', data);
}

export function deleteCustomDomainConfig(id) {
  return request({
    url: '/iot/customDomainConfigs/' + id,
    method: 'delete',
  });
}

export function updateCustomDomainConfig(id, data) {
  return request.putForm('/iot/customDomainConfigs/' + id, data);
}

export function ixExistDomainName(id, domainName) {
  return request({
    url: '/iot/customDomainConfigs/isExistDomainName',
    method: 'get',
    params: {
      id: id,
      domainName: domainName,
    },
  });
}

export function findByDomainName(domainName) {
  return request({
    url: '/iot/customDomainConfigs/findByDomainName?domainName=' + domainName,
    method: 'get',
  });
}

export function isExistLoginSystemName(id, loginSystemName) {
  return request({
    url: '/iot/customDomainConfigs/isExistLoginSystemName',
    method: 'get',
    params: {
      id: id,
      loginSystemName: loginSystemName,
    },
  });
}
