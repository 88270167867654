<template>
  <a-layout>
    <a-layout-header class="layout-header">
      <Navbar />
    </a-layout-header>
    <h1 v-if="$store.getters.payStatus == 'D'">此项目已经长期欠费，请您公司商务人员及时续费，以便继续使用!</h1>
    <h1 v-if="$store.getters.payStatus == 'E' || $store.getters.payStatus == 'F'">此项目已经长期停用，项目软件服务已经终止!</h1>
  </a-layout>
</template>

<script>
import {AppMain, Navbar, Sidebar} from './components';
import {getProjectIdByProjectGroupId, getNavProject} from '@/api/project';
import {computed} from 'vue';
import store from '@/store';
import router from '@/router';
export default {
  name: 'ArrearageLayout',
  components: {
    Navbar,
  },
  data() {
    return {
      shrinkMenu: true,
    };
  },
  provide() {
    return {
      shrinkMenu: computed(() => this.shrinkMenu),
      switchShrink: this.switchShrink,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.groupId == undefined) {
      next((vm) => {
        getProjectIdByProjectGroupId(vm.$store.getters.projectGroupId).then((res) => {
          getNavProject(res.data).then((res1) => {
            store.commit('SET_TOPTOOLTITLE', res1.data.name);
            store.commit('SET_PROJECT_GROUP_ID', res1.data.projectGroupId);
            store.commit('SET_PROJECTTYPE_ID', res1.data.projectTypeId);
            store.commit('SET_GROUPDISPLAYNAME', res1.data.groupDisplayName);
            store.commit('SET_LOGO_URL', res1.data.logoUrl);
            store.commit('SET_PAY_STATUS', res1.data.payStatus);
            if (store.getters.payStatus == 'D' || store.getters.payStatus == 'E' || store.getters.payStatus == 'F') {
              router.push({
                path: '/arrearageProjectPage',
              });
              return;
            } else {
              store.commit('SET_GROUPID', res1.data.projectGroupId);
              store.dispatch('GenerateRoutes', {type: 'project', id: res1.data.projectGroupId, checkHas: true}).then((res2) => {
                let name = vm.$route.name;
                if (!res2) {
                  vm.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                  return;
                }
                if (store.getters.frontMenu[4] != undefined) {
                  const curGroupId = store.getters.frontMenu[4][0].groupId;
                  if (
                    !vm.hasRoute(name, {
                      groupId: curGroupId,
                    }) ||
                    vm.$route.meta.hidden == 1
                  ) {
                    name = 'any';
                  }
                  vm.routePush(name, {groupId: curGroupId}, {});
                } else {
                  vm.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                }
              });
            }
          });
        });
      });
    }
  },
  methods: {
    switchShrink() {
      this.shrinkMenu = !this.shrinkMenu;
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layout-header {
  box-sizing: content-box;
  height: 51px;
  padding-left: 0px;
  padding-right: 24px;
  position: relative;
  line-height: 32px;
  flex-basis: 50px;
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  z-index: 996;
  border-bottom: 1px solid transparent;
  background: var(--navbar-bk);

  &::after {
    box-sizing: border-box;
    content: '';
    position: absolute;
    width: 100%;
    height: 51px;
    background: var(--navbar-right-bk);
    z-index: -100;
    border-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 616px,
        rgba(0, 240, 255, 0.34) 617px,
        rgba(72, 231, 255, 1)
      )
      10 10;
    border-bottom: 1px solid;
  }

  .img {
    z-index: -100;
    position: relative;
  }
}

.theme-tech {
  .layout-header {
    background: linear-gradient(to right, rgba(72, 231, 255, 0.5), rgba(0, 88, 119, 0.9) 512px);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    &::after {
      background: url('/static/pc/img/navbar_right.png') no-repeat right;
    }
  }
}

.theme-light {
  .layout-header {
    background: linear-gradient(to right, #428eff, #4262ff 512px);
    box-shadow: 0 6px 12px 0 #0d184d33;
    &::after {
      background: url('/static/pc/img/navbar_right_light.png') no-repeat right;
    }
  }
}
</style>
