<template>
  <router-view />
</template>

<script>
import store from '@/store';
import {gatewayFirmByUserId as firmDetail} from '@/api/gatewayFirm';
import {getNavProject} from '@/api/project';

export default {
  name: 'EmptyLayout',
  data() {
    return {};
  },
  computed: {},
  watch: {},
  beforeRouteEnter(to, from, next) {
    switch (to.meta.subSystem) {
      case 2:
        if (to.meta.subSystem == from.meta.subSystem && to.params.firmId == from.params.firmId) {
          next();
          return;
        }
        store.commit('SET_PROJECT_GROUP_ID');
        firmDetail(to.params.firmId).then((response) => {
          store.commit('SET_TOPTOOLTITLE', response.data.gatewayFirm.name);
          next();
        });
        break;
      case 4:
        const requests = [];
        let shouldUpdateProject = false;
        if (to.meta.subSystem != from.meta.subSystem || to.params.groupId != from.params.groupId) {
          requests.push(getNavProject(to.params.groupId));
          shouldUpdateProject = true;
        }
        Promise.all(requests).then((responses) => {
          if (shouldUpdateProject) {
            const navProjectResponse = responses.shift();
            if (navProjectResponse.code == 20000) {
              store.commit('SET_TOPTOOLTITLE', navProjectResponse.data.name);
              store.commit('SET_PROJECT_GROUP_ID', navProjectResponse.data.projectGroupId);
              store.commit('SET_PROJECTTYPE_ID', navProjectResponse.data.projectTypeId);
              store.commit('SET_GROUPDISPLAYNAME', navProjectResponse.data.groupDisplayName);
              store.commit('SET_LOGO_URL', navProjectResponse.data.logoUrl);
            }
          }
          next();
        });
        break;
      case 7:
        if (to.meta.subSystem == from.meta.subSystem || to.params.groupId == from.params.groupId) {
          next();
          return;
        }
        getNavProject(to.params.groupId).then((res) => {
          store.commit('SET_TOPTOOLTITLE', res.data.name);
          store.commit('SET_PROJECT_GROUP_ID', res.data.projectGroupId);
          store.commit('SET_PROJECTTYPE_ID', res.data.projectTypeId);
          store.commit('SET_GROUPDISPLAYNAME', res.data.groupDisplayName);
          store.commit('SET_LOGO_URL', res.data.logoUrl);
          next();
        });
        break;
      default:
        next();
        break;
    }
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
