<template>
  <div>
    <a-row class="navbar" justify="space-between" align="top">
      <a-col class="navbar-left">
        <div id="guanbicaidan" @click="switchShrink()">
          <iconFont :type="$store.getters.shrinkMenu ? 'icon-guanbicaidan' : 'icon-caidan'" style="font-size: 24px" />
        </div>
        <a-divider type="vertical" class="vertical-linear-gradient-divider" />
        <div class="navTitle">
          <div class="imgclass" @click="backProject()">
            <img
              class="img"
              height="40"
              :src="
                [$SUBSYSTEM_PROJECT, $SUBSYSTEM_CORPORATION].includes(subSystem)
                  ? logoUrl
                    ? logoUrl
                    : systemLogoUrl
                  : systemLogoUrl
              "
              alt=""
            />
          </div>
          <a-divider class="vertical-divider" type="vertical" />
          <div class="sub-system">
            <a-dropdown
              v-model:open="isDisplay"
              placement="bottomLeft"
              :trigger="['click']"
              :overlay-style="{'backdrop-filter': 'blur(10px)'}"
            >
              <div class="ant-dropdown-link">
                <span class="topToolTitleClass">{{ topToolTitle }}</span>
                <iconFont
                  type="icon-jiantou"
                  :style="isDisplay ? 'font-size: 15px;transform: rotate(-180deg)' : 'font-size: 15px;'"
                />
              </div>
              <template #overlay>
                <div class="subSystemIcon-m">
                  <a-menu :selected-keys="[]">
                    <a-spin :spinning="loadingMoreProjectList">
                      <a-tabs
                        :active-key="activeKey"
                        :tab-position="mode"
                        :style="{height: '256px'}"
                        :type="type"
                        :tab-bar-gutter="tabBarGutter"
                        class="tab-trigger"
                        @tabClick="tabsClick($event)"
                      >
                        <a-tab-pane v-if="corporationList && corporationList.length > 0" key="5" :tab="`集团`">
                          <a-menu-item
                            v-for="item in corporationList"
                            :key="item.id"
                            :class="['tab-menu-trigger', topToolTitle === item.name ? 'tab-menu-trigger-active' : '']"
                            @click="
                              switchMenu($SUBSYSTEM_CORPORATION, item.name, {
                                groupId: item.id,
                              })
                            "
                          >
                            <span style="display: block">{{ item.name }}</span>
                          </a-menu-item>
                        </a-tab-pane>
                        <a-tab-pane key="1" :tab="`项目`">
                          <a-menu-item
                            v-for="item in projectList"
                            :key="item.id"
                            :class="['tab-menu-trigger', topToolTitle === item.name ? 'tab-menu-trigger-active' : '']"
                            @click="
                              switchMenu($SUBSYSTEM_PROJECT, item.name, {
                                groupId: item.id,
                              })
                            "
                          >
                            <span style="display: block">{{ item.name }}</span>
                          </a-menu-item>
                        </a-tab-pane>
                        <a-tab-pane v-if="firmList && firmList.length > 0" key="2" :tab="`厂商`">
                          <a-menu-item
                            v-for="item in firmList"
                            :key="item.id"
                            :class="['tab-menu-trigger', topToolTitle === item.gatewayFirm.name ? 'tab-menu-trigger-active' : '']"
                            @click="switchMenu($SUBSYSTEM_FIRM, item.name, {firmId: item.gatewayFirm.id})"
                          >
                            <span style="display: block">{{ item.gatewayFirm.name }}</span>
                          </a-menu-item>
                        </a-tab-pane>
                      </a-tabs>
                    </a-spin>
                  </a-menu>
                  <div class="flootMenus" :style="flootMenuStyle(firmListSize, corporationListSize)">
                    <div
                      v-if="frontMenu[3] && frontMenu[3].length > 0"
                      class="flootMenu"
                      style="bottom: 37px"
                      @click="tabsClick('3')"
                    >
                      <span>系统管理</span>
                      <iconFont type="icon-jiantou" class="toRightIcon" style="margin-left: 23px" />
                    </div>
                    <div class="flootMenu" style="bottom: 0px" @click="tabsClick('4')">
                      <span>我的项目</span>
                      <iconFont type="icon-jiantou" class="toRightIcon" style="margin-left: 23px" />
                    </div>
                  </div>
                </div>
              </template>
            </a-dropdown>
          </div>
        </div>
      </a-col>
      <a-col class="navbar-right">
        <a-row justify="end" :gutter="16">
          <a-col class="subSystemIcon margin-right-10">
            <a-dropdown :trigger="['click']" placement="bottom" overlay-class-name="navbar-img-dropdown" arrow>
              <iconFont title="小程序" type="icon-erweima" style="font-size: 24px" class="highlight-shadow" />
              <template #overlay>
                <img
                  src="/static/pc/img/applets.jpg"
                  width="129"
                  height="147"
                  style="
                    image-rendering: -moz-crisp-edges;
                    image-rendering: -o-crisp-edges;
                    image-rendering: -webkit-optimize-contrast;
                    image-rendering: crisp-edges;
                    -ms-interpolation-mode: nearest-neighbor;
                  "
                />
              </template>
            </a-dropdown>
          </a-col>
          <a-col class="subSystemIcon margin-right-10">
            <a-dropdown :trigger="['click']" placement="bottom" overlay-class-name="navbar-shutcut-dropdown" arrow>
              <iconFont title="工具集" class="highlight-shadow" type="icon-gongjuji" style="font-size: 24px" />
              <template #overlay>
                <a-menu>
                  <template v-for="(item, index) of toolSetDropdown" :key="item.activeKey">
                    <template v-if="index != 0">
                      <a-menu-divider class="horizontal-linear-gradient-divider" />
                    </template>
                    <a-menu-item @click="switchMenu($SUBSYSTEM_MESSAGE, '工具集', undefined, {activeKey: item.activeKey})">
                      <a-space :size="8">
                        <iconFont :type="item.iconType" style="font-size: 24px" />
                        <span class="menu-item-name">{{ item.menuItemName }}</span>
                      </a-space>
                    </a-menu-item>
                  </template>
                </a-menu>
              </template>
            </a-dropdown>
          </a-col>
          <a-col class="subSystemIcon margin-right-10" @click="switchMenu($SUBSYSTEM_AUDIT, '我的审批', undefined)">
            <template v-if="$store.getters.auditList.length > 0">
              <a-badge class="subSystemIcon-badge" dot color="#f05b72" text="#f05b72" :offset="[0, 0]">
                <iconFont title="我的审批" type="icon-shenhe" style="font-size: 24px" class="highlight-shadow" />
              </a-badge>
            </template>
            <template v-else>
              <iconFont title="我的审批" type="icon-shenhe" style="font-size: 24px" class="highlight-shadow" />
            </template>
          </a-col>
          <a-col class="subSystemIcon ant-dropdown-link">
            <template v-if="$store.getters.messageList.length > 0">
              <a-badge class="subSystemIcon-badge" dot color="#f05b72" text="#f05b72" :offset="[0, 0]">
                <iconFont
                  title="我的消息"
                  type="icon-xiaoxi"
                  style="font-size: 24px"
                  class="highlight-shadow"
                  @click="switchMenu($SUBSYSTEM_MESSAGE, '我的消息', undefined)"
                />
              </a-badge>
            </template>
            <template v-else>
              <iconFont
                title="我的消息"
                type="icon-xiaoxi"
                style="font-size: 24px"
                class="highlight-shadow"
                @click="switchMenu($SUBSYSTEM_MESSAGE, '我的消息', undefined)"
              />
            </template>
            <div>
              <a-drawer
                v-model:open="visible"
                title="消息通知"
                placement="right"
                :closable="false"
                class="message-drawer"
                width="420"
              >
                <template v-if="$store.getters.messageList.length > 0">
                  <p v-for="item in $store.getters.messageList" :key="item.id" class="message-content">
                    {{ item.content }}
                    <br />
                    <a-row class="message-createdAt">
                      <a-col>
                        {{ dateFormat(item.createdAt) }}
                      </a-col>
                    </a-row>
                  </p>
                  <div
                    class="message-footer"
                    :style="{
                      borderTop: '1px solid #e9e9e9',
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      width: '100%',
                      padding: '10px 16px',
                      textAlign: 'right',
                      zIndex: 1,
                    }"
                  >
                    <a-button block class="message-button" @click="switchMenu($SUBSYSTEM_MESSAGE, '我的消息', undefined)">
                      查看更多
                    </a-button>
                  </div>
                </template>
                <template v-else>
                  <p style="color: #ffffff">您暂时没有消息。</p>
                  <div
                    :style="{
                      borderTop: '1px solid #e9e9e9',
                      background: '#033b68',
                      position: 'absolute',
                      right: 0,
                      bottom: 0,
                      width: '100%',
                      padding: '10px 16px',
                      textAlign: 'right',
                      zIndex: 1,
                    }"
                  >
                    <a-button block class="message-button" @click="switchMenu($SUBSYSTEM_MESSAGE, '我的消息', undefined)">
                      查看更多
                    </a-button>
                  </div>
                </template>
              </a-drawer>
            </div>
          </a-col>
          <a-col class="subSystemIcon">
            <a-divider class="vertical-divider" type="vertical" />
          </a-col>
          <a-col class="subSystemIcon">
            <a-dropdown :trigger="['click']" overlay-class-name="navbar-menu-dropdown" arrow>
              <div class="avatar-wrapper">
                <a-avatar size="middle" :src="avatar + '?imageView2/1/w/80/h/80'" class="user-avatar" />
                <iconFont class="anticon-arrow" type="icon-arrow" />
              </div>
              <template #overlay>
                <a-menu>
                  <template v-for="(item, index) of functionDropdown" :key="index">
                    <template v-if="item.menuItemName">
                      <template v-if="index != 0">
                        <a-menu-divider class="horizontal-linear-gradient-divider" />
                      </template>
                      <a-menu-item @click="item.click">
                        <a-space :size="8">
                          <iconFont :type="item.iconType" style="font-size: 24px" />
                          <span class="menu-item-name">{{ item.menuItemName }}</span>
                        </a-space>
                      </a-menu-item>
                    </template>
                  </template>
                </a-menu>
              </template>
            </a-dropdown>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <Guide v-if="guideStatus" :after-close="hasReadGuide" />
    <Share v-if="shareStatus" :after-close="closeShareView" />
  </div>
</template>

<script>
import {defineComponent, h, ref} from 'vue';
import {mapGetters} from 'vuex';
import {getMyProjectList, getNavProject} from '@/api/project';
import {queryCorporationList} from '@/api/corporation';
import {changePassword, updateIsReadGuide, userDetail} from '@/api/user';
import {gatewayFirmlistByUserId} from '@/api/gatewayFirm';
import {checkLeftResource} from '@/api/system';
import {getAllMessageTemplates, getMessagesByParam, updateMessages} from '@/api/message';
import dayjs from 'dayjs';
import {loadMore} from '../../../utils/kunlun';
import {getIncludeSubMenuGroupTree} from '@/api/group';
import {DownOutlined} from '@ant-design/icons-vue';
import {queryAuditResult} from '@/api/dataRepair';
import Guide from '@/views/guide/detail.vue';
import {notification, Button} from 'ant-design-vue';
import {getLeftAccessGatewayIndicatorByGroupId} from '@/api/projectOverview';

import Share from '@/views/share/useShareDetail';

export default defineComponent({
  setup(props, context) {
    console.log(props, context);
    const mode = ref('left');
    const type = ref('card');
    const activeKey = ref('1');
    const tabBarGutter = ref(0);

    const visible = ref(false);
    const showDrawer = () => {
      visible.value = true;
    };
    const gatewayFirmList = loadMore((currentPage, pageSize) => {
      return gatewayFirmlistByUserId(currentPage, pageSize);
    });
    const projectList = loadMore((currentPage, pageSize) => {
      return getMyProjectList(currentPage, pageSize);
    });
    const corporationList = loadMore((currentPage, pageSize) => {
      return queryCorporationList({byCurUser: true, page: currentPage, size: pageSize});
    });
    return {
      visible,
      showDrawer,
      firmList: gatewayFirmList.dataList,
      projectList: projectList.dataList,
      corporationList: corporationList.dataList,
      loadingMoreFirmList: gatewayFirmList.loadingMore,
      loadingMoreProjectList: projectList.loadingMore,
      loadingMoreCorporationList: corporationList.loadingMore,
      loadMoreFirmList: gatewayFirmList.loadMore,
      loadMoreProjectList: projectList.loadMore,
      loadMoreCorporationList: corporationList.loadMore,
      noMoreFirmList: gatewayFirmList.noMore,
      noMoreProjectList: projectList.noMore,
      noMoreCorporationList: corporationList.noMore,
      projectListRefresh: projectList.refresh,
      firmListRefresh: gatewayFirmList.refresh,
      corporationListRefresh: corporationList.refresh,
      mode,
      type,
      activeKey,
      tabBarGutter,
    };
  },
  mounted() {
    window.onresize = () => {
      this.clientHeight = document.documentElement.clientHeight;
    };
    this.isReadGuide();
  },
  components: {
    DownOutlined,
    Guide,
    Share,
  },
  data() {
    const validatePass = (rule, value) => {
      return value.length < 5 ? Promise.reject(new Error('密码不能小于5位')) : Promise.resolve();
    };
    const isSamePassword = (rule, value) => {
      if (value.length < 5) {
        return Promise.reject(new Error('密码不能小于5位'));
      } else if (value != this.temp.password) {
        return Promise.reject(new Error('两次输入的密码不一致'));
      } else {
        return Promise.resolve();
      }
    };
    return {
      shareStatus: false,
      isShowHiddenMessage: true,
      clientHeight: document.documentElement.clientHeight,
      messageKeyList: [],
      messageCount: 0,
      guideStatus: false,
      readGuideStatus: undefined,
      visibleSubMenu: false,
      loadMoreList: this.loadMoreProjectList,
      temp: {
        id: '',
        password: '',
        passwordrepead: '',
      },
      rules: {
        password: [
          {
            required: true,
            trigger: 'blur',
            message: '新密码不能为空',
          },
          {
            validator: validatePass,
            trigger: 'blur',
          },
        ],
        passwordrepead: [
          {
            required: true,
            trigger: 'blur',
            validator: isSamePassword,
          },
        ],
      },
      topToolTitle: this.$store.getters.toptooltitle,
      username: this.mobile,
      isDisplay: false,
      curProject: undefined,
      curArea: undefined,
      curSite: undefined,
      curGroup: this.$route.params.groupId,
      current: 1,
      openKeys: [],
      messageTypeObj: {},
      stompClient: undefined,
      qrCodeVisible: false,
      toolSetDropdown: [
        {
          menuItemName: '查找设备',
          activeKey: 'gatewayDetail',
          iconType: 'icon-chazhaoshebei',
        },
        {
          menuItemName: '数据查看',
          activeKey: 'historicalData',
          iconType: 'icon-shujuchakan',
        },
        {
          menuItemName: '远程控制',
          activeKey: 'equipmentRemoteControl',
          iconType: 'icon-yuanchengkongzhi',
        },
        {
          menuItemName: '实时数据',
          activeKey: 'realTimeData',
          iconType: 'icon-jianceshuju',
        },
        {
          menuItemName: '接入配置',
          activeKey: 'indicatorConfig',
          iconType: 'icon-jierupeizhi',
        },
        {
          menuItemName: '串口配置',
          activeKey: 'modbusConfig',
          iconType: 'icon-shenhe',
        },
        {
          menuItemName: '传感器接入',
          activeKey: 'sensorAccess',
          iconType: 'icon-chuanganqijieru',
        },
      ],
      userFunctionDropdown: [
        {
          menuItemName: '个人中心',
          iconType: 'icon-gerenzhongxin',
          click: this.userCenter,
        },
        {
          menuItemName: '我的消息',
          iconType: 'icon-xiaoxi',
          click: this.messageCenter,
        },
        {
          menuItemName: '我的审批',
          iconType: 'icon-shenhe',
          click: () => {
            this.switchMenu(this.$SUBSYSTEM_AUDIT, '我的审批');
          },
        },
        {
          menuItemName: '新手引导',
          iconType: 'icon-zhaomingxitong',
          click: this.openGuide,
        },
        {
          menuItemName: '使用分享码',
          iconType: 'icon-fenxiang',
          click: this.openShare,
        },
        {
          menuItemName: '退出',
          iconType: 'icon-tuichu',
          click: this.logout,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'avatar',
      'name',
      'userId',
      'mobile',
      'realname',
      'roles',
      'subSystem',
      'firmId',
      'groupId',
      'messageList',
      'frontMenu',
      'projectGroupId',
      'isShowMessage',
      'isMuteMessageSound',
      'otherMessageOperate',
      'warningMessageOperate',
      'equipmentMalfunctionMessageOperate',
      'equipmentMaintenanceMessageOperate',
      'token',
      'logoUrl',
      'systemLogoUrl',
    ]),
    firmListSize() {
      return this.firmList.length;
    },
    corporationListSize() {
      return this.corporationList.length;
    },
    functionDropdown() {
      const host = window.location.host;
      const regex = /(wulink\.tech|localhost|kunlun\.cloud)/;
      if (regex.test(host)) {
        const index = this.userFunctionDropdown.findIndex((obj) => {
          return obj.menuItemName === '新手引导';
        });
        const dropdown = this.userFunctionDropdown;
        dropdown.splice(index + 1, 0, {
          menuItemName: '返回首页',
          iconType: 'icon-shouye1',
          click: this.backToHomePage,
        });
        return dropdown;
      } else {
        return this.userFunctionDropdown;
      }
    },
  },
  watch: {
    '$store.getters.toptooltitle'(newVal, oldVal) {
      this.topToolTitle = newVal;
    },
    '$store.getters.realname'(newValue, oldValue) {
      this.realname = newValue;
    },
    '$store.getters.listRefreshFlag'(newVal, oldVal) {
      if (newVal == 'project') {
        this.projectListRefresh();
      } else if (newVal == 'firm') {
        this.firmListRefresh();
      } else if (newVal == 'corporation') {
        this.corporationListRefresh();
      }
      this.$store.commit('SET_LIST_REFRESH_FLAG');
    },
    '$store.getters.groupMenu'(newVal, oldVal) {
      if (newVal == 'refresh') {
        this.getGroupMenu(this.projectGroupId);
      }
    },
    '$route.params.groupId'(newVal, oldVal) {
      if (newVal != undefined) {
        // this.getProjectAndGroup(this.projectGroupId);
      }
    },
    '$store.getters.projectGroupId'(newVal, oldVal) {
      if (newVal != undefined) {
        this.getGroupMenu(newVal);
      }
    },
    clientHeight(newValue, oldValue) {
      if (newValue) {
        this.controlMessageCount();
      }
    },
  },
  created() {
    this.getMessages();
    this.queryAuditResult();
    this.isReadGuide();
    // this.getProjectAndGroup(this.$route.params.groupId);
    if (this.$route.params.groupId) {
      this.getGroupMenu(this.projectGroupId);
    }
    window.addEventListener('scroll', this.scrollHandle, true);
    if (this.isShowMessage) {
      this.initWebSocket();
    }
    setTimeout(function () {
      checkLeftResource().then((res) => {
        for (let msg of res.data) {
          notification.error({
            message: msg.includes('到期') ? '资源即将到期提醒' : '资源不足提醒',
            description: msg,
            placement: 'topLeft',
            duration: 20,
          });
        }
      });
    }, 5000);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollHandle, true);
    this.closeWebSocket();
  },
  inject: ['switchShrink', 'shrinkMenu'],
  methods: {
    flootMenuStyle(firmListSize, corporationListSize) {
      let num = 0;
      if (firmListSize > 0) {
        num++;
      }
      if (corporationListSize > 0) {
        num++;
      }
      const topSize = 40 + num * 41;
      const heightSize = 217 - num * 41;
      return `top: ${topSize}px;height: ${heightSize}px;`;
    },
    closeShareView() {
      this.shareStatus = false;
    },
    openShare() {
      this.shareStatus = true;
    },
    updateMessages,
    getPopupContainer() {
      if (document.querySelector('.con_lf_top_div') !== null) {
        return document.querySelector('.con_lf_top_div');
      } else if (document.querySelector('.video-play-list') !== null) {
        return document.querySelector('.video-play-list');
      } else if (document.querySelector('.bf-canvas-wrapper') !== null) {
        return document.querySelector('.bf-canvas-wrapper');
      } else {
        return document.querySelector('#app');
      }
    },
    initWebSocket() {
      this.stompClient = this.getStompClient();
      const _this = this;
      this.subscribeTopic('/user/' + _this.userId + '/topic/messageCenter', function (response) {
        if (response.body !== undefined && response.command === 'MESSAGE') {
          _this.webSocketOnMessage(response.body);
        }
      });
      this.getAllMessageTemplates();
    },
    getAllMessageTemplates() {
      getAllMessageTemplates().then((response) => {
        for (const data of response.data) {
          this.messageTypeObj[data.type] = data.type;
        }
      });
    },
    webSocketOnOpen: function () {
      console.log('WebSocket连接成功');
    },
    webSocketOnError: function () {
      console.log('WebSocket连接发生错误');
    },
    controlMessageCount() {
      if (this.clientHeight > 915 ? this.messageCount > Math.floor(this.clientHeight / 220) : this.messageCount > 3) {
        for (let i = 0; i < this.messageCount - Math.round(this.clientHeight / 220); i++) {
          this.$notification.close(this.messageKeyList[0]);
          this.messageKeyList.shift();
          this.messageCount--;
        }
        if (this.isShowHiddenMessage) {
          const hideenMessageKey = `hideenMessageKey`;
          this.$notification.warning({
            message: '提醒隐藏通知',
            description: '提醒过多，已自动隐藏，请到我的消息查看',
            placement: 'bottomRight',
            duration: 0,
            getContainer: this.getPopupContainer,
            key: hideenMessageKey,
            btn: () =>
              h('div', [
                h(
                  Button,
                  {
                    type: 'default',
                    size: 'small',
                    onClick: () => {
                      this.messageCount = 0;
                      this.$notification.destroy();
                      this.isShowHiddenMessage = true;
                      this.messageKeyList = [];
                    },
                    style: {'margin-right': '6px'},
                  },
                  {default: () => '忽略全部'}
                ),
                h(
                  Button,
                  {
                    type: 'primary',
                    size: 'small',
                    onClick: () => {
                      this.routePush('router-messageCenter');
                      this.$notification.close(hideenMessageKey);
                      this.isShowHiddenMessage = true;
                    },
                  },
                  {default: () => '跳转我的消息'}
                ),
              ]),
            onClose: () => {
              this.isShowHiddenMessage = true;
            },
          });
          this.isShowHiddenMessage = false;
        }
      }
    },
    webSocketOnMessage(e) {
      const message = e;
      const type = message.slice(message.lastIndexOf('|') + 1, message.lastIndexOf('id:'));
      const title = type;
      let content = message.slice(0, message.lastIndexOf('|'));
      const id = message.slice(message.lastIndexOf('id:') + 3, message.length);
      if (Object.keys(this.messageTypeObj).length > 0 && ['设备故障通知', '设备故障解除通知'].includes(this.messageTypeObj[type])) {
        content = content.replace(this.messageTypeObj[type], '');
      }
      let formatterContent = '';
      if (type != '其它') {
        const strList = content.split(' ');
        const len = strList.length;
        for (let idx = 0; idx < len; ++idx) {
          if (strList[idx].includes('时间') && idx + 1 < len) {
            formatterContent += strList[idx] + ' ' + strList[idx + 1] + 'separator';
            idx++;
            continue;
          }
          formatterContent += strList[idx] + 'separator';
        }
      } else {
        formatterContent = content;
      }
      if (
        this.$route.params.projectId != undefined &&
        (formatterContent.includes('购买监测项资源成功') || formatterContent.includes('购买短信资源成功'))
      ) {
        getNavProject(this.$route.params.projectId).then((res) => {
          this.$store.commit('SET_SET_MEAL_END_AT', res.data.setMealEndAt);
          this.$store.commit('SET_GATEWAY_INDICATOR_MAX_NUM', res.data.accessGatewayIndicatorMaxNum);
          this.$store.commit('SET_SEND_MESSAGES_TOTAL_NUM', res.data.sendMessagesTotalNum);
          let now = dayjs(new Date()).format('YYYY-MM-DD');
          this.$store.commit('SET_GATEWAY_INDICATOR_EXPIRED', dayjs(res.data.setMealEndAt).isBefore(now));
          getLeftAccessGatewayIndicatorByGroupId(this.$route.params.groupId).then((res1) => {
            if (res.data.accessGatewayIndicatorMaxNum != -1 && res1.data < 0) {
              this.$store.commit('SET_IS_EXCEEDING_GATEWAY_INDICATOR_LIMIT', true);
            } else {
              this.$store.commit('SET_IS_EXCEEDING_GATEWAY_INDICATOR_LIMIT', false);
            }
          });
        });
      }
      const key = `open${Date.now()}`;
      const warningSound = new Audio('/static/tegy/1822.wav');
      const otherSound = new Audio('/static/pc/sound/除预警消息的提示音.mp3');
      if (!this.isMuteMessageSound) {
        if (this.messageTypeObj[type] === '监测预警通知' || this.messageTypeObj[type] === '监测预警解除通知') {
          warningSound.play();
        } else {
          otherSound.play();
        }
      }
      let duration;
      if (this.messageTypeObj[type] === '监测预警通知' || this.messageTypeObj[type] === '监测预警解除通知') {
        duration = this.warningMessageOperate == 0 ? 4.5 : 0;
      } else if (this.messageTypeObj[type] === '设备故障通知' || this.messageTypeObj[type] === '设备故障解除通知') {
        duration = this.equipmentMalfunctionMessageOperate == 0 ? 4.5 : 0;
      } else if (this.messageTypeObj[type] === '设备保养提醒' || this.messageTypeObj[type] === '设备保养提醒解除') {
        duration = this.equipmentMaintenanceMessageOperate == 0 ? 4.5 : 0;
      } else {
        duration = this.otherMessageOperate == 0 ? 4.5 : 0;
      }
      this.messageCount++;
      this.controlMessageCount();
      this.$notification.info({
        message: title,
        description: () => {
          const res = formatterContent.replaceAll('separator', '\n');
          return h(
            'pre',
            {
              style: {
                overflow: 'hidden',
                // 'max-height': '50vh',
                'font-family': 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif',
                'white-space': 'pre-wrap',
              },
            },
            res
          );
        },
        placement: 'topRight',
        duration: duration,
        getContainer: this.getPopupContainer,
        key,
        btn: () =>
          h('div', [
            h(
              Button,
              {
                type: 'default',
                size: 'small',
                onClick: () => {
                  this.messageCount--;
                  this.messageKeyList = this.messageKeyList.filter((item) => item != key);
                  this.$notification.close(key);
                },
                style: {'margin-right': '6px'},
              },
              {default: () => '忽略提醒'}
            ),
            h(
              Button,
              {
                type: 'primary',
                size: 'small',
                onClick: () => {
                  this.messageCount--;
                  this.messageKeyList = this.messageKeyList.filter((item) => item != key);
                  this.updateMessages(id, 'part');
                  this.routePush('router-messageCenter', {}, {activeKey: 1, selectedRowKeys: id});
                  this.$notification.close(key);
                },
              },
              {default: () => '立即查看'}
            ),
          ]),
        onClose: () => {
          this.messageCount--;
          this.messageKeyList = this.messageKeyList.filter((item) => item != key);
        },
      });
      this.messageKeyList.push(key);
    },
    closeWebSocket() {
      this.closeStompWebSocket();
    },
    backProject() {
      if (this.subSystem != this.$SUBSYSTEM_PROJECT) {
        return;
      }
      if (this.$route.params.groupId == this.projectGroupId) {
        this.routePush('any', {groupId: this.projectGroupId});
      } else {
        const id = this.projectGroupId;
        this.$store.dispatch('GenerateRoutes', {type: 'group', id: id}).then(() => {
          if (this.frontMenu[4] != undefined) {
            this.routePush('any', {groupId: id});
          } else {
            this.$notification.warning({
              message: '没有可访问的菜单!',
            });
          }
        });
      }
    },
    async scrollHandle() {
      const subSystemIconNode = document.querySelectorAll('.subSystemIcon-m')[0];
      if (subSystemIconNode && subSystemIconNode.parentNode.parentNode.style.display != 'none') {
        let testBox = document.querySelectorAll('.tab-trigger .ant-tabs-content');
        if (testBox[0] && testBox[0].scrollHeight - testBox[0].clientHeight - testBox[0].scrollTop === 0) {
          this.loadMoreList();
        }
      }
    },
    change(a) {},
    selectData() {
      this.getGroupTreeOption(true, this.$route.params.groupId).then((response) => {
        this.treeData = response;
      });
    },
    getProjectAndGroup(groupId) {
      this.curProject = undefined;
      this.curArea = undefined;
      this.curSite = undefined;
      if (this.$route.params.groupId && this.$route.params.groupId != -1) {
        getNavProject(this.$route.params.groupId).then((res) => {
          this.curProject = res.data;
          if (this.$route.params.groupId && this.$route.params.groupId != -1) {
            this.getGroupTreeOption(true, this.$route.params.groupId).then((response) => {
              response = JSON.parse(JSON.stringify(response).replace(/title/g, 'name').replace(/key/g, 'id'));
              this.curProject.groups = response;
              for (const area of response) {
                this.getCurSite(area);
              }
            });
          }
        });
      }
    },
    getCurSite(area) {
      if (area.id == this.$route.params.groupId) {
        this.curSite = area;
      } else if (area.children.length > 0) {
        this.openKeys.push(area.id);
        for (const site of area.children) {
          this.getCurSite(site);
        }
      }
    },
    dateFormat(time) {
      return dayjs(time).format('YYYY年MM月DD日 HH:mm:ss');
    },
    resettemp() {
      this.temp = {
        password: '',
        passwordrepead: '',
      };
    },
    changePassword() {
      this.$refs['dataForm'].validate().then(() => {
        changePassword(this.temp.password).then((res) => {
          this.$notification.success({
            title: '成功',
            message: '密码修改成功！',
            duration: 2000,
          });
        });
      });
    },
    handleChangePassword() {
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate();
      });
    },
    dropdownChange(visible) {},
    getGroupMenu(id) {
      if (id == undefined) {
        return;
      }
      getIncludeSubMenuGroupTree(id).then((response) => {
        if (response && response.data && response.data.length > 0) {
          this.dataToMenu(response.data[0].children, true);
          this.$store.commit('SET_GROUP_MENU', response.data[0].children);
        } else {
          this.$store.commit('SET_GROUP_MENU', []);
        }
      });
    },
    dataToMenu(data, stop) {
      if (!data) {
        return;
      }
      for (const group of data) {
        const pushObj = this.getGroupPush(group.id);
        const meta = {
          title: group.name,
          isGroupMenu: true,
        };
        if (pushObj) {
          group.name = pushObj.name;
          group.path = pushObj.path;
        } else {
          group.name = undefined;
          group.path = stop ? '/project' : '';
        }
        if (group.children && group.children.length > 0) {
          this.dataToMenu(group.children);
          if (group.children.length == 1) {
            group.spread = true;
          }
        } else {
          group.children = undefined;
        }
        group.groupId = group.id;
        group.meta = meta;
      }
    },
    getGroupPush(groupId) {
      return {};
    },
    getFirstChild(parent) {
      return parent.children && parent.children.length > 0 ? this.getFirstChild(parent.children[0]) : parent;
    },
    switchMenu(value, title, idObj, queryObj) {
      this.visibleSubMenu = false;
      if (queryObj == undefined) {
        queryObj = this.$route.query;
      }
      if (this.$SUBSYSTEM_SYSTEM === value) {
        let frontMenu = this.$store.getters.frontMenu[value][0];
        while (frontMenu.children != undefined && frontMenu.children.length > 0) {
          frontMenu = frontMenu.children[0];
        }
        this.routePush(frontMenu.name.slice(0, -2));
      } else if ([this.$SUBSYSTEM_MESSAGE, this.$SUBSYSTEM_USER_CENTER, this.$SUBSYSTEM_AUDIT].includes(value)) {
        if (title == '我的消息') {
          this.routePush('router-messageCenter');
        } else if (title == '我的审批') {
          this.routePush('router-auditList');
        } else if (title == '工具集') {
          this.routePush('router-toolSet', undefined, queryObj);
        }
        this.visible = false;
      } else
        switch (value) {
          case this.$SUBSYSTEM_FIRM: {
            this.$store.commit('SET_FIRMID', idObj.firmId);
            this.$store.dispatch('GenerateRoutes', {type: 'firm', id: idObj.firmId}).then(() => {
              let name = this.$route.name;
              if (
                !this.hasRoute(name, {
                  firmId: idObj.firmId,
                })
              ) {
                name = 'any';
              }
              this.routePush(name, {
                firmId: idObj.firmId,
              });
            });

            break;
          }
          case this.$SUBSYSTEM_PROJECT: {
            this.$store.commit('SET_GROUPID', idObj.groupId);
            this.$store
              .dispatch('GenerateRoutes', {
                type: 'project',
                id: idObj.groupId,
                checkHas: true,
              })
              .then((response) => {
                let name = this.$route.name;
                if (!response) {
                  this.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                  return;
                }
                if (this.frontMenu[4] != undefined) {
                  const curGroupId = this.frontMenu[4][0].groupId;
                  if (
                    !this.hasRoute(name, {
                      groupId: curGroupId,
                    }) ||
                    this.$route.meta.hidden == 1
                  ) {
                    name = 'any';
                  }
                  this.routePush(name, {groupId: curGroupId}, queryObj);
                } else {
                  this.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                }
              });

            break;
          }
          case this.$SUBSYSTEM_CORPORATION: {
            this.$store.commit('SET_GROUPID', idObj.groupId);
            this.$store
              .dispatch('GenerateRoutes', {
                type: 'corporation',
                id: idObj.groupId,
                checkHas: true,
              })
              .then((response) => {
                let name = this.$route.name;
                if (!response) {
                  this.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                  return;
                }
                if (this.frontMenu[10] != undefined) {
                  const curGroupId = this.frontMenu[10][0].groupId;
                  if (
                    !this.hasRoute(name, {
                      groupId: curGroupId,
                    }) ||
                    this.$route.meta.hidden == 1
                  ) {
                    name = 'any';
                  }
                  this.routePush(name, {groupId: curGroupId}, queryObj);
                } else {
                  this.$notification.warning({
                    message: '没有可访问的菜单!',
                  });
                }
              });

            break;
          }
          default: {
            console.warn('unknown SUBSYSTEM: ' + value);
          }
        }
      this.isDisplay = false;
    },
    logout() {
      this.$store.dispatch('LogOut').then(() => {
        window.location.href = this.$loginUrl;
        sessionStorage.clear();
      });
    },
    userCenter() {
      this.routePush('router-usercenter');
    },
    messageCenter() {
      this.routePush('router-messageCenter');
    },
    tabsClick(tab) {
      if (tab == '4') {
        this.routePush('router-project');
        this.isDisplay = false;
      } else if (tab == '3') {
        this.switchMenu(this.$SUBSYSTEM_SYSTEM, '系统管理');
      } else {
        this.activeKey = tab;
        this.isDisplay = true;
        this.loadMoreList = tab = '1'
          ? this.loadMoreProjectList
          : (tab = '5' ? this.loadMoreCorporationList : this.loadMoreFirmList);
      }
    },
    logoClick() {
      this.switchMenu(
        Number.parseInt(this.subSystem),
        this.$store.getters.toptooltitle,
        this.subSystem == 2 ? {firmId: this.firmId} : {groupId: this.groupId}
      );
    },
    getMessages() {
      getMessagesByParam(undefined, undefined, 0, 8, false).then((res) => {
        const data = res.data.content;
        const array = [];
        if (data.length > 0) {
          for (const item of data) {
            array.push(item);
          }
        }
        this.$store.commit('SET_MESSAGELIST', array);
      });
    },
    queryAuditResult() {
      queryAuditResult(1, '', '', 0, 10).then((response) => {
        let auditList = response.data.content;
        this.$store.commit('SET_AUDITLIST', response.data.content);
      });
    },
    openGuide() {
      this.guideStatus = true;
    },
    backToHomePage() {
      window.location.href = window.location.origin;
    },
    isReadGuide() {
      userDetail(this.userId).then((response) => {
        if (response.code == this.$SUCCESS_CODE) {
          this.readGuideStatus = response.data.isReadGuide;
          this.guideStatus = (this.readGuideStatus & 1) === 0;
        }
      });
    },
    hasReadGuide() {
      this.readGuideStatus = this.readGuideStatus | 1;
      updateIsReadGuide(this.readGuideStatus).then((res) => {
        if (res.code == this.$SUCCESS_CODE) {
          console.log(res);
        }
      });
      this.guideStatus = false;
    },
  },
});
</script>

<style lang="less" scoped>
.navbar {
  position: absolute;
  right: 5px;
  top: 0px;
  width: 100%;

  .navbar-left {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 10px;

    #guanbicaidan {
      width: 50px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navTitle {
      margin-left: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        vertical-align: middle;
        max-width: 280px;
        max-height: 40px;
      }

      .sub-system {
        cursor: pointer;

        .topToolTitleClass {
          width: 117px;
          height: 22px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          font-size: 16px;
          color: #ffffff;
          margin-right: 9px;
        }
      }
    }
  }

  .navbar-right {
    height: 50px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    .subSystemIcon {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 24px;
      }

      .subSystemIcon-badge {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .avatar-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .vertical-divider {
    height: 18px;
    margin: 0px 18px;
  }

  .vertical-linear-gradient-divider {
    vertical-align: middle;
    height: 40px;
    width: 1px;
    margin: 5px 8px;
    border: none;
  }

  .highlight-shadow:hover {
    filter: drop-shadow(0 0 5px #59d1f5);
  }
}

.dom-hover:hover {
  cursor: pointer;
}

/deep/ .ant-tabs-left-content {
  padding: 4px;
}

/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background: #fff !important;
}

/deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background: var(--info-bk-color);
}

/deep/ .tab-menu-trigger {
  font-size: 14px;
  height: 30px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  line-height: 31px;
  padding-left: 20px;
  padding-bottom: 1px;
  left: 7px;
  color: #ffffff;
}

/deep/ .tab-menu-trigger:hover {
  font-family: PingFangSC-Regular;
  font-weight: 400;
}

.ant-dropdown-menu-item:hover {
  background-color: white;
}

.ant-tabs-tab-active {
  color: #1755c4 !important;
  background-color: white !important;
}

.ant-tabs-tab {
  background-color: white;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  background-color: var(--info-bk-color);
}

.message-content {
  background-color: var(--info-bk-color);
  width: 372px;
  height: auto;
  font-size: 14px;
  font-family: 'PingFang SC';
  border-radius: 2px;
  padding: 10px;
  font-weight: 400;
  margin-bottom: 20px;
}

.message-createdAt {
  color: #b6bac0;
  font-weight: 400;
}

/deep/ .message-button {
  background: transparent !important;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  font-weight: 400;
  border-color: transparent !important;
  border: none !important;
}

.projectName {
  height: 22px;
  font-family: PingFangSC-Semibold;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.areaName {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #b6bac0;
  font-weight: 400;
  top: 100px;
  margin-left: 12px;
}

.siteName {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #232323;
  line-height: 16px;
  font-weight: 400;
}

.groupList {
  top: 8px;
  background: var(--body-background);
  border: 1px solid var(--border-color);
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  width: 150px;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.groupList::-webkit-scrollbar-thumb {
  background: #c6cbd3;
  border-radius: 3px;
}

/deep/ .ant-breadcrumb-separator {
  color: var(--border-color);
  font-weight: 10;
}

/deep/ .ant-layout > .ant-layout-sider-collapsed {
  background-color: red;
}

.subSystemIcon-m {
  width: 380px;
  height: 257px;
  border: 1px solid rgba(72, 231, 255, 0.4);
  background: rgba(0, 125, 170, 0.2);
  position: relative;

  .ant-dropdown-menu-vertical {
    padding: 0px;
  }

  :deep(.ant-tabs-content-holder) {
    width: 122px;
    box-sizing: content-box;
    border: none;
  }

  /deep/ .ant-tabs-content {
    box-sizing: content-box;
    border: none;
    // padding-top: 5px;
    // padding-bottom: 5px;
    height: 257px;
    overflow: hidden auto;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #48e7ff;
    background-clip: padding-box;
    min-height: 28px;
    border-radius: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar-button {
    width: 10px;
    height: 10px;
    display: none;
  }

  & .left {
    position: absolute;
    top: -11px;
    left: 217px;
    width: 1px;
    height: 12px;
    background: rgb(16 88 125);
    transform: rotate(40deg);
  }

  & .right {
    position: absolute;
    top: -11px;
    left: 223px;
    width: 1px;
    height: 12px;
    background: rgb(16 88 125);
    transform: rotate(140deg);
  }

  & .center {
    position: absolute;
    top: -1px;
    left: 214px;
    width: 11px;
    height: 1px;
    background: #033b62;
  }
}

.subSystemIcon-m::after {
  display: block;
  content: '◤';
  position: absolute;
  font-size: 18px;
  left: 32px;
  top: -13px;
  transform: rotate(45deg);
}

/*注意：此处::before的content要比::after的bottom高1px，就形成了箭头视觉效果了*/
.subSystemIcon-m::before {
  display: block;
  content: '◤';
  position: absolute;
  font-size: 19px;
  left: 32px;
  top: -14px;
  transform: rotate(45deg);
  color: var(--border-color);
}

.flootMenus {
  border-top: 0px solid var(--border-color);
  position: absolute;
  border-right: 1px solid var(--border-color);
  /* left: 16px; */
  cursor: pointer;
  width: 121px;
  vertical-align: bottom;
  padding-top: 101px;
  padding-left: 14px;

  .flootMenu {
    height: 36px;
    position: absolute;
  }

  .toRightIcon {
    transform: rotate(-90deg);
    font-size: 10px;
    margin-bottom: 10px;
  }
}

.tab-trigger {
  /deep/ .ant-tabs-nav {
    width: 122px;
  }

  /deep/ .ant-tabs-nav-wrap {
    overflow: visible;
  }

  /deep/ .ant-tabs-tab {
    box-sizing: content-box;
    width: 120px;
    height: 40px;
    border-right: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    border-left: 0px;
    border-top: 0px;
    padding: 0px;
    z-index: 10;
  }

  /deep/ .ant-tabs-tab-active {
    width: 121px;
    background: none;
    border-bottom: 1px solid var(--border-color);
    border-right: 0px;
  }

  /deep/ .ant-menu-item:not(.tab-menu-trigger-active) {
    background: none;
  }

  /deep/ .ant-tabs-tab-btn {
    font-size: 14px;
    font-family: PingFangSC-Semibold;
    padding: 0px 16px;
  }

  /deep/ .ant-tabs-nav-list {
    overflow: hidden;
  }

  /deep/ .ant-tabs-nav-list::before {
    backdrop-filter: blur(10px) !important;
  }

  /deep/ .ant-tabs-tabpane {
    padding-left: 0 !important;
  }

  /deep/ .ant-dropdown-menu-item {
    padding: 9px 16px;
  }

  /deep/ .ant-menu-item-selected {
    border: none;
  }
}

/deep/ .ant-spin-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/deep/ .ant-badge-dot {
  margin-top: 6px;
  box-shadow: none;
}

.ant-notification {
  z-index: 1051 !important;
}

.theme-tech {
  .navbar {
    .vertical-linear-gradient-divider {
      background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(72, 231, 255, 1) 50%, rgba(0, 0, 0, 0) 100%);
    }
    .highlight-shadow:hover {
      filter: drop-shadow(0 0 5px #59d1f5);
    }
  }
  .ant-tabs-card /deep/.ant-spin-container::before {
    background: rgba(5, 46, 91, 0.4);
  }
  .ant-tabs-card /deep/ .tab-menu-trigger-active,
  .ant-tabs-card /deep/ .tab-menu-trigger:hover {
    color: #48e7ff;
    background: rgba(0, 125, 170, 0.4);
  }
  .flootMenus {
    color: #48e7ff;
    background: rgba(0, 125, 170, 0.4);
  }
  /deep/.ant-tabs-tab {
    background: rgba(0, 125, 170, 0.4);
    color: #ffffff;
  }
  .subSystemIcon-m::after {
    color: rgba(5, 46, 91, 0.4);
  }
  .message-footer {
    background: #033b68;
  }
}
.theme-light {
  .navbar {
    .vertical-divider {
      height: 18px;
      margin: 0px 18px;
      border-inline-start: 1px solid #ffffff80;
    }

    .vertical-linear-gradient-divider {
      background: linear-gradient(180deg, #ffffff00 0%, #ffffff32 50%, #ffffff00 100%);
    }
    .highlight-shadow:hover {
      filter: drop-shadow(0 0 5px #59d1f5);
    }
  }
  .ant-tabs-card :deep(.tab-menu-trigger-active),
  .ant-tabs-card /deep/ .tab-menu-trigger:hover {
    color: #4262ff;
    background: #f3f5ff;
  }
  .flootMenus {
    background: #f6f6f8;
    color: #4262ff;
  }

  /deep/.ant-tabs-tab {
    background: #f6f6f8;
  }
  .subSystemIcon-m::after {
    color: #f6f6f8;
  }
  .subSystemIcon-m {
    border: none;
    background: transparent;
  }
  .message-footer {
    background: var(--info-bk-color);
  }
}
</style>
@/api/corporation
