import request from '@/utils/request';
export function getSystemFunctions() {
  return request({
    url: '/iot/system/roles/functions',
    method: 'get',
  });
}

export function getSystemRoleById(roleId) {
  return request({
    url: '/iot/system/roles/' + roleId,
    method: 'get',
  });
}

export function addSystemRole(data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/system/roles',
    method: 'post',
    data: data,
  });
}

export function changeSystemRole(roleId, data) {
  // 新增厂或修改项目角色
  return request({
    url: '/iot/system/roles/' + roleId,
    method: 'put',
    data: data,
  });
}
export function removeSystemRole(roleId) {
  // 删除项目角色
  return request({
    url: '/iot/system/roles/' + roleId,
    method: 'delete',
  });
}

export function existSystemRole(name, id) {
  // 删除项目角色
  return request({
    url: '/iot/system/roles/isExist',
    method: 'get',
    params: {
      name,
      id,
    },
  });
}

export function getSystemRoleListByParameter(data) {
  // 条件查询项目角色
  return request({
    url: '/iot/system/roles',
    method: 'get',
    params: data,
  });
}

export function getAllSystemRoleListByParameter(data) {
  // 条件查询项目角色
  return request({
    url: '/iot/system/roles/all',
    method: 'get',
    params: data,
  });
}

export function getSystemMemberListByParam(data) {
  // 条件查询厂商用户
  return request({
    url: '/iot/systemMembers',
    method: 'get',
    params: data,
  });
}

export function addSystemMember(data) {
  // 新增厂或修改厂商用户
  return request({
    url: '/iot/systemMembers',
    method: 'post',
    data: data,
  });
}

export function changeSystemMember(userId, data) {
  // 新增厂或修改厂商用户
  return request({
    url: '/iot/systemMembers/' + userId,
    method: 'put',
    data: data,
  });
}

export function removeSystemMember(userId) {
  // 删除厂商用户
  return request({
    url: '/iot/systemMembers/' + userId,
    method: 'delete',
  });
}

export function detail(id) {
  return request({
    url: '/iot/systemMembers/' + id,
    method: 'get',
  });
}

export function checkLeftResource() {
  return request({
    url: '/iot/system/checkLeftResource',
    method: 'get',
  });
}
