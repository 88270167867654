import {createRouter, createWebHistory} from 'vue-router';

/* Layout */
import Layout from '../views/layout/layout';

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if false, the item will hidden in breadcrumb(default is true)
  }
 **/

export const staticRouterMap = [
  {
    path: '/',
    name: '主页',
    component: () => import('@/views/open/open'),
    meta: {
      notrequireAuth: true,
    },
    redirect: '/index',
    hidden: true,
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/open/index'),
        meta: {
          notrequireAuth: true,
        },
        hidden: true,
      },
      {
        path: 'index.html',
        redirect: '/index',
      },
      {
        path: 'serialKit',
        name: 'serialKit',
        component: () => import('@/views/serialKit/index'),
        meta: {
          notrequireAuth: true,
        },
        theme: 'tech',
        hidden: true,
      },
    ],
  },
  {
    path: '/cloudConfiguration',
    name: 'cloudConfiguration',
    component: () => import('@/views/open/cloudConfiguration'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/login/changePassword'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/401'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/wscindex',
    name: 'wscindex',
    component: () => import('@/views/wscopen/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/yqindex',
    name: 'yqindex',
    component: () => import('@/views/yqopen/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/nwzwindex',
    name: 'nwzwindex',
    component: () => import('@/views/nwzwopen/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/viewLastData',
    name: 'viewLastData',
    component: () => import('@/views/gateway/h5/lastDataDetail'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
    theme: 'tech',
  },
  {
    path: '/videotutorial',
    name: 'videotutorial',
    component: () => import('@/views/videoTutorial/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/userManual',
    name: 'userManual',
    component: () => import('@/views/documents/userManual/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/interfaceDocument/:subPage?',
    name: 'interfaceDocument',
    component: () => import('@/views/documents/interfaceDocument/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/firmManual',
    name: 'firmManual',
    component: () => import('@/views/documents/firmManual/index'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/excelConvert',
    name: 'excelConvert',
    component: () => import('@/views/excelConvert/excelConvert'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
  {
    path: '/warrantyTestPage',
    name: 'warrantyTestPage',
    component: () => import('@/views/warrantyManage/warrantyTestPage'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
    theme: 'tech',
  },
  // {
  //   path: '/audit',
  //   name: '审核',
  //   component: () => import('@/views/audit/index'),
  //   meta: {
  //     notrequireAuth: true,
  //   },
  // },
  {
    path: '/arrearageProjectPage',
    name: 'arrearageProjectPage',
    component: () => import('@/views/layout/arrearageLayout.vue'),
    meta: {
      notrequireAuth: true,
    },
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({
    top: 0,
  }),
  routes: staticRouterMap,
});

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    routes: staticRouterMap,
  });
  router.matcher = newRouter.matcher; // 新路由实例matcer，赋值给旧路由实例的matcher，（相当于replaceRouter）
}

export function addRoutes(routes) {
  for (const route of routes) {
    router.addRoute(route);
  }
}

export default router;
