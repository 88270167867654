import axios from 'axios';
import {message, notification} from 'ant-design-vue';
import {exception} from 'vue-gtag';

// 创建axios实例
const service = axios.create({
  // baseURL: isPro ? 'process.prod.BASE_API' : '',// api 的 base_url
  timeout: 50_000, // 请求超时时间
});

// response 拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    let data = response.data;
    if (/^{.*}{.*}$/.test(data)) {
      notification.error({
        message: '请求超限!',
      });
      data = JSON.parse(data.split('}{')[0] + '}');
    }
    if (data.code !== 20_000) {
      let content = '您输入的账号或密码不正确，请重试！';
      if (response.data.message == '未绑定账户！') {
        content = '未绑定账户！';
      } else if (response.data.message.startsWith('Invalid refresh token')) {
        content = '您的登录状态已过期,请重新登录!';
      } else if (response.data.message == '验证码错误！') {
        content = '验证码错误！';
      }
      notification.error({
        message: content,
      });

      return Promise.reject('error');
    } else {
      return data;
    }
  },
  (error) => {
    console.log('err' + error); // for debug
    exception({
      description: error,
      fatal: false,
    });
    if (error.response.status === 504 || error.response.status === 502) {
      notification.error({
        message: '系统维护中,请稍后再试!',
      });
    } else {
      notification.error({
        message: '系统异常,请联系管理员!',
      });
    }
    return Promise.reject(error);
  }
);

export default service;
