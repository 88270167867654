import {theme} from 'ant-design-vue';

export const lightTheme = {
  chartTheme: 'light',
  name: 'light',
  token: {
    borderRadius: 4,
    borderRadiusXS: 2,
    wireframe: false,
    colorPrimary: '#4262ff',
    colorSuccess: '#46c24e',
    colorWarning: '#f5b836',
    colorError: '#ff3f3f',
    colorInfo: '#4262ff',
    colorBgBase: '#ffffff',
    colorTextBase: '#000000',
    colorBorder: '#E1E3E8',
    colorBorderSecondary: '#E1E3E8',
    colorBgLayout: '#f8f8fa',
  },
};

export const darkTheme = {
  chartTheme: 'dark',
  name: 'dark',
  algorithm: theme.darkAlgorithm,
};

export const techTheme = {
  chartTheme: 'dark',
  name: 'tech',
  algorithm: theme.darkAlgorithm,
  // input 背景色 #042b50
  token: {
    colorPrimary: '#2a8eb6',
    colorPrimaryBg: '#113b63',
    colorText: 'rgba(255,255,255,0.85)',
    colorBgLayout: '#06103c',
    colorBgBase: '#020616',
    colorBgContainer: '#042b50',
    colorBgElevated: '#03234a', // 'rgba(2, 96, 170, 0.3)',
    colorFillQuaternary: 'rgba(0, 81, 110, 0.4)',
    colorBorder: 'rgba(72, 231, 255, 0.4)',
    colorBorderSecondary: 'rgba(72, 231, 255, 0.2)',
    colorInfoText: '#41D4EE',
    colorInfoBg: '#0a244e',
    borderRadius: 0,
    wireframe: true,
  },
  components: {
    Table: {},
  },
};

export const gangTheme = {
  chartTheme: 'dark',
  name: 'gang',
  algorithm: theme.darkAlgorithm,
  // input 背景色 #042b50
  token: {
    colorPrimary: '#2a8eb6',
    colorPrimaryBg: '#113b63',
    colorText: 'rgba(255,255,255,0.85)',
    colorBgLayout: '#06103c',
    colorBgBase: '#020616',
    colorBgContainer: '#042b50',
    colorBgElevated: '#03234a', // 'rgba(2, 96, 170, 0.3)',
    colorFillQuaternary: 'rgba(0, 81, 110, 0.4)',
    colorBorder: 'rgba(72, 231, 255, 0.4)',
    colorBorderSecondary: 'rgba(72, 231, 255, 0.2)',
    colorInfoText: '#41D4EE',
    colorInfoBg: '#0a244e',
    borderRadius: 0,
    wireframe: true,
  },
  components: {
    Table: {},
  },
};

export function getTheme(themeName) {
  switch (themeName) {
    case 'light':
      return lightTheme;
      break;
    case 'dark':
      return darkTheme;
      break;
    case 'tech':
      return techTheme;
      break;
    case 'gang':
      return gangTheme;
      break;

    default:
      return techTheme;
      break;
  }
}
