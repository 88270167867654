import request from '@/utils/request';

export function getGateways(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getLocateGateways',
    method: 'get',
  });
}

export function insertEntryRecord(groupId, id, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id + '/insertEntryRecord',
    method: 'post',
    data: data,
  });
}

export function updateEntryRecord(groupId, id, data, oldTime) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id + '/updateEntryRecord',
    method: 'put',
    params: {
      collectTime: oldTime,
    },
    data: data,
  });
}

export function deleteEntryRecord(groupId, id, collectTime) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id + '/deleteEntryRecord',
    method: 'delete',
    params: {
      collectTime,
    },
  });
}

export function batchInsertEntryRecord(groupId, id, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id + '/batchInsertEntryRecord',
    method: 'post',
    data: data,
  });
}

export function add(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways',
    method: 'post',
    data: data,
  });
}

export function update(groupId, id, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id,
    method: 'put',
    data: data,
  });
}

export function deleteProjectGatewayById(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id,
    method: 'delete',
  });
}

export function getProjectGatewayListByPage(
  groupId,
  {currentPage, pageSize, serialNumber, gatewayName, onlineStatues, exceptionStatuses, rootGroupId},
  includeShare
) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways',
    method: 'get',
    params: {
      serialNumber: serialNumber,
      gatewayName: gatewayName,
      onlineStatues: onlineStatues ? onlineStatues.toString() : onlineStatues,
      exceptionStatuses: exceptionStatuses ? exceptionStatuses.toString() : exceptionStatuses,
      rootGroupId: rootGroupId,
      page: currentPage,
      size: pageSize,
      includeShare: includeShare,
    },
  });
}

export function getProjectGatewayAndGatewayIndicatorsList(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/gatewayAndIndicators',
    method: 'get',
  });
}

export function detail(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + id,
    method: 'get',
  });
}

export function gatewayIndicatorDetail(groupId, id) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getGatewayIndicatorByGatewayId/' + id,
    method: 'get',
  });
}

export function isGatewaySerialNumberAvailable(groupId, serialNumber, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/isGatewaySerialNumberAvailable',
    method: 'get',
    params: {
      serialNumber: serialNumber,
      gatewayId: gatewayId,
    },
  });
}

export function isFirmGatewayById(groupId, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/isFirmGatewayById',
    method: 'get',
    params: {
      gatewayId: gatewayId,
    },
  });
}

export function bindGateway(groupId, id, gatewayId, gatewayTypeId, serialNumber) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${id}/bindGateway`,
    method: 'put',
    params: {
      gatewayId: gatewayId,
      gatewayTypeId: gatewayTypeId,
      serialNumber: serialNumber,
    },
  });
}

export function canBindGateway(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${id}/canBindGateway`,
    method: 'get',
  });
}

export function getThingsModelIndicator(groupId, gatewayId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${gatewayId}/thingsModelIndicator`,
    method: 'get',
  });
}

export function getGatewayDetailBySerialNumber(groupId, serialNumber) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getGatewayDetailBySerialNumber',
    method: 'get',
    params: {
      serialNumber: serialNumber,
    },
  });
}

export function isAliasExist(groupId, alias, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/isGatewayAliasExist',
    method: 'get',
    params: {alias: alias, gatewayId: gatewayId},
  });
}

export function addProjectGatewayIndicator(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + data.id + '/gatewayIndicator',
    method: 'put',
    data: data,
  });
}

export function createEntryPersonAndCreatedAt(groupId, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/createEntryPersonAndCreatedAt',
    method: 'put',
  });
}

export function getGatewaysIndicatorsAndEquipments(groupId, gatewayIndicatorIds) {
  return request({
    url: `/iot/groups/${groupId}/gateways/getGatewaysIndicatorsAndEquipments`,
    method: 'get',
    params: {gatewayIndicatorIds},
  });
}

export function getGatewayFirmByGatewayId(groupId, gatewayId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/getGatewayFirmByGatewayId`,
    method: 'get',
    params: {
      gatewayId: gatewayId,
    },
  });
}

export function getGatewayIndicatorsByGroupId(groupId, groupIds) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/findAllByGroupIdIn',
    method: 'get',
    params: {
      groupIds: groupIds,
    },
  });
}

export function importProjectGateway(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/importProjectGateway',
    method: 'post',
    data: data,
  });
}

export function saveImportGatewayIndicators(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/saveImportGatewayIndicators',
    method: 'post',
    data: data,
  });
}

export function getModbusConfigList(groupId, gatewayId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/getModbusConfigList',
    method: 'get',
  });
}

export function getSlaveConfigByModbusConfigId(groupId, gatewayId, modbusConfigId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/modbusConfig/' + modbusConfigId,
    method: 'get',
  });
}

export function saveModbusConfigs(groupId, gatewayId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/saveModbusConfigs',
    method: 'put',
    data: data,
  });
}

export function isModbusConfigExist(groupId, gatewayId, serialPortNumber, thisId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/isModbusConfigExist',
    method: 'get',
    params: {
      serialPortNumber: serialPortNumber,
      thisId: thisId,
    },
  });
}

export function deleteModbusConfig(groupId, gatewayId, modbusConfigId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/' + gatewayId + '/deleteModbusConfig/' + modbusConfigId,
    method: 'delete',
  });
}

export function reloadData(groupId, gatewayId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/reloadData/send`,
    method: 'get',
    params: {gatewayId},
  });
}

export function getAllGatewayTypes(groupId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/gatewayTypes`,
    method: 'get',
  });
}

export function iotCardUsed(groupId, id, iccid) {
  return request({
    url: `/iot/groups/${groupId}/gateways/iotCard/exists`,
    method: 'get',
    params: {id, iccid},
  });
}

export function isShareOwnerGatewayDelete(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/gateways/isShareOwnerGatewayDelete/` + id,
    method: 'get',
  });
}

export function isOwnerShareGatewayExist(groupId, shareOwnerId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/isOwnerShareGatewayExist/` + shareOwnerId,
    method: 'get',
  });
}

export function getShareProjectList(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/gateways/getShareProjectList/` + id,
    method: 'get',
  });
}

export function isShareOwnerGateway(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/gateways/isShareOwnerGateway/` + id,
    method: 'get',
  });
}

export function getNoBindGateways(groupId, otherGroupId) {
  return request({
    url: `/iot/groups/${groupId}/gateways/noBindGateway`,
    method: 'get',
    params: {otherGroupId},
  });
}

export function transfer(groupId, id, data) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${id}/transfer`,
    method: 'put',
    data: data,
  });
}

export function getTransmitters(groupId, id) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${id}/transmitters`,
    method: 'get',
  });
}

export function updateTransmitters(groupId, id, data) {
  return request({
    url: `/iot/groups/${groupId}/gateways/${id}/transmitters`,
    method: 'put',
    data,
  });
}

export function getHistoricalRoute(groupId, ids, start, end) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getHistoricalRoute',
    method: 'get',
    params: {
      start: start,
      end: end,
      gatewayIds: ids.join(','),
    },
  });
}
