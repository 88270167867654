import router, {addRoutes} from './router';
import {resetRouter, staticRouterMap} from './router';
import store from './store';
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import {base64decode, base64encode} from '@/utils/base64Util';
import {Modal, notification} from 'ant-design-vue'; // 验权
import {lightTheme, techTheme} from './themes/themes';

function toPushByPath(to, from, next) {
  if (/\/projects\/(\d+)\//.test(to.path)) {
    const toPath = to.fullPath.replace(/\/projects\/(\d+)\//, '/projects/');
    window.location.href = window.location.origin + toPath;
    return;
  }
  let option;
  if (from.path == '/' || option == undefined) {
    option = {};
    if (/\/corporations\/groups\/(\d+)\//.test(to.path)) {
      option = {type: 'corporation', id: to.path.match(/\/groups\/(\d+)\//)[1]};
    } else if (/\/projects\/groups\/(\d+)\//.test(to.path)) {
      option = {type: 'project', id: to.path.match(/\/groups\/(\d+)\//)[1]};
    } else if (/\/gatewayfirms\/(\d+)\//.test(to.path)) {
      option = {type: 'firm', id: to.path.match(/\/gatewayfirms\/(\d+)\//)[1]};
    }
  }
  if (to.path.startsWith('/m/')) {
    option.topLayout = 'mLayout';
    option.firstUri = '/m';
  }
  store.dispatch('GenerateRoutes', option).then(() => {
    next({
      ...to,
      replace: true,
    });
  });
}

const noThemePaths = new Map();
for (const route of staticRouterMap) {
  if (route.children) {
    for (const child of route.children) {
      noThemePaths.set(route.path + child.path, child.theme);
    }
  }
  noThemePaths.set(route.path, route.theme);
}

router.beforeEach((to, from, next) => {
  NProgress.start();
  // 是否需要验证权限
  if (to.redirectedFrom && to.redirectedFrom.fullPath && to.fullPath && to.name === undefined) {
    store.dispatch('Go404', to.path);
  }
  if (to.meta.notrequireAuth) {
    next();
  } else {
    if (to.query.accessToken) {
      // 通过url携带token的方式访问
      store.dispatch('CheckToken', to.query.accessToken).then((active) => {
        if (active) {
          store.commit('SET_TOKEN', to.query.accessToken);
          store.commit('SET_REFRESHTOKEN', to.query.refreshToken);
          to.query.accessToken = undefined;
          toPushByPath(to, from, next);
        } else {
          to.query.accessToken = undefined;
          // 否则全部重定向到登录页
          store.dispatch('Go401', to.path);
        }
      });
    } else if (to.query.clientId && to.query.clientSecret) {
      store
        .dispatch('Login', {
          type: 'client_credentials',
          clientId: to.query.clientId,
          clientSecret: to.query.clientSecret,
        })
        .then((response) => {
          store.commit('SET_TOKEN', response.access_token);
          store.commit('SET_REFRESHTOKEN', response.refresh_token);
          to.query.clientId = undefined;
          to.query.clientSecret = undefined;
          toPushByPath(to, from, next);
        })
        .catch((error) => {
          to.query.clientId = undefined;
          to.query.clientSecret = undefined;
          store.dispatch('Go401', to.path);
        });
    } else if (store.getters.token) {
      if (store.getters.isLogin) {
        if (to.matched.length > 0) {
          next();
          NProgress.done();
        } else {
          toPushByPath(to, from, next);
        }
      } else {
        resetRouter();
        store.dispatch('RefreshLogin').then(() => {
          toPushByPath(to, from, next);
        });
      }
    } else {
      // 否则全部重定向到登录页
      store.dispatch('GoIndex', to.path);
    }
  }
});
router.afterEach(async (to) => {
  if (
    store.getters.fromCorporationBigScreen == undefined ||
    to.meta.groupId != store.getters.fromCorporationBigScreen.sonProject.id ||
    localStorage.getItem('isRefreshed') === 'true'
  ) {
    store.commit('SET_FROM_CORPORATION_BIG_SCREEN');
  }
  window.addEventListener('beforeunload', function () {
    localStorage.setItem('isRefreshed', 'true');
  });
  window.onload = function () {
    if (localStorage.getItem('isRefreshed') === 'true') {
      localStorage.removeItem('isRefreshed'); // 刷新后清除标志位
    }
  };

  store.commit('SET_SUBSYSTEM', to.meta.subSystem);
  store.commit('SET_FIRMID', to.params.firmId);
  store.commit('SET_GROUPID', to.params.groupId);
  // 主题设置
  // 静态路由都不进行主题设置
  if (noThemePaths.has(to.path)) {
    if (noThemePaths.get(to.path) != undefined) {
      store.commit('SET_CUR_THEME', noThemePaths.get(to.path));
    } else {
      store.commit('SET_CUR_THEME', 'light');
    }
  } else {
    store.commit('SET_CUR_THEME', store.getters.theme);
  }

  switch (to.meta.subSystem) {
    case 0:
      store.commit('SET_TOPTOOLTITLE', to.meta.title);
      store.commit('SET_PROJECT_GROUP_ID');
      break;
    case 1:
      store.commit('SET_TOPTOOLTITLE', '我的项目');
      store.commit('SET_PROJECT_GROUP_ID');
      break;
    case 3:
      store.commit('SET_TOPTOOLTITLE', '系统管理');
      store.commit('SET_PROJECT_GROUP_ID');
      break;
    case 4:
      if (store.getters.payStatus == 'C') {
        Modal.info({
          title: () => {
            return `欠费提醒`;
          },
          content: () => {
            return `此项目已经欠费，请您公司商务人员及时续费，以免影响正常使用！`;
          },
        });
      }
      if (store.getters.isExceedingGatewayIndicatorLimit) {
        Modal.info({
          title: () => {
            return `监测项资源不足提醒`;
          },
          content: () => {
            return `尊敬的用户！项目监测项接入上限为${store.getters.gatewayIndicatorMaxNum}个，请将项目的监测项数量修改至
            ${store.getters.gatewayIndicatorMaxNum}个以内或购买监测项资源`;
          },
        });
      }
      break;
    default:
      break;
  }

  // 记录路由到本地
  if (!noThemePaths.has(to.path)) {
    store.commit('SET_LAST_ROUTE', {
      name: to.name.replace(/-[fgps]\d*$/, ''),
      params: to.params,
      subSystem: to.meta.subSystem,
    });
  }

  NProgress.done(); // 结束Progress
});
