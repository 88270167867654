// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/static/pc/img/navbar_right.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/static/pc/img/navbar_right_light.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ant-breadcrumb[data-v-58b47685] {\n  margin: 0px 24px;\n  padding: 15px 0px;\n  border-bottom: 1px solid var(--border-color);\n}\n.layout-header[data-v-58b47685] {\n  -webkit-box-sizing: content-box;\n          box-sizing: content-box;\n  height: 51px;\n  padding-left: 0px;\n  padding-right: 24px;\n  position: relative;\n  line-height: 32px;\n  -ms-flex-preferred-size: 50px;\n      flex-basis: 50px;\n  z-index: 996;\n  border-bottom: 1px solid transparent;\n}\n.layout-header[data-v-58b47685]::after {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n  content: '';\n  position: absolute;\n  width: 100%;\n  height: 51px;\n  z-index: -100;\n  -o-border-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 616px, rgba(0, 240, 255, 0.34) 617px, #48e7ff) 10 10;\n     border-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 616px, rgba(0, 240, 255, 0.34) 617px, #48e7ff) 10 10;\n  border-bottom: 1px solid;\n}\n.layout-header .img[data-v-58b47685] {\n  z-index: -100;\n  position: relative;\n}\n.theme-tech .layout-header[data-v-58b47685] {\n  background: linear-gradient(to right, rgba(72, 231, 255, 0.5), rgba(0, 88, 119, 0.9) 512px);\n  -webkit-box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);\n          box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);\n}\n.theme-tech .layout-header[data-v-58b47685]::after {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat right;\n}\n.theme-light .layout-header[data-v-58b47685] {\n  background: linear-gradient(to right, #428eff, #4262ff 512px);\n  -webkit-box-shadow: 0 6px 12px 0 #0d184d33;\n          box-shadow: 0 6px 12px 0 #0d184d33;\n}\n.theme-light .layout-header[data-v-58b47685]::after {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat right;\n}\n[data-v-58b47685] .breadcrumb {\n  cursor: pointer;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
