<template>
  <a-modal
    wrap-class-name="full-modal"
    :open="visible"
    :confirm-loading="false"
    :mask-closable="false"
    :footer="null"
    :style="{
      '--bigFontSizeWithBody': bigFontSizeWithBody,
      '--smallFontSizeWithBody': smallFontSizeWithBody,
      '--bigFontSizeWithPicture': bigFontSizeWithPicture,
      '--smallFontSizeWithPicture': smallFontSizeWithPicture,
      '--arrowSizeWithBody': arrowSizeWithBody,
      '--brokenLineHeightWithBody': brokenLineHeightWithBody,
      '--brokenLineWidthWithBody': brokenLineWidthWithBody,
      '--buttonWidthWithBody': buttonWidthWithBody,
      '--buttonHeightWithBody': buttonHeightWithBody,
      '--buttonPaddingLeftWithBody': buttonPaddingLeftWithBody,
    }"
    width="100%"
    height="100%"
    ok-text="下一步"
    :ok-button-props="{disabled: true}"
    cancel-text="取消"
    @ok="hideModal"
    @cancel="visible = false"
  >
    <div class="guide">
      <div v-if="guideStatus == 0" class="guide-preview">
        <a-row justify="center">
          <h1>使 用 流 程</h1>
        </a-row>
        <a-row align="center" class="first-steps">
          <img
            src="/static/guide/arrows/箭头1.png"
            style="position: absolute; left: 10.5%; top: 20%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头1.png"
            style="position: absolute; left: 27.5%; top: 20%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头1.png"
            style="position: absolute; left: 44%; top: 20%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头1.png"
            style="position: absolute; left: 60.5%; top: 20%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头1.png"
            style="position: absolute; left: 77.5%; top: 20%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头3.png"
            style="
              position: absolute;
              left: 86%;
              top: 20%;
              height: var(--brokenLineHeightWithBody);
              width: var(--brokenLineWidthWithBody);
            "
          />
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 0" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">01</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[0][0]"
                    @click="
                      () => {
                        selectedStep = 0;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p
                  class="big-font-with-body"
                  style="
                     {
                      font-family: PingFangSC-SNaNpxibold;
                    }
                  "
                >
                  {{ stepsOne[0][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[0][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(0)"> 详细操作 </a-button>
            </div>
            <div v-else class="single-step-not-selected">
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 0;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">01</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[0][3]"
                    @click="
                      () => {
                        selectedStep = 0;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[0][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[0][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 1" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">02</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[1][0]"
                    @click="
                      () => {
                        selectedStep = 1;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[1][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[1][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(1)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 1;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">02</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[1][3]"
                    @click="
                      () => {
                        selectedStep = 1;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[1][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[1][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 2" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">03</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[2][0]"
                    @click="
                      () => {
                        selectedStep = 2;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[2][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[2][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(2)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 2;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">03</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[2][3]"
                    @click="
                      () => {
                        selectedStep = 2;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[2][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[2][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 3" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">04</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[3][0]"
                    @click="
                      () => {
                        selectedStep = 3;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[3][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[3][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(3)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 3;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">04</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[3][3]"
                    @click="
                      () => {
                        selectedStep = 3;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[3][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[3][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 4" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">05</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[4][0]"
                    @click="
                      () => {
                        selectedStep = 4;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[4][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[4][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(4)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 4;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">05</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[4][3]"
                    @click="
                      () => {
                        selectedStep = 4;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[4][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[4][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 5" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">06</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[5][0]"
                    @click="
                      () => {
                        selectedStep = 5;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[5][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[5][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(5)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 5;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">06</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[5][3]"
                    @click="
                      () => {
                        selectedStep = 5;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[5][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[5][2] }}</p>
              </div>
            </div>
          </div>
        </a-row>
        <a-row align="center" class="second-steps">
          <img
            src="/static/guide/arrows/箭头2.png"
            style="position: absolute; left: 12%; top: 22%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头2.png"
            style="position: absolute; left: 32%; top: 22%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头2.png"
            style="position: absolute; left: 52%; top: 22%; width: var(--arrowSizeWithBody)"
          />
          <img
            src="/static/guide/arrows/箭头2.png"
            style="position: absolute; left: 72%; top: 22%; width: var(--arrowSizeWithBody)"
          />
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 10" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">11</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[10][0]"
                    @click="
                      () => {
                        selectedStep = 10;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[10][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[10][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(10)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 10;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">11</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[10][3]"
                    @click="
                      () => {
                        selectedStep = 10;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[10][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[10][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 9" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">10</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[9][0]"
                    @click="
                      () => {
                        selectedStep = 9;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[9][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[9][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(9)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 9;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">10</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[9][3]"
                    @click="
                      () => {
                        selectedStep = 9;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[9][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[9][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 8" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">09</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[8][0]"
                    @click="
                      () => {
                        selectedStep = 8;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[8][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[8][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(8)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 8;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">09</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[8][3]"
                    @click="
                      () => {
                        selectedStep = 8;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[8][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[8][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 7" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">08</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[7][0]"
                    @click="
                      () => {
                        selectedStep = 7;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[7][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[7][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(7)"> 详细操作 </a-button>
            </div>
            <div v-else>
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 7;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">08</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[7][3]"
                    @click="
                      () => {
                        selectedStep = 7;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[7][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[7][2] }}</p>
              </div>
            </div>
          </div>
          <div vertical-align:top class="single-step">
            <div v-if="selectedStep === 6" class="single-step-selected">
              <div class="circleSelected">
                <div class="innerIndex">
                  <p class="big-font-with-body">07</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[6][0]"
                    @click="
                      () => {
                        selectedStep = 6;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">
                  {{ stepsOne[6][1] }}
                </p>
              </div>
              <div align="center">
                <p class="small-font-with-body">
                  {{ stepsOne[6][2] }}
                </p>
              </div>
              <a-button type="primary" shape="round" :size="'small'" @click="turnToContent(6)"> 详细操作 </a-button>
            </div>
            <div v-else class="single-step-not-selected">
              <div
                class="circle"
                @click="
                  () => {
                    selectedStep = 6;
                  }
                "
              >
                <div class="innerIndex">
                  <p class="big-font-with-body">07</p>
                </div>
                <div class="innerImage">
                  <img
                    :src="stepsOne[6][3]"
                    @click="
                      () => {
                        selectedStep = 6;
                      }
                    "
                  />
                </div>
              </div>
              <div align="center">
                <p class="big-font-with-body">{{ stepsOne[6][1] }}</p>
              </div>
              <div align="center">
                <p class="small-font-with-body">{{ stepsOne[6][2] }}</p>
              </div>
            </div>
          </div>
        </a-row>
        <a-row class="button-row">
          <a-col>
            <a-button class="button-up" @click="prev">上一步</a-button>
          </a-col>
          <a-col>
            <a-space>
              <a-button class="button-down" type="primary" @click="turnToContent(0)">下一步</a-button>
            </a-space>
          </a-col>
        </a-row>
        <a-row justify="center" class="hyper-link-row">
          详细内容请查看<a href="/docs/userManual/" target="_self">《物Link平台用户手册》</a>
        </a-row>
      </div>
    </div>
    <div v-if="guideStatus == 1" class="guide-content">
      <a-steps :current="current" :label-placement="'vertical'">
        <a-step v-for="(item, index) in steps" :key="item.title" :title="item.title" @click="toThisStep(index)">
          <template #icon>
            <div v-if="index < 9" class="index-in-icon">
              <p class="small-font-with-body">0{{ index + 1 }}</p>
            </div>
            <div v-else class="index-in-icon">
              <p class="small-font-with-body">{{ index + 1 }}</p>
            </div>
            <img v-if="current >= index" :src="item.icon[0]" />
            <img v-else :src="item.icon[1]" />
          </template>
        </a-step>
      </a-steps>
      <div class="steps-content" justify="center">
        <div class="content-text">
          <p>
            {{ steps[current].text }}
          </p>
        </div>
        <div class="content-picture" style="color: white">
          <div v-if="current === 0 && guideStatus === 1" style="position: absolute; top: 14%; left: 73%">
            <p class="small-font-with-picture">1.点击这里新建项目</p>
          </div>
          <div v-if="current === 0 && guideStatus === 1" style="position: absolute; top: 47%; left: 13%; width: 150px">
            <p class="small-font-with-picture">2.输入项目名称、选择项目类型，拾取项目坐标</p>
          </div>
          <div v-if="current === 0 && guideStatus === 1" style="position: absolute; top: 41%; left: 79.5%; width: 150px">
            <p class="small-font-with-picture">3.点击这里进入项目</p>
          </div>
          <div v-if="current === 1 && guideStatus === 1" style="position: absolute; top: 10.8%; left: 64%">
            <p class="small-font-with-picture">1.点击这里新建物联设备</p>
          </div>
          <div v-if="current === 1 && guideStatus === 1" style="position: absolute; top: 31%; left: 17%; width: 150px">
            <p class="small-font-with-picture">2.新增物联设备</p>
          </div>
          <div v-if="current === 1 && guideStatus === 1" style="position: absolute; top: 30%; left: 70%; width: 130px">
            <p class="small-font-with-picture">3.点击可进行监测项的自定义配置</p>
          </div>
          <div v-if="current === 2 && guideStatus === 1" style="position: absolute; top: 13.8%; left: 73%">
            <p class="small-font-with-picture">1.点击这里新建分组</p>
          </div>
          <div v-if="current === 2 && guideStatus === 1" style="position: absolute; top: 50%; left: 12%; width: 150px">
            <p class="small-font-with-picture">2.按照项目实际情况创建分组</p>
          </div>
          <div v-if="current === 2 && guideStatus === 1" style="position: absolute; top: 28%; left: 86%; width: 130px">
            <p class="small-font-with-picture">3.同级分组可进行上下移动</p>
          </div>
          <div v-if="current === 3 && guideStatus === 1" style="position: absolute; top: 13.7%; left: 63%">
            <p class="small-font-with-picture">1.点击这里新增监测点</p>
          </div>
          <div v-if="current === 3 && guideStatus === 1" style="position: absolute; top: 41%; left: 24%; width: 150px">
            <p class="small-font-with-picture">2.输入监测点名称并选中所属分组</p>
          </div>
          <div v-if="current === 3 && guideStatus === 1" style="position: absolute; top: 71%; left: 86%; width: 130px">
            <p class="small-font-with-picture">3.点击这里设置监测点的监测数据</p>
          </div>
          <div v-if="current === 3 && guideStatus === 1" style="position: absolute; top: 81%; left: 55.3%; width: 130px">
            <p class="small-font-with-picture">4.选择需要添加的监测指标后，点击【>】，并保存</p>
          </div>
          <div v-if="current === 4 && guideStatus === 1" style="position: absolute; top: 15.5%; left: 57%">
            <p class="small-font-with-picture">1.设置需要显示的监测项</p>
          </div>
          <div v-if="current === 4 && guideStatus === 1" style="position: absolute; top: 43%; left: 21%; width: 150px">
            <p class="small-font-with-picture">2.设置查看的历史数据时间范围</p>
          </div>
          <div v-if="current === 4 && guideStatus === 1" style="position: absolute; top: 43%; left: 45%; width: 130px">
            <p class="small-font-with-picture">3.设置查看的历史数据监测项</p>
          </div>
          <div v-if="current === 5 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 16%; left: 77%">
            <p class="small-font-with-picture">1.新增设备型号</p>
          </div>
          <div
            v-if="current === 5 && guideStatus === 1 && pageIndex == 0"
            style="position: absolute; top: 41%; left: 81%; width: 200px"
          >
            <p class="small-font-with-picture">2.设置型号对应的图例</p>
          </div>
          <div v-if="current === 5 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 13.9%; left: 79%">
            <p class="small-font-with-picture">3.新增设备</p>
          </div>
          <div
            v-if="current === 5 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 26%; left: 47%; width: 150px"
          >
            <p class="small-font-with-picture">4.选择设备的监测点</p>
          </div>
          <div
            v-if="current === 5 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 66%; left: 30%; width: 200px"
          >
            <p class="small-font-with-picture">5.选择设备对应的型号</p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 22%; left: 70%">
            <p class="small-font-with-picture">1.点击进入编辑工艺图页面</p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 29.6%; left: 32%">
            <p class="small-font-with-picture">2.当前分组下的设备/监测项和系统内置设备，直接拖入绘图区即可</p>
          </div>
          <div
            v-if="current === 6 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 15%; left: 72%; width: 150px"
          >
            <p class="small-font-with-picture">3.选择连线类型</p>
          </div>
          <div
            v-if="current === 6 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 40%; left: 74.5%; width: 200px"
          >
            <p class="small-font-with-picture">
              4.设备连线时，鼠标悬浮在设备的输入点上按住鼠标左键，选择需要连接的下一个设备的输出点
            </p>
          </div>
          <div
            v-if="current === 6 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 70%; left: 56%; width: 200px"
          >
            <p class="small-font-with-picture">5.从对应的分组菜单中拖出需要在画面中显示的监测指标，摆放在相应的位置</p>
          </div>
          <div
            v-if="current === 6 && guideStatus === 1 && pageIndex == 2"
            style="position: absolute; top: 33%; left: 19%; width: 250px"
          >
            <p class="small-font-with-picture">
              6.选择设备后，可进行设备指标的添加与关联（分组菜单中的设备创建设备的时候关联监测点则不需要额外添加指标）
            </p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 2" style="position: absolute; top: 92%; left: 61%">
            <p class="small-font-with-picture">7.点击可对设备添加指标</p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 2" style="position: absolute; top: 47%; left: 61%">
            <p class="small-font-with-picture">8.可设置设备状态是否显示</p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 2" style="position: absolute; top: 82%; left: 67%">
            <p class="small-font-with-picture">9.选择设备绑定的监测项是否显示</p>
          </div>
          <div v-if="current === 6 && guideStatus === 1 && pageIndex == 3" style="position: absolute; top: 14.5%; left: 25%">
            <p class="small-font-with-picture">10.可查看并切换各分组的工艺图</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 13.5%; left: 75%">
            <p class="small-font-with-picture">1.点击新增视频账号</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 39%; left: 34%">
            <p class="small-font-with-picture">2.输入对应设备类似的key和秘钥</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 63.5%; left: 54.5%">
            <p class="small-font-with-picture">3.点击新摄像头（只能增加接入萤石云、乐橙云的摄像头）</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 89%; left: 58.5%">
            <p class="small-font-with-picture">4.输入摄像头的设备序列号</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 16%; left: 16%">
            <p class="small-font-with-picture">5.可查看实时监控和视频回放画面</p>
          </div>
          <div v-if="current === 7 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 37%; left: 12%">
            <p class="small-font-with-picture">6.通过摄像头列表勾选需要查看的视频监控画面</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 12.7%; left: 57%">
            <p class="small-font-with-picture">1.可设置预警级别和非预警时段</p>
          </div>
          <div
            v-if="current === 8 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 31%; left: 83%; width: 120px"
          >
            <p class="small-font-with-picture">2.设置预警消息的接收方式</p>
          </div>
          <div
            v-if="current === 8 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 31%; left: 93%; width: 90px"
          >
            <p class="small-font-with-picture">3.点击新增预警设置</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 46%; left: 33.3%">
            <p class="small-font-with-picture">4.设置预警名称</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 50.5%; left: 33.4%">
            <p class="small-font-with-picture">5.选择预警指标</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 55%; left: 33.4%">
            <p class="small-font-with-picture">6.选择预警范围：全部或指定监测项</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 46%; left: 82%">
            <p class="small-font-with-picture">7.选择预警级别</p>
          </div>
          <div v-if="current === 8 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 50.7%; left: 77%">
            <p class="small-font-with-picture">8.设置预警触发条件</p>
          </div>
          <div v-if="current === 9 && guideStatus === 1 && pageIndex == 0" style="position: absolute; top: 13.8%; left: 77%">
            <p class="small-font-with-picture">1.点击新增角色</p>
          </div>
          <div
            v-if="current === 9 && guideStatus === 1 && pageIndex == 0"
            style="position: absolute; top: 44%; left: 9.5%; width: 150px"
          >
            <p class="small-font-with-picture">2.设置角色审批权限和功能权限</p>
          </div>
          <div v-if="current === 9 && guideStatus === 1 && pageIndex == 1" style="position: absolute; top: 13.7%; left: 77%">
            <p class="small-font-with-picture">3.点击新增成员</p>
          </div>
          <div
            v-if="current === 9 && guideStatus === 1 && pageIndex == 1"
            style="position: absolute; top: 46%; left: 13%; width: 160px"
          >
            <p class="small-font-with-picture">4.设置成员的分组权限和对应的角色</p>
          </div>
          <div v-if="current === 10 && guideStatus === 1" style="position: absolute; top: 16%; left: 79%; width: 250px">
            <p class="small-font-with-picture">1.运维任务包含任务分配、人物列表、运维计划、任务地图、任务设置、排班日历等功能</p>
          </div>
          <div v-if="current === 10 && guideStatus === 1" style="position: absolute; top: 45%; left: 17%; width: 250px">
            <p class="small-font-with-picture">
              2.库存中心包含出库申请、入库申请、还库申请、工具库、耗材库、设备库和库区设置等功能
            </p>
          </div>
          <div v-if="current === 10 && guideStatus === 1" style="position: absolute; top: 45%; left: 69%; width: 250px">
            <p class="small-font-with-picture">
              3.物Link小程序包含数据监测、视频监控、设备远控以及运维任务、发起任务和预警中心等功能
            </p>
          </div>
          <img :src="steps[current].content[pageIndex]" class="showPicture" />
        </div>
      </div>
      <div class="steps-action">
        <a-row justify="center">
          <a-col>
            <a-button class="button-up" @click="prev">上一步</a-button>
          </a-col>
          <a-col>
            <a-space>
              <a-button v-if="isFinishReading()" class="button-down" type="primary" @click="finishReading">完成 </a-button>
              <a-button v-else class="button-down" type="primary" @click="next">下一步</a-button>
            </a-space>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {Modal, Upload} from 'ant-design-vue';
import {ForwardFilled} from '@ant-design/icons-vue';
import {height} from 'dom7';
import {LeftCircleOutlined, RightCircleOutlined} from '@ant-design/icons-vue';
export default {
  name: 'Guide',
  components: {
    ForwardFilled,
    LeftCircleOutlined,
    RightCircleOutlined,
  },
  props: {
    guideTitle: String,
    showContent: Array,
    linkedDocument: Array,
  },
  data() {
    return {
      bigFontSizeWithBody: '20px',
      smallFontSizeWithBody: '16px',
      bigFontSizeWithPicture: '20px',
      smallFontSizeWithPicture: '16px',
      arrowSizeWithBody: '97px',
      brokenLineHeightWithBody: '397px',
      brokenLineWidthWithBody: '227px',
      buttonWidthWithBody: '150px',
      buttonHeightWithBody: '48px',
      buttonPaddingLeftWithBody: '800px',
      current: 0,
      guideStatus: 0,
      visible: true,
      pageIndex: 0,
      stepsOne: [
        ['/static/guide/创建项目.png', '创建项目', '项目：对物联设备数据以及业务的管理集合', '/static/guide/创建项目(1).png'],
        [
          '/static/guide/添加传感设备.png',
          '添加传感设备',
          '传感设备包含智能网关和各类型传感器',
          '/static/guide/添加传感设备(1).png',
        ],
        ['/static/guide/添加分组.png', '添加分组', '分组：对项目内容进行组织划分', '/static/guide/添加分组(1).png'],
        ['/static/guide/添加监测点.png', '添加监测点', '监测点：反映被监测对象变化的观测点', '/static/guide/添加监测点(1).png'],
        ['/static/guide/数据监测.png', '数据监测', '查看各监测项的实时数据、历史数据', '/static/guide/数据监测(1).png'],
        ['/static/guide/添加工艺设备.png', '添加工艺设备', '工艺设备：现场生产或工艺中的设备', '/static/guide/添加工艺设备(1).png'],
        ['/static/guide/添加工艺图.png', '添加工艺图', '现实工艺流程的可视化展示和操作', '/static/guide/添加工艺图(1).png'],
        [
          '/static/guide/添加摄像头.png',
          '添加摄像头',
          '添加摄像头，可查看实时监控、视频回访画面',
          '/static/guide/添加摄像头(1).png',
        ],
        ['/static/guide/预警设置.png', '预警设置', '对监测项进行预警设置，可查看预警记录', '/static/guide/预警设置(1).png'],
        ['/static/guide/权限设置.png', '权限设置', '创建不同的角色并赋予相应成员权限', '/static/guide/权限设置(1).png'],
        ['/static/guide/更多.png', '更多', '包含运维中心、库存中心和物Link小程序', '/static/guide/更多(1).png'],
      ],
      selectedStep: 0,
      steps: [
        {
          title: '创建项目',
          text: '项目：对物联设备数据以及业务的管理集合',
          icon: ['/static/guide/创建项目.png', '/static/guide/创建项目(1).png'],
          content: ['/static/guide/content/01.png'],
        },
        {
          title: '添加传感设备',
          text: '传感设备包含智能网关和各类型传感器',
          icon: ['/static/guide/添加传感设备.png', '/static/guide/添加传感设备(1).png'],
          content: ['/static/guide/content/02.png'],
        },
        {
          title: '添加分组',
          text: '分组：对项目内容进行组织划分',
          icon: ['/static/guide/添加分组.png', '/static/guide/添加分组(1).png'],
          content: ['/static/guide/content/03.png'],
        },
        {
          title: '添加监测点',
          text: '监测点：反映被监测对象变化的观测点',
          icon: ['/static/guide/添加监测点.png', '/static/guide/添加监测点(1).png'],
          content: ['/static/guide/content/04.png'],
        },
        {
          title: '数据监测',
          text: '查看各监测项的实时数据、历史数据',
          icon: ['/static/guide/数据监测.png', '/static/guide/数据监测(1).png'],
          content: ['/static/guide/content/05.png'],
        },
        {
          title: '添加工艺设备',
          text: '工艺设备：现场生产或工艺中的设备',
          icon: ['/static/guide/添加工艺设备.png', '/static/guide/添加工艺设备(1).png'],
          content: ['/static/guide/content/06-1.png', '/static/guide/content/06-2.png'],
        },
        {
          title: '添加工艺图',
          text: '现实工艺流程的可视化展示和操作',
          icon: ['/static/guide/添加工艺图.png', '/static/guide/添加工艺图(1).png'],
          content: [
            '/static/guide/content/07-1.png',
            '/static/guide/content/07-2.png',
            '/static/guide/content/07-3.png',
            '/static/guide/content/07-4.png',
          ],
        },
        {
          title: '添加摄像头',
          text: '添加摄像头，可查看实时监控、视频回访画面',
          icon: ['/static/guide/添加摄像头.png', '/static/guide/添加摄像头(1).png'],
          content: ['/static/guide/content/08-1.png', '/static/guide/content/08-2.png'],
        },
        {
          title: '预警设置',
          text: '对监测项进行预警设置，可查看预警记录',
          icon: ['/static/guide/预警设置.png', '/static/guide/预警设置(1).png'],
          content: ['/static/guide/content/09-1.png', '/static/guide/content/09-2.png'],
        },
        {
          title: '权限设置',
          text: '创建不同的角色并赋予相应成员权限',
          icon: ['/static/guide/权限设置.png', '/static/guide/权限设置(1).png'],
          content: ['/static/guide/content/10-1.png', '/static/guide/content/10-2.png'],
        },
        {
          title: '更多',
          text: '包含运维中心、库存中心和物Link小程序',
          icon: ['/static/guide/更多.png', '/static/guide/更多(1).png'],
          content: ['/static/guide/content/11.png'],
        },
      ],
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.bigFontSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 20) / 1900 + 'px';
    this.smallFontSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 16) / 1900 + 'px';
    this.arrowSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 97) / 1900 + 'px';
    this.brokenLineHeightWithBody = (document.querySelector('.ant-modal-body').clientHeight * 397) / 907 + 'px';
    this.brokenLineWidthWithBody = (document.querySelector('.ant-modal-body').clientWidth * 227) / 1900 + 'px';
    this.buttonWidthWithBody = (document.querySelector('.ant-modal-body').clientWidth * 150) / 1900 + 'px';
    this.buttonHeightWithBody = (document.querySelector('.ant-modal-body').clientHeight * 48) / 907 + 'px';
    this.buttonPaddingLeftWithBody = (document.querySelector('.ant-modal-body').clientWidth * 800) / 1900 + 'px';
    if (document.querySelector('.content-picture')) {
      this.bigFontSizeWithPicture = (document.querySelector('.content-picture').clientWidth * 20) / 1300 + 'px';
      this.smallFontSizeWithPicture = (document.querySelector('.content-picture').clientWidth * 16) / 1300 + 'px';
    }
    window.addEventListener('resize', this.windowResizeCallback);
  },
  methods: {
    windowResizeCallback() {
      this.bigFontSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 20) / 1900 + 'px';
      this.smallFontSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 16) / 1900 + 'px';
      this.arrowSizeWithBody = (document.querySelector('.ant-modal-body').clientWidth * 97) / 1900 + 'px';
      this.brokenLineHeightWithBody = (document.querySelector('.ant-modal-body').clientHeight * 397) / 907 + 'px';
      this.brokenLineWidthWithBody = (document.querySelector('.ant-modal-body').clientWidth * 227) / 1900 + 'px';
      this.buttonWidthWithBody = (document.querySelector('.ant-modal-body').clientWidth * 150) / 1900 + 'px';
      this.buttonHeightWithBody = (document.querySelector('.ant-modal-body').clientHeight * 48) / 907 + 'px';
      this.buttonPaddingLeftWithBody = (document.querySelector('.ant-modal-body').clientWidth * 800) / 1900 + 'px';
      if (document.querySelector('.content-picture')) {
        this.bigFontSizeWithPicture = (document.querySelector('.content-picture').clientWidth * 20) / 1300 + 'px';
        this.smallFontSizeWithPicture = (document.querySelector('.content-picture').clientWidth * 16) / 1300 + 'px';
      }
    },
    turnToContent(index) {
      this.current = index;
      this.guideStatus = 1;
    },
    next() {
      const len = this.steps[this.current].content.length;
      if (this.pageIndex != len - 1) {
        this.pageIndex++;
      } else {
        if (this.current != 10) this.current++;
        this.pageIndex = 0;
      }
    },
    prev() {
      if (this.current == 0 && this.pageIndex == 0) {
        this.guideStatus = 0;
      } else {
        const len = this.steps[this.current].content.length;
        if (this.pageIndex != 0) {
          this.pageIndex--;
        } else {
          if (this.current != 0) this.current--;
          this.pageIndex = 0;
        }
      }
    },
    toThisStep(index) {
      this.current = index;
      this.pageIndex = 0;
    },
    isFinishReading() {
      const len = this.steps[this.current].content.length;
      return this.current == 10 && this.pageIndex == len - 1;
    },
    finishReading() {
      this.visible = false;
      this.current = 0;
      this.pageIndex = 0;
    },
    hideModal() {
      this.visible = false;
    },
    currentIndex(index) {
      if (index < 10) {
        return '0' + index;
      }
      return index;
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.windowResizeCallback);
  },
};
</script>
<style lang="less" scoped>
.theme-light {
  .single-step /deep/ {
    img {
      filter: brightness(0);
    }
    .single-step-selected {
      img {
        filter: invert(52%) sepia(92%) saturate(6345%) hue-rotate(226deg) brightness(104%) contrast(101%) !important;
      }
    }
    /deep/ .circleSelected {
      background-color: white !important;
      border-color: #4262ff !important;
    }
  }
  .steps-content /deep/ .content-picture {
    img {
      filter: none !important;
    }
  }
  .first-steps /deep/ {
    img {
      filter: brightness(0);
    }
  }
  .second-steps /deep/ {
    img {
      filter: brightness(0);
    }
  }

  .guide-content {
    img {
      filter: brightness(0);
    }
    /deep/ .ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: rgba(5, 5, 5, 1) !important;
    }
  }
  .ant-steps-item-active {
    img {
      filter: invert(52%) sepia(92%) saturate(6345%) hue-rotate(226deg) brightness(104%) contrast(101%) !important;
    }
    /deep/ .ant-steps-icon {
      color: #4262ff !important;
    }
    /deep/ .ant-steps-item-title {
      color: #4262ff !important;
    }
  }
  .ant-steps-item-finish {
    /deep/ .ant-steps-icon {
      color: black !important;
    }
  }
}

.full-modal {
  .ant-modal {
    background: rgba(6, 16, 60, 0.6);
  }

  .ant-modal-content {
    background-color: rgba(6, 16, 60, 0.6);
  }

  .ant-modal-content::before {
    pointer-events: none;
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    background: none;
  }
  .ant-modal-body {
    flex: 1;
    background: rgba(6, 16, 60, 0.6);

    .first-steps {
      position: absolute;
      top: 18%;
      left: 10%;
      height: 204px;

      .single-step {
        width: 10.4%;
        margin-right: 6.26%;
        text-align: -webkit-center;

        .arrow-with-body {
          position: absolute;
          left: 11%;
          top: 22%;
          width: var(--arrowSizeWithBody);
        }

        .circleSelected {
          width: 100px;
          height: 100px;
          background: rgba(71, 230, 255, 0.2);
          border: 1px solid #47e6ff;
          border-radius: 50%;
          position: relative;
        }

        .circle {
          width: 100px;
          height: 100px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 50%;
          position: relative;
        }

        p {
          font-family: PingFangSC-Regular;
        }
      }
    }

    .single-step-selected {
      p {
        font-family: PingFangSC-Regular;
        color: #47e6ff;
      }
      img {
        filter: none;
      }
    }

    a {
      color: rgb(95, 95, 221);
    }

    .second-steps {
      position: absolute;
      top: 60%;
      left: 13%;
      height: 205px;

      .single-step {
        width: 10.4%;
        margin-right: 9.5%;
        text-align: -webkit-center;

        .circleSelected {
          width: 100px;
          height: 100px;
          background: rgba(71, 230, 255, 0.2);
          border: 1px solid #47e6ff;
          border-radius: 50%;
          position: relative;
        }

        .circle {
          width: 100px;
          height: 100px;
          background: rgba(255, 255, 255, 0.05);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 50%;
          position: relative;
        }

        p {
          font-family: PingFangSC-Regular;
        }
      }
    }

    .button-row {
      position: absolute;
      top: 89%;
      left: var(--buttonPaddingLeftWithBody);
      justify-content: space-between;
      min-width: 300px;
    }

    .hyper-link-row {
      position: absolute;
      top: 96%;
      left: 43.7%;
    }
  }

  .steps-content {
    margin-top: 16px;
    border-radius: 6px;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
    height: 700px;
    justify-content: center;
    margin-left: 257px;
  }

  .steps-action {
    margin-top: 24px;
    justify-content: center;
    position: absolute;
    top: 91%;
    left: 42%;
  }

  [data-theme='dark'] .steps-content {
    background-color: #2f2f2f;
    border: 1px dashed #e7bcbc;
  }
}

.innerIndex {
  position: absolute;
  left: 38%;
  top: 20%;
}

.innerImage {
  position: absolute;
  left: 35%;
  top: 50%;
}

.content-picture {
  width: 69%;
  height: 75%;
  justify-content: center;
  position: absolute;
  top: 17%;
  left: 16%;
}

.content-text {
  position: absolute;
  top: 12%;
  left: 42%;

  p {
    font-size: var(--bigFontSizeWithBody);
    font-family: PingFangSC-Regular;
    color: #4262ff;
  }
}

.showPicture {
  height: 100%;
  width: 100%;
  min-width: 600px;
}

.text-detail {
  z-index: 1000;
}

.text-selected {
  p {
    font-family: PingFangSC-Regular;
    color: #47e6ff;
  }

  .showPicture {
    color: #46e4fd;
  }
}

.button-up {
  width: var(--buttonWidthWithBody);
  height: var(--buttonHeightWithBody);
  margin-right: 24px;
}

.button-down {
  width: var(--buttonWidthWithBody);
  height: var(--buttonHeightWithBody);
  border: 1px solid rgba(72, 231, 255, 0.4);
}

.big-font-with-body {
  font-size: var(--bigFontSizeWithBody);
}

.small-font-with-body {
  font-size: var(--smallFontSizeWithBody);
}

.big-font-with-picture {
  font-size: var(--bigFontSizeWithPicture);
}

.small-font-with-picture {
  font-size: var(--smallFontSizeWithPicture);
}

.arrow-size-with-body {
  width: var(--arrowSizeWithBody);
}

.index-in-icon {
  position: absolute;
  top: -130%;
  left: 22%;
  z-index: 1001;
}
:deep(.ant-steps-item-content) {
  margin-top: -10px !important;
}
.ant-steps-horizontal {
  margin-top: 10px;
}

:deep(.ant-steps-item-active .ant-steps-icon) {
  color: #48e7ff !important;
}
</style>
