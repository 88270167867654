import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {createVNode} from 'vue';
import {Modal, notification} from 'ant-design-vue';
import store from '../store';
import router from '../router';
import {getGatewayIndicatorTreeInGroup, getHasPermissionGatewayIndicatorTree} from '@/api/gatewayIndicators';
import {getGroupSelectTree} from '@/api/group';
import {getMessagesByParam as getMessagesByParameter} from '../api/message';
import AMapLoader from '@amap/amap-jsapi-loader';
import {city} from '@/components/divform/city';
import {useLoadMore} from 'vue-request';
import {useRouter} from 'vue-router';
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import dayjs from 'dayjs';
import Qs from 'qs';
import _ from 'lodash';
import QRCode from 'qrcode';
import {
  getLastMetricsByAnyGateway,
  getLastMetricsByAnyGatewayAndIndicators,
  getMetricsByAnyGateway,
  iotGetLastIndicators,
} from '@/api/iot';
import formCreate from '@form-create/ant-design-vue';
import Stomp from 'webstomp-client';
import {param} from '.';

// 昆仑可复用功能插件
function endSubmit(success, data) {
  this.confirmLoading = false;
  if (success) {
    this.$emit('detailOk', data);
    if (this.$data.hasOwnProperty('visible')) {
      this.visible = false;
    }
  }
}

const mainContentTop = 104;
function calcHeightFromView(offset, minHeight = 80, lightThemeOffset = 0) {
  // 修改时，需要和跟css中设置的最小高度适配
  const mainContentMinHeight = 396;
  if (store.getters.curTheme === 'light') offset += lightThemeOffset;
  const realMinHeight = Math.max(minHeight, mainContentMinHeight + mainContentTop - offset);
  return 'max(calc(100vh - ' + offset + 'px), ' + realMinHeight + 'px)';
}

// includePadding是按照main-content的高度，去掉上16下24的留空，进行计算
function calcHeightFromContent(offset, minHeight = 80, lightThemeOffset = 0, includePadding = false) {
  const padding = includePadding ? 0 : 40;
  return calcHeightFromView(mainContentTop + padding + offset, minHeight, lightThemeOffset);
}

function getMessagesCard() {
  getMessagesByParameter('', 0, 8, false).then((response) => {
    const data = response.data.content;
    const array = [];
    if (data.length > 0) {
      for (const item of data) {
        array.push(item);
      }
    }
    this.$store.commit('SET_MESSAGELIST', array);
  });
}

function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let index = 0; index != s.length; ++index) {
    view[index] = s.codePointAt(index) & 0xff;
  }
  return buf;
}

async function addTemplateHeadersAndDownload(url, newHeaders, downloadName) {
  // 该方式针对 Chrome 42+ 的现代网站，兼容性不好
  // eslint-disable-next-line unicorn/no-await-expression-member
  // const workbook = XLSX.read(await (await fetch(url)).arrayBuffer(), {cellStyles: true});
  let req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'arraybuffer';

  req.onload = (e) => {
    let workbook = XLSX.read(req.response, {cellStyles: true});
    // 找到最后一列的编号（一共是5列，最后一列的编号是4，转换后是E）
    let lastCol = XLSX.utils.encode_col(newHeaders.length);

    // 遍历每张表读取
    for (const sheet in workbook.Sheets) {
      if (workbook.Sheets.hasOwnProperty(sheet)) {
        const ws = workbook.Sheets[sheet];
        const cellInfoMap = {};
        const oldRange = XLSX.utils.decode_range(ws['!ref']);
        for (let c = oldRange.s.c; c <= oldRange.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1';
          const key = ws[header].v.replace(' ', '');
          cellInfoMap[key] = ws[header];
        }
        // 重新设置 单元格范围
        ws['!ref'] = `A1:${lastCol}1`;
        const newRange = XLSX.utils.decode_range(ws['!ref']);
        // 找到worksheet中 A1,B1,C1...等设置表头的字段，替换为上方newHeaders对应的数据
        for (let c = newRange.s.c; c <= newRange.e.c; c++) {
          const header = XLSX.utils.encode_col(c) + '1';
          const oldInfo = cellInfoMap[newHeaders[c]];
          const cellC = oldInfo != undefined ? oldInfo.c : undefined;
          ws[header] = {
            t: 's',
            v: newHeaders[c],
            r: `<t>${newHeaders[c]}</t><phoneticPr fontId=\"1\" type=\"noConversion\"/>`,
            h: newHeaders[c],
            w: newHeaders[c],
            c: cellC,
          };
        }
      }
    }
    saveAs(
      new Blob(
        [
          s2ab(
            XLSX.write(workbook, {
              bookType: 'xlsx',
              bookSST: false,
              type: 'binary',
            })
          ),
        ],
        {
          type: 'application/octet-stream',
        }
      ),
      `${downloadName}.xlsx`
    );
  };
  req.send();
}

/**
 * 上传文件前处理文件
 * @param {Object} file 上传的文件
 * @param {Array} fileList 上传的文件列表
 * @param {function} successCallback 文件上传成功的回调函数
 * @param {function} errCallback 文件上传错误的回调函数
 */
function importFileBeforeUpload(file, fileList, successCallback, errorCallback) {
  const isAccept =
    file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
  if (!isAccept) {
    Modal.confirm({
      icon: () => createVNode(ExclamationCircleOutlined),
      content: () => '上传的文件格式不正确,请选择.csv,.xls,.xlsx类型的文件',
    });
    return false;
  }
  fileList = [...fileList, file];
  handleFileContent(file)
    .then((response) => {
      successCallback(response);
    })
    .catch((error) => {
      console.error('文件导入失败,请重新上传', error);
    });
}

/**
 * 上传文件增加校验(直接上传给后端)
 * @param {Object} file 上传的文件
 * @param {function} callback 文件上传的具体操作
 */
function customRequestBeforeUpload(requestInfo, customRequest, successCallback, errorCallback) {
  // 导入文件暂时只限制只能上传.csv,.xls,.xlsx格式的文件，其他类型文件需要放开可进行修改
  const isAccept =
    requestInfo.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    requestInfo.file.type === 'application/vnd.ms-excel';
  if (!isAccept) {
    Modal.confirm({
      icon: () => createVNode(ExclamationCircleOutlined),
      content: () => '上传的文件格式不正确,请选择.csv,.xls,.xlsx类型的文件',
    });
    return false;
  }
  if (customRequest) {
    customRequest(requestInfo, successCallback, errorCallback);
  }
}

function importJsonBeforeUpload(file, fileList, successCallback, errorCallback) {
  const isAccept = file.type === 'application/json';
  if (!isAccept) {
    Modal.confirm({
      icon: () => createVNode(ExclamationCircleOutlined),
      content: () => '上传的文件格式不正确,请选择.json类型的物模型文件',
    });
    return false;
  }
  fileList = [...fileList, file];
  const reader = new FileReader();
  reader.readAsText(file, 'utf-8');
  reader.addEventListener('load', (event) => {
    const data = JSON.parse(reader.result);
    data.properties;
    const dataList = [];
    let index = 1;
    for (const property of data.properties) {
      const accessMode = property.accessMode;
      let access = '';
      if (accessMode.length == 2) {
        access = '读写';
      } else if (accessMode == 'r') {
        access = '只读';
      } else if (accessMode == 'w') {
        access = '只写';
      }
      const isNumber = ['int', 'float', 'double', 'enum'].includes(property.dataType.type);
      dataList.push({
        显示小数位数: isNumber ? (property.dataType.specs.step + '.').split('.')[1].length + '位' : undefined,
        序号: index,
        数据别名: property.name,
        数据标识: property.identifier,
        监测指标: property.desc ? property.name : '温度',
        读写状态: access,
        单位: property.desc ? property.dataType.specs.unit : '℃',
      });
      index++;
    }
    successCallback(dataList);
  });
}

/**
 * 对上传文件进行处理
 * @param {Object} info 上传的文件
 */
function handleFileContent(info) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const file = info;
    let dataList = [];
    reader.readAsArrayBuffer(file);
    reader.addEventListener('load', (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, {type: 'binary'});
      let buildings = []; // 存储获取到的数据
      // 遍历每张表读取
      for (const sheet in workbook.Sheets) {
        if (workbook.Sheets.hasOwnProperty(sheet)) {
          let headers = [];
          const ws = workbook.Sheets[sheet];
          if (ws['!ref'] == undefined) {
            continue;
          }
          const range = XLSX.utils.decode_range(ws['!ref']);
          for (let c = range.s.c; c <= range.e.c; c++) {
            const header = XLSX.utils.encode_col(c) + '1';
            if (ws[header] != undefined) {
              headers.push(
                String(ws[header].v)
                  .trim()
                  .replace(/(\*|（必填）|(必填))/g, '')
              );
            }
          }
          buildings = [...buildings, ...XLSX.utils.sheet_to_json(ws, {header: headers, range: 1})];
          // break; // 如果只取第一张sheet表，就取消注释这行
        }
      }
      dataList = buildings;
      resolve(dataList);
      if (reader.readyState) {
        resolve(dataList);
      } else {
        reject();
      }
    });
  });
}

/**
 * 导出excel文件操作
 * @param {Array} header excel文件表头
 * @param {Array} data excel文件数据内容
 * @param {String} fileName excel文件名
 */
function exportExcelFile(header, data, fileName) {
  Modal.confirm({
    title: () => '确定导出列表中的所有数据？',
    icon: () => createVNode(ExclamationCircleOutlined),
    content: () => '点击确认按钮后会列表中的所有数据的导出为Excel文件，点击取消按钮退出数据导出',
    maskClosable: true,
    onOk() {
      return new Promise((resolve, reject) => {
        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        export2Excel(header, data, fileName);
      }).catch(() => {});
    },
    onCancel() {},
  });
}

function exportExcelFileMany(multiHeaders, data, filename, merges, isLoadMore = true) {
  Modal.confirm({
    title: () => '确定导出列表中的所有数据？',
    icon: () => createVNode(ExclamationCircleOutlined),
    content: () => '点击确认按钮后会列表中的所有数据的导出为Excel文件，点击取消按钮退出数据导出',
    maskClosable: true,
    onOk() {
      return new Promise((resolve, reject) => {
        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        export2ExcelMany(multiHeaders, data, filename, merges, isLoadMore);
      }).catch(() => {});
    },
    onCancel() {},
  });
}

function export2ExcelMany(multiHeaders, data, filename, merges, isLoadMore = true) {
  require.ensure([], () => {
    const {exportJsonToExcelMany} = require('@/excel/export2Excel'); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
    exportJsonToExcelMany({
      multiHeaders, // 这里是第一行的表头
      data,
      filename,
      merges,
    });
    notification.success({
      title: '成功',
      message: isLoadMore ? '数据导出成功' : '已成功导出所有数据!',
    });
  });
}

/**
 * 数据写入excel
 * @param {Array} header excel文件表头
 * @param {Array} data excel文件数据内容
 * @param {String} fileName excel文件名
 * @param {Boolean} isLoadMore 是否为最后一页
 */
function export2Excel(header, data, fileName, isLoadMore = true) {
  require.ensure([], () => {
    const {exportJsonToExcel} = require('@/excel/export2Excel'); // 这里必须使用绝对路径，使用@/+存放export2Excel的路径
    let message = '数据导出成功';
    exportJsonToExcel(header, data, fileName);
    if (fileName.includes('模板')) {
      message = '模板下载成功';
    } else if (!isLoadMore) {
      message = '已成功导出所有数据!';
    }
    notification.success({
      title: '成功',
      message: message,
    });
  });
}

/**
 *
 * @param {Object} data 导出的数据
 * @param {String} fileName 文件名称
 */
function exportJsonFile(data, fileName) {
  if (typeof navigator !== 'undefined' && navigator.msSaveOrOpenBlob) {
    // 兼容ie10
    let blob = new Blob([JSON.stringify(data)], {
      type: 'data:application/json;charset=utf-8',
    });
    navigator.msSaveOrOpenBlob(blob, fileName + ',json');
  } else {
    const a = document.createElement('a');
    a.style.visibility = 'hidden';
    document.body.append(a);
    let blobs = new Blob([JSON.stringify(data)], {
      type: 'data:application/json;charset=utf-8',
    });
    let objurl = URL.createObjectURL(blobs);
    a.href = objurl;
    // a.href = "data:application/json;charset=utf-8" + JSON.stringify(data, null, 2);  // 使用这种方式会导出文件失败net error，原因在于协议不同无法跨域
    a.download = fileName + '.json';
    a.click();
    a.remove();
  }
}

/**
 *
 * @param {Object} data 导出的数据
 * @param {String} fileName 文件名称
 */
function exportTxtFile(data, fileName) {
  const blob = new Blob([data], {type: 'text/plain;charset=utf-8'});
  const url = URL.createObjectURL(blob);

  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName + '.txt';
  document.body.append(downloadLink);
  downloadLink.click();
  downloadLink.remove();
  URL.revokeObjectURL(url);
}

function confirmDelete(content, deleteFunction, successFunction) {
  Modal.confirm({
    content: content,
    icon: createVNode(ExclamationCircleOutlined),
    onOk: () => {
      // 删除
      return new Promise((resolve, reject) => {
        deleteFunction()
          .then((response) => {
            if (response.code == this.$SUCCESS_CODE) {
              this.$notification.success({
                title: '成功',
                message: response.message,
              });
              successFunction();
            } else {
              this.$notification.warning({
                title: '删除失败！',
                message: response.message,
                style: {'max-height': '240px', overflow: 'auto'},
              });
            }
            resolve();
          })
          .catch((error) => {
            console.log(error);
            this.$notification.warning({
              title: '删除失败！',
              message: error.message,
            });
            reject();
          });
      }).catch((error) => console.log('Oops errors!', error));
    },
  });
}

function hasPermission(value, routeName) {
  let permissionValue = 0;
  if (routeName) {
    routeName = routeName + '-' + router.currentRoute._value.name.split('-')[router.currentRoute._value.name.split('-').length - 1];
    const route = router.getRoutes().find((v) => v.name == routeName);
    if (route) {
      permissionValue = route.meta.permission;
    }
  } else {
    permissionValue = router.currentRoute._value.meta.permission;
  }
  return (value & permissionValue) != 0;
}

function hasFunctionPermission(value, functionPermission) {
  return (value & functionPermission) != 0;
}

// 退出登录
function loginOut() {
  store.dispatch('LogOut').then(() => {
    window.location.href = this.$loginUrl;
    sessionStorage.clear();
  });
}

function getDefaultName(routes, params) {
  for (const route of routes) {
    if (route.name && route.name != 'root') {
      if (
        params.subSystems.includes(route.meta.subSystem) &&
        route.meta.groupId == params.groupId &&
        route.meta.firmId == params.firmId &&
        route.meta.customType !== 11 &&
        route.meta.code === undefined
      ) {
        return route.name;
      }
    } else {
      if (route.children?.length > 0) {
        const subName = getDefaultName(route.children, params);
        if (subName) {
          return subName;
        }
      }
    }
  }
}

function getRealRouteName(name, parametersObject) {
  if (name === undefined) {
    return;
  }
  name = name.replace(/-[fgps]\d*$/, '');
  const oldName = name;
  if (!parametersObject) {
    parametersObject = {
      firmId: undefined,
      groupId: undefined,
    };
  }
  if (name == 'any') {
    let subSystems = [global.$SUBSYSTEM_SYSTEM];
    if (parametersObject.firmId && parametersObject.firmId != -1) {
      subSystems = [global.$SUBSYSTEM_FIRM];
    } else if (parametersObject.groupId && parametersObject.groupId != -1) {
      subSystems = [global.$SUBSYSTEM_CORPORATION, global.$SUBSYSTEM_PROJECT];
    }
    const params = {...parametersObject, subSystems: subSystems};
    name = getDefaultName(store.getters.routes, params);
  }
  if (name != 'any') {
    if (oldName != 'any') {
      if (parametersObject.groupId && parametersObject.groupId != -1) {
        name = name + '-g' + parametersObject.groupId;
      } else if (parametersObject.firmId && parametersObject.firmId != -1) {
        name = name + '-f' + parametersObject.firmId;
      } else {
        name = name + '-s';
      }
    }
    return name;
  }
  return;
}

// 路由跳转
async function routePush(name, parametersObject, queryObject) {
  let routeName = getRealRouteName(name, parametersObject);
  if (!routeName) {
    routeName = getRealRouteName(name, parametersObject);
    if (!routeName) {
      console.error('路由不存在', name, parametersObject);
      this.$notification.warning({
        title: '提示',
        message: '菜单不存在!',
      });
      return;
    }
  }
  router.push({
    name: routeName,
    params: parametersObject,
    query: queryObject || {},
  });
}

function hasRoute(name, parametersObject) {
  const routeName = getRealRouteName(name, parametersObject);
  return router.hasRoute(routeName);
}

function getRouteName() {
  let arrName = this.$route.name.split('-');
  return arrName.slice(1, -1).join('-');
}

let websock;
const curHost = document.location.origin.split('//')[1];
// const curHost = '192.168.1.3';

let websockReconnected = false;

/**
 * 发起websocket请求函数
 * @param {string} url ws连接地址
 * @param {Object} agentData 传给后台的参数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 * @param {function} errCallback ws连接错误的回调函数
 */
function openWebsocket(url) {
  const heartCheck = {
    pingTimeout: 15_000,
    pongTimeout: 10_000,
    pingTimeoutId: undefined,
    pongTimeoutId: undefined,

    reset: function () {
      clearInterval(this.pingTimeoutId);
      clearInterval(this.pongTimeoutId);
      return this;
    },
    start: function () {
      this.pingTimeoutId = setTimeout(() => {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息，
        // onmessage拿到返回的心跳就说明连接正常
        websock.send(
          JSON.stringify({
            id: '1', // 消息id，对应客户端请求消息的id
            cmd: 'ping', // 回复客户端消息的类型
          })
        );
        // 如果超过一定时间还没重置，说明后端主动断开了
        this.pongTimeoutId = setTimeout(() => {
          // 如果onclose会执行reconnect，我们执行ws.close()就行了.如果直接执行reconnect 会触发onclose导致重连两次
          websock.close(); // 关闭websocket
          console.error('连接超时断开!');
          // this.reconnect();
        }, this.pongTimeout);
      }, this.pingTimeout);
    },
    reconnect: function () {
      setTimeout(() => {
        if (!websockReconnected) {
          websockReconnected = true;
        }
        console.log('websock 重连');
        initWebsocket(this, url);
        refreshSubscribeGateways(websock.lastGatewayIds, websock.successCallback, websock.errorCallback);
      }, 1000);
    },
  };
  if (websock === undefined) {
    websock = initWebsocket(heartCheck, url);
  }
  if (websock.url !== url) {
    websock.close();
    websock = initWebsocket(heartCheck, url);
  }
  console.log(websock);
  return websock;
}

function initWebsocket(heartCheck, url) {
  // let tryTime = null
  // 初始化weosocket
  if (typeof WebSocket === 'undefined') {
    // Message.error('您的浏览器不支持WebSocket，无法获取数据')
    return false;
  }
  let errorCallback;
  let successCallback;
  let initSubscribeGateway;
  let lastGatewayIds;
  if (websock) {
    errorCallback = websock.errorCallback;
    successCallback = websock.successCallback;
    initSubscribeGateway = websock.initSubscribeGateway;
    lastGatewayIds = websock.lastGatewayIds;
  }

  // ws请求完整地址
  websock = new WebSocket(url);

  websock.errorCallback = errorCallback;
  websock.successCallback = successCallback;
  websock.initSubscribeGateway = initSubscribeGateway;
  websock.lastGatewayIds = lastGatewayIds;

  // 接收ws后端返回的数据
  websock.onmessage = function (event) {
    heartCheck.reset().start();
    websock.successCallback(JSON.parse(event.data));
  };
  // 建立ws连接
  websock.addEventListener('open', function () {
    console.log('ws连接成功');
    heartCheck.reset().start();
    if (websock.initSubscribeGateway) {
      websock.initSubscribeGateway();
    }
    websockReconnected = false;
  });
  // ws连接异常
  websock.onerror = function () {
    console.log('ws连接异常，请稍候重试');
    websock.errorCallback && websock.errorCallback();
    websockReconnected = false;
  };

  // 关闭ws连接
  websock.addEventListener('close', function (event) {
    console.log('ws连接关闭');
    heartCheck.reset();
    // event.code === 1000  表示正常关闭。 无论为何目的而创建, 该链接都已成功完成任务。
    // event.code !== 1000  表示非正常关闭。
    if (event && event.code !== 1000) {
      // Message.error('ws连接异常，请稍候重试')
      console.log('ws连接失败', event.code + ' ' + event.reason + ' ' + event.wasClean);
      websock.errorCallback && websock.errorCallback();
      if (!event.wasClean && event.code === 1006) {
        heartCheck.reconnect();
      }
    }
  });

  // 发起websocket连接
  setTimeout(() => {
    // 添加状态判断，当为OPEN时，发送消息
    if (websock.readyState === websock.OPEN) {
      // websock.OPEN = 1
      // 发给后端的数据需要字符串化
      // websock.send(JSON.stringify(agentData))
    }
    if (websock.readyState === websock.CLOSED) {
      // websock.CLOSED = 3
      console.log('websock.readyState=3');
      console.log('ws连接异常，请稍候重试');
    }
  }, 500);
  return websock;
}

/**
 * 关闭websocket函数
 */
function closeWebsocket() {
  /* if (websock) {
    websock.close(); // 关闭websocket
  } */
  if (websock && websock.lastGatewayIds && websock.lastGatewayIds.length > 0) {
    unsubscribeGateways(websock, websock.lastGatewayIds);
    websock.lastGatewayIds = undefined;
  }
}

function getWebsocketProtocal() {
  return 'https:' == document.location.protocol ? 'wss://' : 'ws://';
}

/**
 * 获取iot平台数据
 * @param {string} gatewayId 一个设备信息
 * @param {function} lastcallback 接收到最新的历史数据，对数据进行处理的回调函数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 */
function openRtIndicatorsDataWebsocket(gatewayId, wsMessage, wsError) {
  // 链接websocket
  const token = store.getters.token;
  const url = getWebsocketProtocal() + curHost + '/data-server/ws?isCleanData=true&token=' + token;
  const websocket = openWebsocket(url);
  refreshSubscribeGateways([gatewayId], wsMessage, wsError);
  console.log(websocket);
  return websocket;
}

/**
 * 获取iot平台原始数据
 * @param {string} gatewayId 一个设备信息
 * @param {function} lastcallback 接收到最新的历史数据，对数据进行处理的回调函数
 * @param {function} successCallback 接收到ws数据，对数据进行处理的回调函数
 */
function openOriginalRtIndicatorsDataWebsocket(gatewayId, wsMessage, wsError) {
  // 链接websocket
  const token = store.getters.token;
  const url = getWebsocketProtocal() + curHost + '/data-server/ws?isCleanData=false&token=' + token;
  const websocket = openWebsocket(url);
  refreshSubscribeGateways([gatewayId], wsMessage, wsError);
  console.log(websocket);
  return websocket;
}

function openOriginalRtIndicatorsDataNotPermissionsWs(gatewayId, wsMessage, wsError) {
  // 链接websocket
  const url = getWebsocketProtocal() + curHost + '/data-server/ws?isCleanData=false&isAnonymous=true';
  const websocket = openWebsocket(url);
  refreshSubscribeGateways([gatewayId], wsMessage, wsError);
  return websocket;
}

function openIndicatorsDataAnyGatewanySocket(gatewayIds, wsMessage, wsError) {
  // 链接websocket
  const token = store.getters.token;
  const url = getWebsocketProtocal() + curHost + '/data-server/ws?isCleanData=true&token=' + token;
  const websocket = openWebsocket(url);
  refreshSubscribeGateways(gatewayIds, wsMessage, wsError);
  return websocket;
}

function refreshSubscribeGateways(gatewayIds, successCallback, errorCallback) {
  if (websock.readyState === websock.OPEN) {
    if (websock.lastGatewayIds && websock.lastGatewayIds.length > 0) {
      unsubscribeGateways(websock, websock.lastGatewayIds);
    }
    websock.successCallback = successCallback;
    websock.errorCallback = errorCallback;
    if (gatewayIds && gatewayIds.length > 0) {
      subscribeGateways(websock, gatewayIds);
      websock.lastGatewayIds = gatewayIds;
    }
  } else {
    websock.initSubscribeGateway = function () {
      if (websock.lastGatewayIds && websock.lastGatewayIds.length > 0) {
        unsubscribeGateways(websock, websock.lastGatewayIds);
      }
      websock.successCallback = successCallback;
      websock.errorCallback = errorCallback;
      if (gatewayIds && gatewayIds.length > 0) {
        subscribeGateways(websock, gatewayIds);
        websock.lastGatewayIds = gatewayIds;
      }
    };
  }
}

function unsubscribeGateways(websocket, gatewayIds) {
  const now = new Date();
  const message = {
    id: now.getTime().toString(),
    cmd: 'unsubscribe',
    gateways: gatewayIds.map((v) => v.toString()),
  };
  websocket.send(JSON.stringify(message));
}

function subscribeGateways(websocket, gatewayIds) {
  const now = new Date();
  const message = {
    id: now.getTime().toString(),
    cmd: 'subscribe',
    gateways: gatewayIds.map((v) => v.toString()),
  };
  websocket.send(JSON.stringify(message));
}

function getGroupTreeData({groupId, disableCheckbox, parentId, generateDefault, hasOnlyWirte, gatewayIds}) {
  return new Promise((resolve, reject) => {
    getGatewayIndicatorTreeInGroup(parentId == undefined ? groupId : parentId, generateDefault, gatewayIds)
      .then((response) => {
        const data = this.dataToTreeData(response.data, disableCheckbox, hasOnlyWirte);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function getHasPermissionGroupTreeData({groupId, disableCheckbox, parentId, generateDefault, hasOnlyWirte}) {
  return new Promise((resolve, reject) => {
    getHasPermissionGatewayIndicatorTree(groupId, parentId)
      .then((response) => {
        const data = this.dataToTreeData(response.data, disableCheckbox, hasOnlyWirte);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function currentGroupId() {
  return this.$route.params.groupId != undefined && this.$route.params.groupId != -1 ? this.$route.params.groupId : undefined;
}

function currentProjectName() {
  return this.$store.getters.toptooltitle;
}

function dataToTreeData(nodeList, disableCheckbox, hasOnlyWirte) {
  for (const item of nodeList) {
    item.isLeaf = false;
    item.key = item.id;
    item.value = item.id;
    item.equipmentId = item.equipmentId;
    item.equipmentName = item.equipmentName;
    item.children = this.dataToTreeData(item.children, disableCheckbox, hasOnlyWirte);
    for (const object of item.gatewayIndicators) {
      if (object.indicator == undefined) {
        object.indicator = {};
      }
      object.disableCheckbox = disableCheckbox && !this.isNumberByIndicatorType(object.indicator.type) ? true : false;
      const node = {
        id: object.id,
        value: item.id + '-' + object.gateway + '-' + object.id + '-' + object.dataIdentification,
        key: item.id + '-' + object.gateway + '-' + object.id,
        name: object.alias ? object.alias : object.dataIdentification,
        dataIdentification: object.dataIdentification,
        indicatorEnums: object.indicator.indicatorEnums,
        indicatorId: object.indicator.id,
        dataType: this.$indicatorDataType[object.indicator.type],
        ioType: object.ioType,
        disableCheckbox: disableCheckbox && !this.isNumberByIndicatorType(object.indicator.type) ? true : false,
        groupName: item.name,
        groupParentName: item.parentName,
        groupId: item.id,
        gatewayId: object.gateway,
        unit: object.indicator.unit,
        decimalPlaces: object.decimalPlaces,
        isAutoReposition: object.isAutoReposition,
        repositionDelay: object.repositionDelay,
        isLeaf: true,
        children: [],
      };
      if (!hasOnlyWirte) {
        node.disableCheckbox = object.disableCheckbox = node.disableCheckbox || node.ioType == 2;
      }
      item.children.push(node);
    }
  }
  return nodeList;
}

function setChildObj(item, onlyLeafSelectable) {
  const a = {};
  a.key = item.id;
  a.value = item.id;
  a.title = item.name;
  a.isMonitor = item.isMonitor;
  a.children = [];
  if (item.children.length > 0) {
    if (onlyLeafSelectable) {
      a.disabled = true;
    }
    for (const child of item.children) {
      a.children.push(setChildObj(child, onlyLeafSelectable));
    }
  }
  return a;
}

function getGroupTreeOption(onlyLeafSelectable, groupId, includeRoot, includeMonitor) {
  return getGroupSelectTree(groupId == undefined ? this.$route.params.groupId : groupId, includeMonitor).then((response) => {
    response.tree = [];
    const data = includeRoot ? response.data : response.data[0].children;
    for (const item of data) {
      response.tree.push(setChildObj(item, onlyLeafSelectable));
    }
    return response.tree;
  });
}

export function getFirmId() {
  const router = useRouter();
  return router.currentRoute.value.params.firmId;
}

export function loadMore(getLoadMore) {
  const limit = 100;
  const getList = (parameters) => {
    const p = {
      pageSize: limit,
    };
    if (parameters !== undefined) {
      p['currentPage'] = parameters.dataList.length !== undefined ? Math.ceil(parameters.dataList.length / p.pageSize) : 0;
    } else {
      p['currentPage'] = 0;
    }
    return getLoadMore(p.currentPage, p.pageSize).then((response) => {
      return response.data;
    });
  };
  const {dataList, loadingMore, loadMore, noMore, refresh} = useLoadMore(getList, {
    listKey: 'content',
    loadMore: true,
    isNoMore: (d) => (d ? d.content.length < limit : false),
  });
  return {
    dataList,
    loadingMore,
    loadMore,
    noMore,
    refresh,
  };
}

function checkFull() {
  // 判断浏览器是否处于全屏状态 （需要考虑兼容问题）
  // 火狐浏览器
  // 谷歌浏览器及Webkit内核浏览器
  let isFull =
    document.mozFullScreen ||
    document.fullScreen ||
    document.webkitIsFullScreen ||
    document.webkitRequestFullScreen ||
    document.mozRequestFullScreen ||
    document.msFullscreenEnabled;
  if (isFull === undefined) {
    isFull = false;
  }
  return isFull;
}

function verifyAndGetData(response, successCallback, errCallback) {
  if (response) {
    if (response.code == this.$SUCCESS_CODE) {
      if (successCallback) {
        return successCallback(response.data);
      } else {
        this.$notification.success({
          message: response.message,
        });
      }
    } else {
      if (errCallback) {
        errCallback(response.message);
      } else {
        this.$notification.warning({
          message: response.message,
        });
      }
    }
  }
}

function getGroupDisPlayNameFormGroup() {
  return this.$store.getters.groupDisPlayName !== undefined && this.$store.getters.groupDisPlayName !== ''
    ? this.$store.getters.groupDisPlayName
    : '分组';
}

function getGroupDisPlayNameFormGroupAndMonitor() {
  return this.$store.getters.groupDisPlayName !== undefined && this.$store.getters.groupDisPlayName !== ''
    ? this.$store.getters.groupDisPlayName + '/监测点'
    : '分组/监测点';
}

function getGroupDisPlayNameFormSite() {
  return this.$store.getters.groupDisPlayName !== undefined && this.$store.getters.groupDisPlayName !== ''
    ? this.$store.getters.groupDisPlayName
    : '站点';
}

const queryConditionKey = 'query-condition';
const userId = store.getters.userId;

function cacheQueryCondition(key, data) {
  let queryConditionObj = JSON.parse(localStorage.getItem(queryConditionKey));
  if (!queryConditionObj) {
    queryConditionObj = {};
  }
  if (!queryConditionObj[userId]) {
    queryConditionObj[userId] = {};
  }
  queryConditionObj[userId][key] = data;
  localStorage.setItem(queryConditionKey, JSON.stringify(queryConditionObj));
}

function getQueryConditionCache(key, callback) {
  const dataJsonObj = JSON.parse(localStorage.getItem(queryConditionKey));
  if (!dataJsonObj || !dataJsonObj[userId]) {
    callback();
    return;
  }
  callback(dataJsonObj[userId][key]);
}

function getFirstMenu(menu) {
  if (menu.children && menu.children.length > 0) {
    for (const child of menu.children) {
      const subMenu = getFirstMenu(child);
      if (subMenu.name) {
        return subMenu;
      }
    }
  } else {
    return menu;
  }
  return menu.children && menu.children.length > 0 ? getFirstMenu(menu.children[0]) : menu;
}

function getDefaultRouterOption() {
  const lastRoute = store.getters.lastRoute;
  if (lastRoute) {
    let type;
    let id;
    switch (lastRoute.subSystem) {
      case 2:
        type = 'firm';
        id = lastRoute.params.firmId;
        break;
      case 0:
      case 1:
      case 3:
        type = 'system';
        break;
      case 4:
        type = 'project';
        id = lastRoute.params.groupId;
        break;
      case 7:
        type = 'group';
        id = lastRoute.params.groupId;
        break;
      case 10:
        type = 'corporation';
        id = lastRoute.params.groupId;
        break;
      default:
        break;
    }
    if (type) {
      return {
        type: type,
        id: id,
      };
    }
  }
}

function getRouterName() {
  const lastRoute = store.getters.lastRoute;
  if (lastRoute && hasRoute(lastRoute.name, lastRoute.params)) {
    return {
      name: lastRoute.name,
      params: lastRoute.params,
    };
  }
  const frontMenu = store.getters.frontMenu;
  let routeObj;
  if (frontMenu[3]) {
    routeObj = {name: 'router-systemProject-s'};
    for (const elements of frontMenu[3]) {
      const elements = frontMenu[3];
      const menu = getFirstMenu(elements[0]);
      if (menu) {
        routeObj = {name: menu.name, params: {}};
      }
    }
  } else if (frontMenu[2]) {
    for (const elements of frontMenu[2]) {
      const elements = frontMenu[2];
      const menu = getFirstMenu(elements[0]);
      if (menu) {
        routeObj = {name: menu.name, params: {firmId: menu.firmId}};
      }
    }
  } else if (frontMenu[10]) {
    for (const elements of frontMenu[10]) {
      const elements = frontMenu[10];
      const menu = getFirstMenu(elements[0]);
      if (menu) {
        routeObj = {name: menu.name, params: {groupId: menu.groupId}};
      }
    }
  } else if (frontMenu[4]) {
    for (const elements of frontMenu[4]) {
      const element = frontMenu[4].find((item) => item.code === undefined);
      const menu = getFirstMenu(element);
      if (menu) {
        routeObj = {
          name: menu.name,
          params: {groupId: menu.groupId},
        };
      }
    }
  } else if (frontMenu[7]) {
    for (const elements of frontMenu[7]) {
      const elements = frontMenu[7];
      const menu = getFirstMenu(elements[0]);
      if (menu) {
        routeObj = {
          name: menu.name,
          params: {groupId: menu.groupId},
        };
      }
    }
  }
  if (!routeObj || !routeObj.name) {
    routeObj = {name: 'router-project'};
  }
  return routeObj;
}

function getHistoryIotDatasWithMap({
  gatewayIds,
  indicatorNameList,
  startTime,
  endTime,
  page,
  pageSize,
  ascending,
  fetchOneMore = false,
  dataMap,
}) {
  return getMetricsByAnyGateway({
    gatewayIds: gatewayIds,
    metrics: indicatorNameList,
    startTime: startTime,
    stopTime: endTime,
    page: page,
    perPage: pageSize,
    ascending: ascending,
    fetchOneMore: fetchOneMore,
  }).then((response) => {
    if (response.data.code === this.$SUCCESS_CODE) {
      const data = response.data.data;
      const metrics = data.metrics;
      for (const item of metrics) {
        for (const gateway of Object.keys(item.metrics)) {
          for (let [key, value] of Object.entries(item.metrics[gateway])) {
            {
              item.metrics[gateway][key] = this.getIndicatorValueText(
                dataMap.get(Number(gateway) + ':' + key),
                item.metrics[gateway][key]
              );
            }
          }
        }
      }
    }
    return response;
  });
}

function getLastIotDatasWithMap(gatewayIds, dataMap) {
  return getLastMetricsByAnyGateway(gatewayIds.toString()).then((response) => {
    if (response.data.code && response.data.code === this.$SUCCESS_CODE) {
      if (gatewayIds.length === 1) {
        for (const item of response.data.data.metrics) {
          {
            item.value = this.getIndicatorValueText(dataMap.get(gatewayIds.toString() + ':' + item.name), item.value);
          }
        }
      } else {
        for (const item of response.data.data.metrics) {
          const [gatewayId, dataIdentification] = item.name.split('.');
          {
            item.value = this.getIndicatorValueText(dataMap.get(Number(gatewayId) + ':' + dataIdentification), item.value);
          }
        }
      }
      return response;
    } else {
      if (response.data.code == 40000) {
        if (response.data.data && response.data.data.startsWith('411:')) {
          this.$notification.warning({
            message: '指标数量超过限制',
          });
        } else if (response.data.message) {
          this.$notification.warning({
            message: response.data.message,
          });
        }
      } else if (response.data.code == 414) {
        this.$notification.warning({
          message: '指标数量超过限制',
        });
      }
    }
  });
}

function getLastMetricsIotDatasWithMap(gatewayIds, indicators, dataMap, time) {
  return getLastMetricsByAnyGatewayAndIndicators(gatewayIds.toString(), encodeURIComponent(JSON.stringify(indicators)), time).then(
    (response) => {
      if (response.data.code && response.data.code === this.$SUCCESS_CODE) {
        if (gatewayIds.length === 1) {
          for (const item of response.data.data.metrics) {
            {
              item.value = this.getIndicatorValueText(dataMap.get(gatewayIds.toString() + ':' + item.name), item.value);
            }
          }
        } else {
          for (const item of response.data.data.metrics) {
            const [gatewayId, dataIdentification] = item.name.split('.');
            {
              item.value = this.getIndicatorValueText(dataMap.get(Number(gatewayId) + ':' + dataIdentification), item.value);
            }
          }
        }
        return response;
      } else {
        if (response.data.code == 40000) {
          if (response.data.data && response.data.data.startsWith('411:')) {
            this.$notification.warning({
              message: '指标数量超过限制',
            });
          } else if (response.data.message) {
            this.$notification.warning({
              message: response.data.message,
            });
          }
        } else if (response.data.code == 414) {
          this.$notification.warning({
            message: '指标数量超过限制',
          });
        }
      }
    }
  );
}

function getIotDatasByWebsocket(gatewayIds, dataMap, func) {
  this.openIndicatorsDataAnyGatewanySocket(
    gatewayIds,
    (response) => {
      if (response.type !== 'pong' && response.data !== undefined && response.type === 'notify') {
        console.log('数据刷新');
        for (const metric of Object.keys(response.data.params)) {
          response.data.params[metric] = this.getIndicatorValueText(
            dataMap.get(response.data.gateway + ':' + metric),
            response.data.params[metric]
          );
        }
        if (func) {
          func(response.data);
        }
      }
    },
    () => {
      console.log('ws连接失败,请稍后重试');
    }
  );
}

function getIndicatorValueText(indicator, value) {
  let convertValue = value;
  if (indicator) {
    if (this.$indicatorDataType[indicator.type] === '布尔型' || this.$indicatorDataType[indicator.type] === '枚举型') {
      if (indicator.indicatorEnums && Object.keys(indicator.indicatorEnums).length > 0) {
        convertValue =
          value === true || value === false ? indicator.indicatorEnums[Number(value)] : indicator.indicatorEnums[value] ?? value;
      }
    } else if (typeof value == 'number') {
      convertValue = indicator.decimalPlaces !== undefined ? value.toFixed(indicator.decimalPlaces) : value;
    }
  }
  return convertValue;
}

function debounceFunc(execFunction, delay) {
  return _.debounce(execFunction, delay, {maxWait: 5000});
}

function isProject() {
  return this.$store.getters.projectGroupId == this.$route.params.groupId;
}

/**
 * 判断是否一个字符串是否可以转成JSON格式
 */

export function isJSON(str) {
  if (typeof str == 'string') {
    try {
      let obj = JSON.parse(str);
      return typeof obj == 'object' && obj ? true : false;
    } catch {
      return false;
    }
  }
}

let stompWebsocket;
let stompClient;

export function getStompClient() {
  if (stompWebsocket) {
    return stompClient;
  } else {
    stompWebsocket = new WebSocket(getWebsocketProtocal() + window.location.host + '/socket?token=' + store.getters.token);
    stompClient = Stomp.over(stompWebsocket);
    stompClient.connect({}, function (frame) {});
    return stompClient;
  }
}

export function subscribeTopic(topic, func) {
  if (stompClient.connected === true) {
    return stompClient.subscribe(topic, func);
  } else {
    stompClient.connect({}, function (frame) {
      return stompClient.subscribe(topic, func);
    });
  }
}

export function closeStompWebSocket() {
  if (stompWebsocket) {
    stompWebsocket.close();
  }
}

function getQrCodeImg(url, callback, width = 250) {
  QRCode.toDataURL(
    url,
    {margin: 1, errorCorrectionLevel: 'H', width: width},
    callback
      ? callback
      : (error, url) => {
          if (error) {
            console.error(error);
          } else {
            console.log('QRCode success!');
          }
        }
  );
}

function isNearScrollBottom(target) {
  return target.scrollTop + target.offsetHeight >= target.scrollHeight - 10;
}

function isHasSubMenuGroup(groupId) {
  let frontMenus = JSON.parse(JSON.stringify(store.getters.frontMenu[4]));
  let hasGroupMenu = false;
  for (let index = 0; index < frontMenus.length; index++) {
    const frontMenu = frontMenus[index];
    if (frontMenu.code == 'siteInspection') {
      hasGroupMenu = true;
    }
    if (frontMenu.children && frontMenu.children.length > 0) {
      frontMenus = [...frontMenus, ...frontMenu.children];
    }
  }
  if (!hasGroupMenu) {
    return false;
  }
  let groupMenus = JSON.parse(JSON.stringify(store.getters.groupMenu));
  for (let index = 0; index < groupMenus.length; index++) {
    const groupMenu = groupMenus[index];
    if (!groupMenu.spread && groupId == groupMenu.groupId) {
      return true;
    }
    if (groupMenu.children && groupMenu.children.length > 0) {
      groupMenus = [...groupMenus, ...groupMenu.children];
    }
  }
  return false;
}

function getBlob(url) {
  return new Promise((resolve) => {
    const xReq = new XMLHttpRequest();
    xReq.open('GET', url, true);
    xReq.responseType = 'blob';
    xReq.onload = () => {
      if (xReq.status === 200) {
        resolve(xReq.response);
      }
    };
    xReq.send();
  });
}

function getPermission(name, sourceId) {
  let subSystem = store.getters.subSystem;
  if (subSystem == global.$SUBSYSTEM_FIRM) {
    name = name + '-f' + sourceId;
  } else if (subSystem == global.$SUBSYSTEM_PROJECT || subSystem == global.$SUBSYSTEM_CORPORATION) {
    name = name + '-g' + sourceId;
  } else if (subSystem == global.$SUBSYSTEM_SYSTEM) {
    name = name + '-s';
  }
  let currentMenu = JSON.parse(JSON.stringify(store.getters.frontMenu[subSystem]));
  let i = 0;
  let length = currentMenu.length;
  let permission = 0;
  while (i < length) {
    let item = currentMenu[i];
    if (item.name == name) {
      permission = item.permission;
      break;
    } else if (item.children != undefined && item.children.length > 0) {
      currentMenu.push(...item.children);
      length = currentMenu.length;
    }
    ++i;
  }
  return permission;
}

function isNumberByIndicatorType(type) {
  return ['浮点型', '整型'].includes(type);
}

function isEnumByIndicatorType(type) {
  return ['布尔型', '枚举型'].includes(type);
}

function indicatorEnumsToOptions(type, indicatorEnums = {}) {
  const options = [];
  for (const k of Object.keys(indicatorEnums)) {
    const v = indicatorEnums[k];
    options.push({
      label: v,
      value: k,
    });
  }
  if (options.length === 0 && type === '布尔型') {
    options.push(
      {
        label: '1',
        value: '1',
      },
      {
        label: '0',
        value: '0',
      }
    );
  }
  return options;
}

function formatIndicatorEnumDisplayStr(indicatorEnums) {
  let indicatorEnumStr = '';
  if (indicatorEnums != undefined && Object.keys(indicatorEnums).length > 0) {
    let keys = Object.keys(indicatorEnums);
    let len = keys.length;
    for (const key of keys) {
      indicatorEnumStr += `${key}|${indicatorEnums[key]}`;
      --len;
      if (len > 0) {
        indicatorEnumStr += ',';
      }
    }
  }
  return indicatorEnumStr;
}

export function getSetMealByQuantity(quantity) {
  for (const item of global.$gatewayIndicatorSetMeal) {
    if (quantity == item.quantity) {
      return item;
    }
  }
}

export default {
  install: (app, options) => {
    notification.config({
      placement: 'bottomRight',
      duration: 3,
    });

    global = app.config.globalProperties;
    global.$gatewayIndicatorSetMeal = [
      {
        name: '256个（1000元/年/物联数据无上限）',
        quantity: 256,
        charge: '1000元/年',
        value: 0,
      },
      {
        name: '1024个（2500元/年/物联数据无上限）',
        quantity: 1024,
        charge: '2500元/年',
        value: 1,
      },
      {
        name: '4096个（5000元/年/物联数据无上限）',
        quantity: 4096,
        charge: '5000元/年',
        value: 2,
      },
    ];
    global.$locationMode = ['手动定位', '自动定位'];
    global.$menuTypes = ['', '页面', '页签'];
    global.$contentType = {
      自定义表单: 13,
      设备远控: 15,
      自定义仪表盘: 20,
    };
    global.$gatewayIndicatorInitialSize = 64;
    global.$SUBSYSTEM_USER_CENTER = 0;
    global.$SUBSYSTEM_PROJECT_MANAGE = 1;
    global.$SUBSYSTEM_FIRM = 2;
    global.$SUBSYSTEM_SYSTEM = 3;
    global.$SUBSYSTEM_PROJECT = 4;
    global.$SUBSYSTEM_MESSAGE = 5;
    global.$SUBSYSTEM_AUDIT = 6;
    global.$SUBSYSTEM_SITE = 7;
    global.$SUBSYSTEM_MOBILE = 8;
    global.$SUBSYSTEM_WLINK_APPLET = 9;
    global.$SUBSYSTEM_CORPORATION = 10;
    global.$SUCCESS_CODE = 20_000;
    global.$ERROR_STATUS = 40_000;
    global.$CACHED_STATUS = 20101;
    global.$RESPONSE_CACHED_SUCCESS = 20102; /** 缓存命令下发成功 */
    global.$RESPONSE_CACHED_ERROR = 40002; /** 缓存命令下发失败 */
    global.$TOKEN_ERROR = 40_001;
    global.$EZVIZ_SUCCESS_STATUS = 200;
    // this.$READ, this.$UPDATE, this.$DELETE, this.$CREATE 按次顺序的二进制的值
    global.$READ = 1;
    global.$UPDATE = 2;
    global.$CREATE = 8;
    global.$DELETE = 4;
    global.$TOKEN_MISS = 50_001;
    global.$loginUrl = '/login';
    global.$indicatorTypeIcon = {
      浮点型: 'icon-fu',
      布尔型: 'icon-bu',
      枚举型: 'icon-mei1',
      文本: 'icon-wen1',
      整型: 'icon-zheng',
    };
    global.$gatewayTypes = ['', '普通网关', 'NB传感器', '物联设备', '智能网关'];
    global.$indicatorDataType = {
      浮点型: '浮点型',
      整型: '整型',
      布尔型: '布尔型',
      文本: '文本',
      枚举型: '枚举型',
    };
    global.$indicatorDecimalPlaces = ['0位', '1位', '2位', '3位', '4位', '5位', '6位'];
    global.$functionScopeTypes = ['', '全部', '仅顶级分组', '仅子分组'];
    global.$indicatorConfigType = ['', '模拟通道', '串口', 'ZigBee'];
    global.$IOType = ['', '只读', '只写', '读写'];
    global.$analogAcquisitionType = ['', '电流(0-20mA)', '电流(4-20mA)', '电压(0-5V)', '电压(0-10V)'];
    global.$groupTypes = ['', '片区', '站点'];
    global.$siteTypes = ['', 'M-A型', '集装箱'];
    global.$equipmentCategory = ['格栅', '水泵', '搅拌机', '化料器', '计量泵', '曝气风机', '除臭机', '分离器', '除污机', '沉砂器'];
    global.$gatewayModelType = [
      '直连设备',
      'DTU透传设备(modbus协议)',
      '云网关(modbus协议)',
      '昆仑kbox系列网关',
      '昆仑1200/HS系列网关',
      '直连设备(仅阿里云协议)',
    ];
    global.$gatewayModelTypeManualUrl = {
      直连设备: `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI('直连设备')}`,
      'DTU透传设备(modbus协议)': `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI(
        'DTU透传设备-modbus协议'
      )}`,
      '云网关(modbus协议)': `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI('云网关-modbus协议')}`,
      昆仑kbox系列网关: `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI('昆仑kbox系列网关')}`,
      '昆仑1200/HS系列网关': `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI('昆仑1200-HS系列网关')}`,
      '直连设备(仅阿里云协议)': `${document.location.origin}/docs/userManual/deviceAccess.html#${encodeURI(
        '直连设备-仅阿里云协议'
      )}`,
    };
    global.$warningReasonType = {
      IndicatorReasonType: 'IndicatorWarningReason',
      EquipmentReasonType: 'EquipmentWarningReason',
      EquipmentMalfunction: 'EquipmentMalfunction',
    };
    global.$warningRecordType = {
      IndicatorWarningRecord: 'IndicatorWarningRecord',
      EquipmentWarningRecord: 'EquipmentWarningRecord',
      EquipmentMalfunctionRecord: 'EquipmentMalfunctionRecord',
    };
    global.$warningDataType = {
      IndicatorType: 'IndicatorType',
      GatewayIndicatorType: 'GatewayIndicatorType',
      EquipmentType: 'EquipmentType',
      EquipmentIndicatorType: 'EquipmentIndicatorType',
      EquipmentGatewayIndicatorType: 'EquipmentGatewayIndicatorType',
    };
    global.$dowloadFileLogType = ['质保管理', '物联设备管理'];
    global.$warningHandleStatus = ['未处理', '已处理', '处理中'];
    global.$baudRate = ['', '2400', '4800', '9600', '14400', '19200', '38400', '56000', '57600', '115200'];
    global.$dataBit = ['', '5', '6', '7', '8'];
    global.$stopBit = ['', '1', '2'];
    global.$parityDigit = ['', '无校验', '奇校验', '偶校验'];
    global.$iotCardStatus = ['库存', '待激活', '可测试', '在用', '停机', '销户', '未知'];
    global.$iotCardStatusColor = {
      库存: '#A5AAA3',
      待激活: '#C280FF',
      可测试: '#EC808D',
      在用: '#1BBF98',
      停机: '#5B6281',
      销户: '#81D3F8',
      未知: '#facd91',
    };
    global.$functionCode = {
      只读0x01: '只读（0x01）',
      只读0x02: '只读（0x02）',
      只读0x03: '只读（0x03）',
      只读0x04: '只读（0x04）',
      只写0x05: '只写（0x05）',
      只写0x06: '只写（0x06）',
      读写0x01_0x05: '读写（0x01/0x05）',
      读写0x03_0x06: '读写（0x03/0x06）',
    };
    global.$outputSignalType = {
      电流输出_4_20mA: '电流输出:4-20mA',
      电压输出_0_5V: '电压输出:0V-5V',
      网络输出_RS485: '网络输出:RS485',
      网络输出_RS232: '网络输出:RS232',
    };
    global.$sensorModelType = {
      系统内置: '系统内置',
      项目自定义: '项目自定义',
    };
    global.$sortLabelOptions = [
      {
        key: 0,
        label: '传感器、变送器',
        value: '传感器、变送器',
      },
      {
        key: 1,
        label: '执行器、控制单元',
        value: '执行器、控制单元',
      },
      {
        key: 2,
        label: '测控仪表及控制器',
        value: '测控仪表及控制器',
      },
      {
        key: 3,
        label: '计量检测仪表',
        value: '计量检测仪表',
      },
      {
        key: 4,
        label: '数据采集与通讯设备',
        value: '数据采集与通讯设备',
      },
      {
        key: 5,
        label: '其他',
        value: '其他',
      },
    ];
    global.$wiringWay = {
      两线制接法: '两线制接法',
      三线制接法: '三线制接法',
      四线制接法: '四线制接法',
    };
    global.$dataType = ['', 'INT16', 'UINT16', 'INT32', 'UINT32', 'INT64', '浮点数32位', '浮点数64位', '位', '字符串'];
    global.$byteOrder = ['', 'ABCD', 'BADC', 'CDAB', 'DCBA'];
    global.$detailTitle = {
      view: '查看',
      update: '编辑',
      create: '新增',
      copy: '复制',
    };
    // 1站点，2监测点，3项目
    global.$CameraPlatformType = {萤石云: '萤石云', 乐橙云: '乐橙云', 物Link: '物Link', 'HTTP-FLV': 'HTTPFLV'};
    global.$indicatorCategory = {Internal: 'Internal', Custom: 'Custom'};
    global.$importFileRule = {
      RETAIN_AND_COVER: '保留已有数据，新增模板中的数据（重复数据覆盖）',
      RETAIN_AND_NOT_PROCESS: '保存已有数据，新增模板中的数据（重复数据不处理）',
      DELETE_AND_COVER: '删除已有数据，导入模板中的数据',
    };
    global.$dataReportingTime = {服务器时间: '服务器时间', 设备时间: '设备时间'};
    global.$exceptionTooltip = {
      WARNING_LEAD_TIME_STATUS: '上报数据的设备时间大于服务器时间',
      WARNING_LAG_TIME_STATUS: '上报数据的设备时间小于服务器时间10分钟',
      ERROR_LEAD_TIME_STATUS: '上报数据的设备时间大于服务器时间1小时以上',
      ERROR_LAG_TIME_STATUS: '上报数据的设备时间小于服务器时间48小时以上',
      ERROR_TIME_STATUS: '采集时间大于发送时间',
    };
    global.$maintainTaskType = {
      巡检类: 1,
      维修类: 2,
      药剂添加类: 4,
      保养类: 8,
    };
    global.$onlineStatus = {
      在线: ['ONLINE_STATUS'],
      离线: ['NORMAL_OFFLINE_STATUS', 'TIMEOUT_OFFLINE_STATUS'],
    };
    global.$exceptionStatus = {
      异常: ['WARNING_LEAD_TIME_STATUS', 'WARNING_LAG_TIME_STATUS'],
      错误: ['ERROR_LEAD_TIME_STATUS', 'ERROR_LAG_TIME_STATUS', 'ERROR_TIME_STATUS'],
    };
    global.$projectPayStatus = {
      A: '正常',
      B: '即将欠费',
      C: '已经欠费',
      D: '欠费停用',
      E: '断卡停用',
      F: '合同终止',
    };
    global.$NO_MOBILE_USER = 2; // 内置用户
    global.$NEEDAUDIT = 1; // 待审核
    global.$TOBEALLOCATED = 2; // 待分配
    global.$TOBEREASSIGNED = 3; // 待重新分配
    global.$UNCLAIMED = 4; // 待领取
    global.$UNCLAIMEDCANCLE = 5; // 待领取申请撤销
    global.$TOBEIMPLEMENTED = 6; // 待执行
    global.$TOBEIMPLEMENTEDCANCE = 7; // 待执行申请撤销
    global.$UNPASSED = 8; // 审核不通过
    global.$REJECT = 9; // 审核驳回
    global.$EXECUTION = 10; // 执行中
    global.$CLOSED = 11; // 已完结
    global.$PROCESSEDBUTNOTRESOLVED = 12; // 已处理未解决
    global.$OVERDUE = 13; // 超期未处理
    global.$SlidingTypeEnum = ['YEAR', 'MONTH', 'WEEK', 'DAY', 'HOUR', 'MINUTE', 'SECOND', '', 'QUARTER']; // iot聚合单位类型
    global.$WeixinPlatform = {
      公众号: 0,
      网页: 1,
      小程序: 2,
    };
    global.$QRcodeType = {
      生产设备信息: 0,
      设备分享: 1,
      监测点: 2,
    };
    global.$displayType = {
      无: '无',
      显示在列表: '显示在列表',
      显示在查询条件: '显示在查询条件',
      显示在列表和查询条件: '显示在列表和查询条件',
    };
    global.$timeSetType = {
      DATA_CREATE: '创建时间',
      MANUAL_ENTRY_TO_SECOND: '手动录入年月日时分秒',
      MANUAL_ENTRY_TO_MINUTE: '手动录入年月日时分',
      MANUAL_ENTRY_TO_HOUR: '手动录入年月日时',
      MANUAL_ENTRY_TO_DAY: '手动录入年月日',
    };
    global.$EXPORT_MAX_PAGE_SIZE = 1000; // 分页导出时最大条数
    global.$menuTypeNameMap = {
      1: '页面',
      2: '页签',
      3: '主页签',
    };
    global.$customFormValueColumnMode = {
      VALUE_OR_UNIT: 'valueOrUnit',
      VALUE: 'value',
    };
    global.$equipmentCategorySource = {
      系统内置: '系统内置',
      项目自定义: '项目自定义',
    };
    global.$equipmentModelSource = {
      系统内置: '系统内置',
      项目自定义: '项目自定义',
    };
    global.$effectiveStatus = {
      已失效: '已失效',
      生效中: '生效中',
    };
    global.$medicineType = {
      PAC: 'PAC',
      反洗次氯酸钠: '反洗次氯酸钠',
      消毒次氯酸钠: '消毒次氯酸钠',
      葡萄糖: '葡萄糖',
      柠檬酸: '柠檬酸',
    };
    global.$messageSceneAndRouterMap = {
      IOTCARD_WARNING: 'router-iotCardWarningRecord',
      NUMERICAL_VALUE_WARNING: 'router-warningList',
      NUMERICAL_VALUE_CANCLE_WARNING: 'router-warningList',
      EQUIPMENT_MAINTENANCE_WARNING: 'router-equipmentMaintenanceWarning',
      EQUIPMENT_MAINTENANCE_CANCLE_WARNING: 'router-equipmentMaintenanceWarning',
      EQUIPMENT_MALFUNCTION_WARNING: 'router-equipmentMalfunctionRecord',
      EQUIPMENT_MALFUNCTION_CANCLE_WARNING: 'router-equipmentMalfunctionRecord',
      ARTIFICIAL_TASK_NEED_AUDIT: 'router-maintenanceTaskList',
      CANCLE_TASK_APPLY_PASS: 'router-maintenanceTaskList',
      TASK_BE_REJECTED: 'router-maintenanceTaskList',
      TASK_OVERDUE: 'router-maintenanceTaskList',
      TASK_NEED_AUDIT: 'router-maintenanceTaskList',
      TASK_NEED_RECEIVE: 'router-maintenanceTaskList',
      TASK_CANCLE_APPLY: 'router-maintenanceTaskList',
      TASK_NEED_REDISTRIBUTE: 'router-maintenanceTaskList',
      TOOL_LACK_STOCK_REMINDER: 'router-ToolWarehousing',
      CONSUMABLE_LACK_STOCK_REMINDER: 'router-ConsumableWarehousing',
      EXPIRED_STOCK_REMINDER: 'router-ConsumableWarehousing',
      OUT_WAREHOUSE_NEED_AUDIT: 'router-EXWarehousing',
      OUT_WAREHOUSE_BE_REJECTED: 'router-EXWarehousing',
      DATA_REPAIR_NEED_AUDIT: 'router-site-dataRepair',
      DATA_REPAIR_BE_REJECTED: 'router-site-dataRepair',
      CAPTURE_PIC_REMINDER: 'router-project-capturePic',
      RESOURCES_INSUFFICIENT: 'router-projectOverview',
    };
    global.$locateTypeOptions = [
      {
        label: '基站定位',
        value: 0,
      },
      {
        label: '卫星定位',
        value: 1,
      },
    ];
    global.$coordinateOptions = [
      {
        label: 'GCJ02',
        value: 0,
      },
      {
        label: 'WGS84',
        value: 1,
      },
      {
        label: 'BD09',
        value: 2,
      },
    ];
    global.$locateByReportDataTypes = [[1], [2, 3, 4]];
    app.directive('autoClick', {
      mounted(element, binding) {
        if (binding.value) {
          element.click();
        }
      },
    });
    app.directive('permission', {
      mounted(el, binding, vnode) {
        // 当前指令的值为undefined or true时做校验
        if (binding.value == undefined || binding.value) {
          const perssionMap = {
            add: global.$CREATE,
            remove: global.$DELETE,
            change: global.$UPDATE,
            read: global.$READ,
          };
          const curPerValue = perssionMap[binding.arg] ? perssionMap[binding.arg] : 0;
          const roles = store.getters.roles;
          const permissionValue = router.currentRoute._value.meta.permission;
          if ((curPerValue & permissionValue) == 0) {
            (el && el.remove()) || (el.style.display = 'none');
          }
        }
      },
    });

    app.mixin({
      methods: {
        calcHeightFromContent,
        calcHeightFromView,
        dayjs,
        getWebsocketProtocal,
        subscribeTopic,
        closeStompWebSocket,
        getStompClient,
        cacheQueryCondition,
        getQueryConditionCache,
        getMessagesCard,
        endSubmit,
        confirmDelete,
        hasPermission,
        hasFunctionPermission,
        loginOut,
        getRealRouteName,
        routePush,
        hasRoute,
        getRouteName,
        openRtIndicatorsDataWebsocket,
        openOriginalRtIndicatorsDataWebsocket,
        openOriginalRtIndicatorsDataNotPermissionsWs,
        getGroupTreeData,
        getHasPermissionGroupTreeData,
        dataToTreeData,
        openIndicatorsDataAnyGatewanySocket,
        subscribeGateways,
        unsubscribeGateways,
        closeWebsocket,
        addTemplateHeadersAndDownload,
        exportExcelFile,
        importFileBeforeUpload,
        importJsonBeforeUpload,
        customRequestBeforeUpload,
        handleFileContent,
        export2Excel,
        export2ExcelMany,
        exportJsonFile,
        exportTxtFile,
        exportExcelFileMany,
        currentGroupId,
        checkFull,
        getGroupTreeOption,
        verifyAndGetData,
        getRouterName,
        getDefaultRouterOption,
        getLastIotDatasWithMap,
        getLastMetricsIotDatasWithMap,
        refreshSubscribeGateways,
        getIotDatasByWebsocket,
        currentProjectName,
        getHistoryIotDatasWithMap,
        getIndicatorValueText,
        debounceFunc,
        isProject,
        getGroupDisPlayNameFormGroup,
        getGroupDisPlayNameFormGroupAndMonitor,
        getGroupDisPlayNameFormSite,
        isJSON,
        getQrCodeImg,
        isNearScrollBottom,
        isHasSubMenuGroup,
        getBlob,
        getPermission,
        isNumberByIndicatorType,
        isEnumByIndicatorType,
        indicatorEnumsToOptions,
        formatIndicatorEnumDisplayStr,
        getSetMealByQuantity,
      },
    });
  },
};
