import {login, logout, refreshToken, checkToken} from '@/api/login';
import {getMenu} from '@/utils/menu';
import {iotUserInfo, iotGetMenu} from '@/api/iot';
import {getRealname, getLoginInfo, getPermission, getRoles} from '@/api/user';
import router, {addRoutes} from '@/router';
import {parsePayload} from '@/utils/jwtUtil';
import {base64encode} from '@/utils/base64Util';

const user = {
  state: {
    token: '',
    exp: undefined,
    name: '',
    username: '',
    userId: '',
    avatar: '',
    // TODO 缓存menu的意义不大
    // 单纯的使用被getMenu处理过的值，无法在刷新页面后重新使用，因为动态页面需要被import
    // getMenu里import过了，对于刷新的页面，已经失效了，会导致无法正常显示页面。
    // 另外，刷新页面后，路由有可能不同，所以，直接重新获取路由，重新生成就好，没必要缓存
    // 对于某些地方在使用缓存的menu，应该可以用router.getRoutes替代，而且这个缓存占用了大量的空间
    menu: [],
    frontMenu: [],
    firmId: -1,
    // TODO 如果所有的路径参数中，都已经存在projectId，这个应该可以移除
    groupId: -1,
    projectGroupId: -1,
    realname: '',
    refreshToken: '',
    groupMenu: [],
    isShowMessage: true,
    isMuteMessageSound: false,
    otherMessageOperate: 1,
    warningMessageOperate: 1,
    theme: 'tech',
    lastRoute: undefined,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      if (token && token != '') {
        const data = parsePayload(token);
        state.userId = data.userId;
        state.name = data.realname;
        state.avatar = data.avatar;
        state.username = data.username;
        state.exp = data.exp;
      } else {
        state.userId = '';
        state.name = '';
        state.avatar = '';
        state.username = '';
        state.exp = undefined;
      }
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_MENU: (state, menu) => {
      state.menu = menu;
    },
    SET_FRONT_MENU: (state, frontMenu) => {
      state.frontMenu = frontMenu;
    },
    SET_FIRMID: (state, firmId) => {
      state.firmId = firmId;
    },
    SET_GROUPID: (state, groupId) => {
      state.groupId = groupId;
    },
    SET_REALNAME: (state, realname) => {
      state.realname = realname;
    },
    SET_REFRESHTOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_GROUP_MENU: (state, groupMenu) => {
      state.groupMenu = groupMenu;
    },
    SET_PROJECT_GROUP_ID: (state, projectGroupId) => {
      state.projectGroupId = projectGroupId;
    },
    SET_PROJECTTYPE_ID: (state, projectTypeId) => {
      state.projectTypeId = projectTypeId;
    },
    SET_GROUPDISPLAYNAME: (state, groupDisPlayName) => {
      state.groupDisPlayName = groupDisPlayName;
    },
    SET_IS_SHOW_MESSAGE: (state, isShowMessage) => {
      state.isShowMessage = isShowMessage;
    },
    SET_IS_MUTE_MESSAGE_SOUND: (state, isMuteMessageSound) => {
      state.isMuteMessageSound = isMuteMessageSound;
    },
    SET_OTHER_MESSAGE_OPERATE: (state, otherMessageOperate) => {
      state.otherMessageOperate = otherMessageOperate;
    },
    SET_WARNING_MESSAGE_OPERATE: (state, warningMessageOperate) => {
      state.warningMessageOperate = warningMessageOperate;
    },
    SET_THEME: (state, theme) => {
      state.theme = theme;
    },
    SET_LAST_ROUTE: (state, lastRoute) => {
      state.lastRoute = lastRoute;
    },
  },

  actions: {
    async fetchLoginInfo({commit}) {
      const res = await getLoginInfo();
      commit('SET_REALNAME', res.data.realname);
      if (res.data.theme) commit('SET_THEME', res.data.theme);
      const menu = getMenu(res.data.frontMenus);
      commit('SET_FRONT_MENU', res.data.frontMenus);
      commit('SET_MENU', menu);
      commit('SET_IS_SHOW_MESSAGE', res.data.messageSetting.isShowMessage);
      commit('SET_IS_MUTE_MESSAGE_SOUND', res.data.messageSetting.isMuteMessageSound);
      commit('SET_OTHER_MESSAGE_OPERATE', res.data.messageSetting.otherMessageOperate);
      commit('SET_WARNING_MESSAGE_OPERATE', res.data.messageSetting.warningMessageOperate);
      addRoutes(menu);
    },
    // 登录
    Login({dispatch, commit}, option) {
      return new Promise((resolve, reject) => {
        login(option.type, option)
          .then((response) => {
            window.clearVuexAlong();
            const data = response;
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESHTOKEN', data.refresh_token);
            dispatch('fetchLoginInfo').then(() => {
              resolve(data);
            });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 刷新token，重新获取登录信息
    RefreshLogin({dispatch, commit}, option) {
      return new Promise((resolve, reject) => {
        if (!this.getters.refreshToken) {
          reject('refreshToken不存在');
        }
        refreshToken(this.getters.refreshToken)
          .then((response) => {
            window.clearVuexAlong();
            const data = response;
            commit('SET_TOKEN', data.access_token);
            commit('SET_REFRESHTOKEN', data.refresh_token);
            dispatch('fetchLoginInfo').then(() => {
              resolve(data);
            });
          })
          .catch((error) => {
            commit('SET_TOKEN', '');
            commit('SET_REFRESHTOKEN', '');
            commit('SET_MENU', []);
            commit('SET_FRONT_MENU', []);
            window.clearVuexAlong();
            reject(error);
          });
      });
    },
    // 校验token
    CheckToken({dispatch, commit}, token) {
      return new Promise((resolve, reject) => {
        checkToken(token)
          .then((response) => {
            window.clearVuexAlong();
            const data = response;
            resolve(data.active);
          })
          .catch((error) => {
            console.log('token无效!');
            resolve(false);
          });
      });
    },
    GoIndex({dispatch, commit}, path) {
      commit('SET_TOKEN', '');
      commit('SET_REFRESHTOKEN', '');
      commit('SET_MENU', []);
      commit('SET_FRONT_MENU', []);
      window.clearVuexAlong();
      window.location.href = process.env.LOGIN_URL + '?path=' + base64encode(path);
      NProgress.done();
    },
    Go401({commit}) {
      commit('SET_TOKEN', '');
      commit('SET_REFRESHTOKEN', '');
      commit('SET_MENU', []);
      commit('SET_FRONT_MENU', []);
      window.clearVuexAlong();
      window.location.href = window.location.origin + '/401';
      NProgress.done();
    },
    Go404({commit}) {
      window.location.href = window.location.origin + '/404';
      NProgress.done();
    },
    GenerateRoutes({commit}, option) {
      return new Promise((resolve, reject) => {
        let checkFunc;
        if (option && option.checkFunc) {
          checkFunc = option.checkFunc;
          delete option.checkFunc;
        }
        iotGetMenu(option)
          .then((response) => {
            if (!response) {
              reject('error');
            }
            const data = response.data;
            if (option && option.checkHas) {
              const map = {
                firm: 2,
                project: 4,
                group: 4,
                system: 3,
                corporation: 10,
              };
              if (!data[map[option.type]]) {
                if (option.type == 'project') {
                  if (!data[map['group']]) {
                    resolve(false);
                    return;
                  }
                } else {
                  resolve(false);
                  return;
                }
              }
            }
            if (checkFunc && !checkFunc(data)) {
              resolve(false);
              return;
            }
            const menu = getMenu(data, option);
            if (menu && menu.length > 0) {
              commit('SET_FRONT_MENU', data);
              commit('SET_MENU', menu);
              addRoutes(menu);
            }
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 登出
    async LogOut({commit}) {
      const token = this.getters.token;
      if (token && token != '') {
        try {
          const res = await logout(token);
          if (res.code != global.$SUCCESS_CODE) {
            console.error('logout error', res);
          }
        } catch (error) {
          console.error(error);
        }
      }
      commit('SET_TOKEN', '');
      commit('SET_REFRESHTOKEN', '');
      commit('SET_MENU', []);
      commit('SET_FRONT_MENU', []);
      commit('SET_LAST_ROUTE');
      window.clearVuexAlong();
    },
  },
};

export default user;
