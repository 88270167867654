import request from '@/utils/request';
import Qs from 'qs';
import store from '../store';
import axios from 'axios';
import {method} from 'lodash';

export function iotUserInfo(data) {
  return request({
    url: '/user/frontMenu/userInfo',
    method: 'get',
    params: data,
  });
}

export function iotGetMenu(data) {
  return request({
    url: '/iot/system/frontMenus',
    method: 'get',
    params: data,
  });
}

export function iotGetLastIndicators(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/metrics/last?isCleanData=true',
  });
}

export function iotGetOriginalLastIndicators(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/metrics/last?isCleanData=false',
  });
}

export function iotGetOriginalNotPermissionsLast(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/metrics/notPermissionsLast?isCleanData=false',
  });
}

export function iotGetIndicatorsColumns(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/columns?isCleanData=true',
  });
}

export function iotGetOriginalIndicatorsColumns(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/columns?isCleanData=false',
  });
}

export function iotGetOriginalNotPermissionsColumns(gatewayId) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/notPermissionsColumns?isCleanData=false',
  });
}

export function iotSetIndicators(
  groupId,
  gatewayId,
  gatewayIndicatorId,
  identificationValue,
  writeOperationType,
  identificationName,
  indicatorId
) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/setIndicators/' + gatewayId,
    method: 'post',
    params: {
      identificationValue: identificationValue,
      gatewayIndicatorId: gatewayIndicatorId,
      writeOperationType: writeOperationType,
      identificationName: identificationName,
      indicatorId: indicatorId,
    },
  });
}

export function iotSetIndicatorsByRemoteDeployment(groupId, gatewayId, gatewayIndicatorId, identificationValue, type) {
  return request({
    url: '/iot/groups/' + groupId + '/remoteDeployment/' + gatewayId,
    method: 'post',
    params: {
      identificationValue: identificationValue,
      gatewayIndicatorId: gatewayIndicatorId,
      writeOperationType: type,
    },
  });
}

export function clearZero(groupId, gatewayId, gatewayIndicatorId, identificationValue, type) {
  return request({
    url: '/iot/groups/' + groupId + '/clearZero/' + gatewayId,
    method: 'post',
    params: {
      identificationValue: identificationValue,
      gatewayIndicatorId: gatewayIndicatorId,
      writeOperationType: type,
    },
  });
}

export function setMode(groupId, sourceModeId, targetModeId) {
  return request({
    url: '/iot/groups/' + groupId + '/setMode',
    method: 'post',
    params: {
      sourceModeId: sourceModeId,
      targetModeId: targetModeId,
    },
  });
}

export function getCustomFormConfigByRealFunctionId(groupId, realFunctionId) {
  return request({
    url: '/iot/groups/' + groupId + '/getCustomFormConfigByRealFunctionId',
    method: 'get',
    params: {
      realFunctionId,
    },
  });
}

export function iotGetInstructionRecord({gatewayId, startTime, stopTime, metrics, page, perPage, ascending, fetchOneMore = false}) {
  return request({
    method: 'get',
    url: '/data-server/api/v2/gateways/' + gatewayId + '/instructionRecord',
    params: {
      startTime: startTime,
      stopTime: stopTime,
      metrics: metrics,
      page: page,
      perPage: perPage,
      ascending: ascending,
      fetchOneMore: fetchOneMore,
    },
  });
}

export function iotGetMultiLastIndicators(gatewayIds) {
  return request({
    type: 'get',
    url: `/data-server/api/v2/gateways/metrics/last?isCleanData=true`,
    params: {
      gatewayIds: gatewayIds.join(','),
    },
  });
}

export function iotGetLastIndicatorsBefore(gatewayId, metrics, time) {
  return request({
    type: 'get',
    url: `/data-server/api/v2/gateways/${gatewayId}/metrics/last_value?isCleanData=true&time=${time}&metrics=${encodeURIComponent(
      JSON.stringify(metrics)
    )}`,
  });
}

export function mqttComputationData(data) {
  return request({
    method: 'post',
    url: `/data-server/computation/getData`,
    data: data,
  });
}

export function mqttComputationCheckData(data) {
  return request({
    method: 'post',
    url: `/data-server/computation/checkExpression`,
    data: data,
  });
}

const getMetricsParameter = function (metrics) {
  return encodeURIComponent(JSON.stringify(metrics));
};

export function getMetricsMonitorByAnyGateway({
  gatewayIds,
  metrics,
  startTime,
  stopTime,
  type,
  chronoUnit,
  page,
  perPage,
  fetchOneMore = false,
}) {
  let timeParam = '';
  if (startTime != undefined) {
    timeParam += '&startTime=' + startTime;
  }
  if (stopTime != undefined) {
    timeParam += '&stopTime=' + stopTime;
  }
  if (chronoUnit != undefined) {
    timeParam += '&chronoUnit=' + chronoUnit;
  }
  return axios({
    url:
      '/data-server/api/v2/gateways/metrics/sliding_statistics?isCleanData=true&type=' +
      type +
      '&metrics=' +
      getMetricsParameter(metrics) +
      timeParam +
      '&gatewayIds=' +
      gatewayIds +
      '&page=' +
      page +
      '&perPage=' +
      perPage +
      '&fetchOneMore=' +
      fetchOneMore,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getStatisticsByGroupType(data, startTime, stopTime) {
  let timeParam = '';
  if (startTime != undefined) {
    timeParam += 'startTime=' + startTime;
  }
  if (stopTime != undefined) {
    timeParam += startTime != undefined ? '&stopTime=' + stopTime : 'stopTime=' + stopTime;
  }
  return axios({
    url: '/data-server/api/v2/gateways/metrics/statisticsByGroupType?' + timeParam,
    method: 'get',
    data: data,
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getMetricsMonitorByGateway(
  gatewayId,
  metrics,
  startTime,
  stopTime,
  type,
  chronoUnit,
  page,
  perPage,
  fetchOneMore = false
) {
  let lastUrl = '';
  let timeParam = '';
  if (startTime != undefined) {
    timeParam += '&startTime=' + startTime;
  }
  if (stopTime != undefined) {
    timeParam += '&stopTime=' + stopTime;
  }
  if (page != undefined && perPage != undefined) {
    lastUrl = '&page=' + page + '&perPage=' + perPage + '&fetchOneMore=' + fetchOneMore;
  }
  if (chronoUnit != undefined) {
    lastUrl = '&chronoUnit=' + chronoUnit + lastUrl;
  }
  return axios({
    url:
      '/data-server/api/v2/gateways/' +
      gatewayId +
      '/metrics/sliding_statistics?isCleanData=true&type=' +
      type +
      '&metrics=' +
      getMetricsParameter(metrics) +
      timeParam +
      lastUrl,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getMetricsByAnyGateway({gatewayIds, metrics, startTime, stopTime, page, perPage, ascending, fetchOneMore = false}) {
  let timeParam = '';
  if (startTime != undefined) {
    timeParam += '&startTime=' + startTime;
  }
  if (stopTime != undefined) {
    timeParam += '&stopTime=' + stopTime;
  }

  return axios({
    url:
      '/data-server/api/v2/gateways/metrics?isCleanData=true&metrics=' +
      getMetricsParameter(metrics) +
      timeParam +
      '&page=' +
      page +
      '&perPage=' +
      perPage +
      '&gatewayIds=' +
      gatewayIds +
      '&ascending=' +
      ascending +
      '&fetchOneMore=' +
      fetchOneMore,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getOriginalMetricsByAnyGateway({
  gatewayIds,
  metrics,
  startTime,
  stopTime,
  page,
  perPage,
  ascending,
  fetchOneMore = false,
}) {
  let timeParam = '';
  if (startTime != undefined) {
    timeParam += '&startTime=' + startTime;
  }
  if (stopTime != undefined) {
    timeParam += '&stopTime=' + stopTime;
  }
  return axios({
    url:
      '/data-server/api/v2/gateways/metrics?isCleanData=false&metrics=' +
      getMetricsParameter(metrics) +
      timeParam +
      '&page=' +
      page +
      '&perPage=' +
      perPage +
      '&gatewayIds=' +
      gatewayIds +
      '&ascending=' +
      ascending +
      '&fetchOneMore=' +
      fetchOneMore,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getLastMetricsByAnyGateway(gatewayIds) {
  return axios({
    url: '/data-server/api/v2/gateways/metrics/last?isCleanData=true&gatewayIds=' + gatewayIds,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getOriginalLastestMetricsByAnyGateway(gatewayIds) {
  return axios({
    url: '/data-server/api/v2/gateways/metrics/last?isCleanData=false&gatewayIds=' + gatewayIds,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

// 获取单个网关最新数据
export function getLastValueByGatewayAndMetrics(gatewayId, metrics) {
  return axios({
    url: '/data-server/api/v2/gateways/' + gatewayId + '/metrics/last?metrics=' + getMetricsParameter(metrics),
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getLastMetricsByAnyGatewayAndIndicators(gatewayIds, indicators, time) {
  let url = '/data-server/api/v2/gateways/metrics/last?isCleanData=true&gatewayIds=' + gatewayIds + '&metrics=' + indicators;
  if (time !== undefined) {
    url = url + '&time=' + time;
  }
  return axios({
    url: url,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getOriginalLastMetricsByAnyGateway(gatewayIds, indicators) {
  return axios({
    url: '/data-server/api/v2/gateways/metrics/last?isCleanData=false&gatewayIds=' + gatewayIds + '&metrics=' + indicators,
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}

export function getLastMetricsByGatewayId(gatewayId) {
  return axios({
    url: '/data-server/api/v2/gateways/' + gatewayId + '/metrics/last?isCleanData=true&',
    method: 'get',
    headers: {
      Authorization: 'Bearer ' + store.getters.token,
    },
  });
}
