import request from '@/utils/request';
import md5 from 'js-md5';
import qs from 'qs';
import axios from 'axios';
import requestLogin from '@/utils/requestLogin';
import {base64encode} from '@/utils/base64Util'; // 验权

export function login(type, data) {
  if (type == 'client_credentials') {
    return requestLogin({
      url: '/iot/oauth/token',
      method: 'post',
      data: qs.stringify({
        grant_type: 'client_credentials',
        client_id: data.clientId,
        client_secret: data.clientSecret,
      }),
    });
  }
  if (type == 'password') {
    return requestLogin({
      url: '/iot/oauth/token',
      headers: {
        Authorization: 'Basic ' + base64encode('iot-front:XoAYKEAn56xUgcch1Uci'),
      },
      method: 'post',
      data: qs.stringify({
        username: data.username,
        password: data.password,
        grant_type: 'password',
      }),
    });
  }
  if (type == 'sms') {
    return requestLogin({
      url: '/iot/oauth/token',
      headers: {
        // "Authorization":'Basic ' + base64encode("first:XoAYKEAn56xUgcch1Uci"),
        Authorization: 'Basic ' + base64encode('iot-front:XoAYKEAn56xUgcch1Uci'),
      },
      method: 'post',
      data: qs.stringify({
        grant_type: 'sms',
        username: data.username,
        smscode: data.smscode,
        realname: data.realname,
        company: data.company,
        province: data.province,
        city: data.city,
        district: data.district,
        currentUserApplication: data.currentUserApplication,
      }),
    });
  }

  if (type == 'weixin') {
    return requestLogin({
      url: '/iot/oauth/token',
      headers: {
        Authorization: 'Basic ' + base64encode('iot-front:XoAYKEAn56xUgcch1Uci'),
      },
      method: 'post',
      data: qs.stringify({
        unionid: data.unionid,
        grant_type: 'weixin',
      }),
    });
  }

  if (type == 'dingding') {
    return requestLogin({
      url: '/iot/oauth/token',
      headers: {
        Authorization: 'Basic ' + base64encode('iot-front:XoAYKEAn56xUgcch1Uci'),
      },
      method: 'post',
      data: qs.stringify({
        openId: data.openId,
        grant_type: 'dingding',
      }),
    });
  }
}

export function logout(token) {
  return request({
    url: '/iot/exit',
    method: 'post',
    params: {accessToken: token},
  });
}

export function refreshToken(refreshToken) {
  return requestLogin({
    url: '/iot/oauth/token',
    method: 'post',
    headers: {
      Authorization: 'Basic ' + base64encode('iot-front:XoAYKEAn56xUgcch1Uci'),
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: qs.stringify({
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    }),
  });
}

export function checkToken(token) {
  return requestLogin({
    url: '/iot/oauth/check_token',
    method: 'post',
    params: {token},
  });
}
