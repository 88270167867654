<template>
  <a-modal
    :visible="visible"
    :confirm-loading="confirmLoading"
    :mask-closable="false"
    :title="'使用分享码'"
    ok-text="保存"
    wrap-class-name="full-modal"
    :ok-button-props="{disabled: readonly}"
    width="100%"
    @cancel="visible = false"
    @ok="submitForm"
  >
    <a-form ref="form" layout="vertical" :model="form">
      <a-form-item :name="['group', 'id']" label="加入至项目" :rules="groupRule">
        <a-tree-select
          v-model:value="form.group.id"
          show-search
          tree-node-filter-prop="title"
          :dropdown-style="{maxHeight: '400px', overflow: 'auto'}"
          :tree-data="groupList"
          tree-default-expand-all
          :disabled="readonly"
          @change="selectData"
        >
          <template #title="{title, isMonitor}">
            <b v-if="isMonitor" style="color: var(--high-light-color)">{{ title }}</b>
            <template v-else>{{ title }}</template>
          </template>
        </a-tree-select>
      </a-form-item>
      <a-form-item name="code" label="设备分享码" :rules="rules">
        <a-input v-model:value="form.code"></a-input>
      </a-form-item>
      <a-form-item label="分享的设备" style="margin-bottom: 0">
        <a-row :gutter="24">
          <a-col span="12">
            <a-table :data-source="gatewayDataSource" :columns="gatewayColumns" :pagination="false" bordered></a-table>
          </a-col>
          <a-col span="12">
            <a-form-item name="equipment" :rules="equipmentRules">
              <a-table :data-source="cameraDataSource" :columns="cameraColumns" :pagination="false" bordered>
                <template #bodyCell="{column, record}">
                  <template v-if="column.dataIndex == 'selectGroup'">
                    <a-tree-select
                      v-model:value="record.selectGroupId"
                      show-search
                      tree-node-filter-prop="title"
                      :dropdown-style="{maxHeight: '400px', overflow: 'auto'}"
                      :tree-data="treeData"
                      tree-default-expand-all
                      :disabled="readonly"
                    >
                      <template #title="{title, isMonitor}">
                        <b v-if="isMonitor" style="color: var(--high-light-color)">{{ title }}</b>
                        <template v-else>{{ title }}</template>
                      </template>
                    </a-tree-select>
                  </template>
                </template>
              </a-table>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>
    <BuyResourceModal
      v-if="showBuyResource"
      :after-close="
        () => {
          showBuyResource = false;
        }
      "
      :target-id="form.group.id"
    >
      <template #content>
        操作失败！当前分享所需监测项资源超出分享的项目的剩余接入资源<span style="color: red">{{ overflowNum }}</span
        >个，请为分享项目
        <a-button type="link" style="padding: 0px 0px">【购买】</a-button>
        资源
      </template>
    </BuyResourceModal>
  </a-modal>
</template>

<script>
import {useShare, getShareByCode, canShare} from '@/api/share';
import {getProjectAndGroupByUser} from '@/api/project';
import {isSerialNumberExist} from '@/api/gateway';
import BuyResourceModal from '@/components/buyResourceModal';
export default {
  name: 'UseShareDetail',
  props: {
    detailId: {type: Number, default: undefined, required: false},
    title: String,
    readonly: Boolean,
  },
  components: {
    BuyResourceModal,
  },
  computed: {
    equipmentRules() {
      return [
        {
          validator: (rule, value) => {
            const hasUnselected = this.cameraDataSource.some((record) => !record.selectGroupId);
            return hasUnselected ? Promise.reject('请选择摄像头所在分组！') : Promise.resolve();
          },
          trigger: 'submit',
        },
      ];
    },
  },
  data() {
    return {
      cameraTargetGroupId: undefined,
      treeData: [],
      selectGroupList: [],
      gatewayDataSource: [],
      gatewayColumns: [
        {
          title: '物联设备',
          dataIndex: 'alias',
          key: 'alias',
        },
      ],
      cameraDataSource: [],
      cameraColumns: [
        {
          title: '摄像头',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '添加至分组/监测点',
          dataIndex: 'selectGroup',
          key: 'selectGroup',
        },
      ],
      groupRule: [
        {
          required: true,
          message: '项目不能为空！',
          trigger: 'blur',
        },
        {
          validator: (rule, value) => {
            return value != '' && value != undefined ? Promise.resolve() : Promise.reject();
          },
          trigger: 'blur',
        },
      ],
      showBuyResource: false,
      visible: true,
      confirmLoading: false,
      code: undefined,
      overflowNum: 0,
      codeList: [],
      form: {
        gateways: [],
        cameras: [],
        group: {
          id: undefined,
        },
        code: undefined,
      },
      groupList: [],
      sharedGroupId: undefined,
      shareId: undefined,
      rules: [
        {
          required: true,
          message: '分享码不能为空！',
          trigger: 'blur',
        },
        {
          type: 'string',
          max: 36,
          message: '最多输入36个字符！',
          trigger: 'blur',
        },
        {
          validator: (rule, value) => {
            return value != '' && value != undefined
              ? getShareByCode(this.form.code).then((response) => {
                  if (response.code == this.$SUCCESS_CODE) {
                    // 保存 selectGroup 的值
                    const savedSelectGroups = this.cameraDataSource.map((item) => item.selectGroupId);

                    this.gatewayDataSource = response.data.gateways;
                    this.cameraDataSource = response.data.cameras;

                    // 恢复 selectGroup 的值
                    for (let i = 0; i < this.cameraDataSource.length; i++) {
                      this.cameraDataSource[i].selectGroupId = savedSelectGroups[i] || undefined;
                    }

                    this.shareId = response.data.id;

                    if (response.data.group.id == this.form.group.id) {
                      // eslint-disable-next-line no-throw-literal
                      throw '分享码上的设备源于所选项目，请修改！';
                    }
                  } else {
                    this.gatewayDataSource = [];
                    this.cameraDataSource = [];
                    this.shareId = undefined;
                  }
                  return response.code != this.$SUCCESS_CODE ? Promise.reject(response.message) : Promise.resolve();
                })
              : Promise.resolve();
          },
          trigger: 'blur',
        },
      ],
    };
  },
  created() {
    this.getProjectList();
  },
  methods: {
    selectData() {
      this.getGroupTreeOption(false, this.form.group.id, false, true).then((response) => {
        this.treeData = response;
        console.log(this.treeData);
      });
    },
    submitForm() {
      this.$refs.form.validate().then(() => {
        canShare(this.shareId, this.form.group.id).then((res) => {
          if (res.code == this.$SUCCESS_CODE) {
            if (res.data >= 0) {
              useShare(this.shareId, this.form.group.id, this.cameraDataSource).then((response) => {
                if (response.code == this.$SUCCESS_CODE) {
                  this.$notification.success({
                    title: '创建成功',
                    message: response.message,
                  });
                  this.endSubmit(true);
                }
              });
            } else {
              this.overflowNum = -res.data;
              this.showBuyResource = true;
            }
          } else {
            this.$notification.error({
              message: res.message,
            });
          }
        });
      });
    },
    getProjectList() {
      getProjectAndGroupByUser().then((response) => {
        if (response.code == this.$SUCCESS_CODE) {
          for (const item of response.data) {
            this.groupList.push({
              title: item.name,
              key: item.id,
              value: item.projectGroup.id,
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-card-body {
  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 0px;
  height: 415px;
  overflow-y: auto;
}
/deep/ .extraTitle {
  font-weight: 600;
  font-size: 16px;
}
.selected-class {
  color: #a11c32;
}
.singe-line {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  text-align: left;
}
</style>
