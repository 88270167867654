<template>
  <div :style="style">
    <a-tree :height="height" v-bind="$attrs">
      <!-- 循环注册 父级传递下来的slot -->
      <template v-for="(value, key) in $slots" #[key]="slotProps" :key="key">
        <slot :name="key" v-bind="slotProps || {}"></slot>
      </template>
    </a-tree>
  </div>
</template>

<script>
import {ref, nextTick} from 'vue';

// autoHeightTree 自动计算高度的树
export default {
  inheritAttrs: false,
  props: {
    style: [Object, String],
  },
  data: function () {
    return {
      height: 100,
    };
  },
  mounted() {
    this.updateTreeHeight();
    this._resizeFunc = _.debounce(this.updateTreeHeight, 16);
    window.addEventListener('resize', this._resizeFunc);
  },
  Unmounted() {
    window.removeEventListener('resize', this._resizeFunc);
  },
  methods: {
    updateTreeHeight() {
      // this.$el.getBoundingClientRect 获取的是组件的渲染高度，在此组件初次渲染的时候可能会获取到错误的高度
      this.height = this.$el.offsetHeight;
    },
  },
};
</script>
