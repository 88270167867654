/**
 * Created by jiachenpan on 16/11/18.
 */
import {checkAppName} from '@/api/app';
import {checkPhoneCode, checkImageCode, isUserExist} from '@/api/user';

export function isValidUsername(string_) {
  const validMap = ['admin', 'editor'];
  return validMap.includes(string_.trim());
}

/* 合法uri*/
export function validateURL(textval) {
  const urlregex =
    /^(https?|ftp):\/\/([\d.A-Za-z-]+(:[\d$%&.A-Za-z-]+)*@)*((25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d?)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}|([\dA-Za-z-]+\.)*[\dA-Za-z-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[A-Za-z]{2}))(:\d+)*(\/($|[\w#$%&'+,.=?\\~-]+))*$/;
  return urlregex.test(textval);
}

// 合法地址
export function isURL(rule, value) {
  if (value === '') {
    return Promise.reject('请输入地址');
  } else {
    const reg =
      /^((https?|ftp):\/\/)*(\/)*([\d.A-Za-z-]+(:[\d$%&.A-Za-z-]+)*@)*((25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d?)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}|([\dA-Za-z-]+\.)*[\dA-Za-z-]+)(:\d+)*(\/($|[\w#$%&'+,.=?\\~-]+))*$/;
    return !reg.test(value) ? Promise.reject('输入的地址格式错误') : Promise.resolve();
  }
}

export function isPositiveInteger(value) {
  const regex = /^[1-9]d*$/;
  return regex.test(value);
}

// 合法地址1
export function isURL1(rule, value) {
  if (value === '') {
  } else {
    const reg =
      /^((https?|ftp):\/\/)*(\/)*([\d.A-Za-z-]+(:[\d$%&.A-Za-z-]+)*@)*((25[0-5]|2[0-4]\d|1\d{2}|[1-9]\d?)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3}|([\dA-Za-z-]+\.)*[\dA-Za-z-]+)(:\d+)*(\/($|[\w#$%&'+,.=?\\~-]+))*$/;
    return !reg.test(value) ? Promise.reject('输入的地址格式错误') : Promise.resolve();
  }
}

/* 小写字母*/
export function validateLowerCase(string_) {
  const reg = /^[a-z]+$/;
  return reg.test(string_);
}

/* 大写字母*/
export function validateUpperCase(string_) {
  const reg = /^[A-Z]+$/;
  return reg.test(string_);
}

/* 大小写字母*/
export function validatAlphabets(string_) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(string_);
}

/* 营业执照验证，不包含非空校验，需要另外进行校验*/
export function validateBusinessLicense(rule, string_) {
  if (string_ === '' || string_ === undefined || string_ === null) {
    return Promise.resolve();
  } else {
    const reg = /^[\dA-Za-z]{15,18}$/;
    return !reg.test(string_) ? Promise.reject('输入的营业执照错误') : Promise.resolve();
  }
}

/* 手机号*/
export function isMobile(value) {
  if (!value) {
    return false;
  }
  const pattern = /^\d{11}$/;
  if (pattern.test(value)) {
    return true;
  }
  return false;
}

// 验证手机号，不包含非空校验，需要另外进行校验
export function checkPhone(rule, value) {
  if (value == undefined || value == undefined || value == '') {
    return Promise.resolve();
  } else {
    const reg = /^\d{11}$/;
    if (!reg.test(value)) {
      return Promise.reject('输入的手机号错误');
    }
    return Promise.resolve();
  }
}

/** 这个文件只允许放表单验证方法 **/

// 邮箱验证
export function isEmail(rule, value) {
  if (value === '') {
    return Promise.reject('请正确填写邮箱');
  } else {
    if (value !== '') {
      const reg = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$');
      if (!reg.test(value)) {
        return Promise.reject('请输入有效的邮箱');
      }
    }
    return Promise.resolve();
  }
}

// 数值验证
export function isNumber(rule, value) {
  const reg = /(^(-?\d+)(\.\d+)?$)/;
  if (!reg.test(value)) {
    return Promise.reject('请输入数字！');
  }
  return Promise.resolve();
}

export function checkbox(rule, value) {
  return value.length === 0 ? Promise.reject('请选择月份！') : Promise.resolve();
}

export function checkSpecificKey(rule, string_, callback) {
  const specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'";
  for (let index = 0; index < string_.length; index++) {
    if (specialKey.includes(string_.slice(index, 1 + index))) {
      return callback(new Error('不能包含特殊字符！'));
    }
  }
  return Promise.resolve();
}

// 用户名验证
export function isUsername(rule, value) {
  if (value === '') {
    return Promise.reject('账号不能为空');
  } else {
    const reg = new RegExp('^[a-zA-Z0-9]{0,20}$');
    return !reg.test(value)
      ? Promise.reject('支持20个字符的字母和数字组合（不能输入空格）')
      : checkUserName({
          name: value,
        })
          .then((response) => {
            return response.message == '' ? Promise.resolve() : Promise.reject(response.message);
          })
          .catch((error) => {});
  }
}

// 用户名验证
export function isUsername10(rule, value) {
  if (value === '') {
    return Promise.reject('账号不能为空');
  } else {
    const reg = new RegExp('^[a-zA-Z0-9]{0,10}$');
    return !reg.test(value)
      ? Promise.reject('支持10个字符的字母和数字组合（不能输入空格）')
      : checkUserName({
          name: value,
        })
          .then((response) => {
            return response.message == '' ? Promise.resolve() : Promise.reject(response.message);
          })
          .catch((error) => {});
  }
}

let mobileCodeCheckCount = 0;

export function isSecondEmpty(rule, value) {
  if (mobileCodeCheckCount >= 1 && value == '') {
    return Promise.reject('不能为空');
  } else {
    mobileCodeCheckCount++;
    return Promise.resolve();
  }
}

// 密码验证
export function isPassword(rule, value) {
  if (value === '' || value == undefined) {
    return Promise.reject('密码不能为空');
  } else {
    const reg = new RegExp('^[^\\s]{0,}$');
    if (!reg.test(value)) {
      return Promise.reject('不能输入空格');
    } else {
      const reg = new RegExp('^(?=.*\\d)(?!^\\d+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\\s\\u4e00-\\u9fa5]{6,16}$');
      return !reg.test(value) ? Promise.reject('6-16位密码，数字、字母、符号至少包含两种') : Promise.resolve();
    }
  }
}

// 手机验证码
export function isCheckPhoneCode(mobile, mobileCodeType, mobileCode) {
  if (value == '' || value == undefined || value == '') {
    return Promise.reject('请输入手机验证码');
  } else if (value !== '') {
    return checkPhoneCode({
      mobile: mobile,
      mobileCode: mobileCode,
      mobileCodeType: mobileCodeType,
    })
      .then((response) => {
        return response.data.code == 20_000 ? Promise.resolve() : Promise.reject(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    return Promise.resolve();
  }
}

// 身份证号校验
export function isidCard(rule, value) {
  if (value === '') {
    return Promise.reject('身份证号不能为空');
  } else {
    if (value !== '') {
      const reg = /^(^\d{15}$)|(^\d{18}$)|(^\d{17}([\dXx])$)$/;
      return !reg.test(value) ? Promise.reject('身份证号格式输入有误') : Promise.resolve();
    }
  }
}

// 验证经度
export function isLongitude(rule, value, parameters) {
  if (!parameters.value) {
    return Promise.reject('请输入经度');
  } else {
    const reg = /^[+-]?(0?\d{1,2}\.\d{1,6}|1[0-7]?\d\.\d{1,6}|180\.0{1,6})$/;
    return !reg.test(parameters.value) ? Promise.reject('输入的经度错误(整数部分为0～180，必须输入1到6位小数)') : Promise.resolve();
  }
}

// 验证纬度
export function isLatitude(rule, value, parameters) {
  if (!parameters.value) {
    return Promise.reject('请输入纬度');
  } else {
    const reg = /^[+-]?([0-8]?\d\.\d{1,6}|90\.0{1,6})$/;
    return !reg.test(parameters.value) ? Promise.reject('输入的纬度错误(整数部分为0～90，必须输入1到6位小数)') : Promise.resolve();
  }
}

// 小数限制
export function fixed(rule, value, parameters) {
  if (!value) {
    return Promise.reject('请输入数字');
  } else {
    const reg = new RegExp('^[0-9]{1,' + parameters.int + '}([.][0-9]{' + parameters.min + ',' + parameters.max + '})?$');
    if (!reg.test(value)) {
      let string_ = '';
      if (parameters.int) {
        string_ += '只能' + parameters.int + '位以内整数';
        // return Promise.reject('只能' + params.int + '位以内整数');
      }
      if (parameters.min === 0) {
        string_ += string_ != '' ? ',' + parameters.max + '位以内小数' : '只能' + parameters.max + '位以内小数';
        // return Promise.reject('只能' + params.max + '位以内小数');
      } else {
        string_ +=
          string_ != ''
            ? ',' + parameters.min + '-' + parameters.max + '位小数'
            : '只能' + parameters.min + '-' + parameters.max + '位小数';
        // return Promise.reject('只能' + params.min + '-' + params.max + '位小数');
      }
      return Promise.reject(string_);
    } else {
      return Promise.resolve();
    }
  }
}
