<template>
  <v-chart
    ref="vchart"
    class="kchart"
    :theme="curChartTheme"
    :loading="loading"
    :loading-options="loadingOptions"
    :manual-update="true"
    autoresize
  />
</template>

<script>
import * as echarts from 'echarts';
import VChart, {THEME_KEY} from 'vue-echarts';
import {getTheme} from '@/themes/themes';
import {ref, provide} from 'vue';

export default {
  components: {VChart},
  name: 'KCharts',
  inject: ['curTheme'],
  computed: {
    curChartTheme() {
      return getTheme(this.curTheme).chartTheme;
    },
  },
  data: function () {
    return {
      loading: false,
      loadingOptions: undefined,
    };
  },
  mounted: function () {
    this.$refs.vchart.resize();
  },
  methods: {
    setOption(option, notMerge) {
      // 这里是整体主题，和chart主题有区别
      const theme = this.curTheme;
      if (!option.backgroundColor) {
        option.backgroundColor = 'transparent';
      }
      let dv;
      if (option && option.toolbox && option.toolbox.feature) dv = option.toolbox.feature.dataView;
      switch (theme) {
        case 'tech':
          if (dv) {
            dv.buttonColor = '#267b9d';
            dv.backgroundColor = '#042552';
            dv.textColor = '#ffffff';
          }
          if (option.legend) {
            option.legend.pageTextStyle = {color: '#b0b0b0'};
            option.legend.backgroundColor = 'rgba(0, 125, 170, 0.2)';
            option.legend.borderColor = 'rgba(72, 231, 255, 0.4)';
            option.legend.pageIconColor = '#aaa';
            option.legend.pageIconInactiveColor = '#2f4554';
          }
          if (option.yAxis?.splitLine?.lineStyle && !option.yAxis.splitLine.lineStyle.color) {
            option.yAxis.splitLine.lineStyle.color = ['#27567f'];
          }
          option.darkMode = true;
          break;
        case 'light':
          if (dv) {
            dv.buttonColor = '#1677ff';
            dv.backgroundColor = '#ffffff';
            dv.textColor = undefined;
          }
          if (option.legend) {
            option.legend.backgroundColor = '#F6F6F8';
            option.legend.borderColor = '#E1E3E8';
          }
          option.darkMode = false;
          break;
        case 'gang':
          if (dv) {
            dv.buttonColor = '#267b9d';
            dv.backgroundColor = '#042552';
            dv.textColor = '#ffffff';
          }
          if (option.legend) {
            option.legend.pageTextStyle = {color: '#b0b0b0'};
            option.legend.pageIconColor = '#aaa';
            option.legend.pageIconInactiveColor = '#2f4554';
          }
          if (option.yAxis?.splitLine?.lineStyle) {
            option.yAxis.splitLine.lineStyle.color = ['rgba(255, 255, 255, 0.3)'];
          }
          option.darkMode = true;
          break;

        default:
          break;
      }
      if (this.$refs.vchart) {
        this.$refs.vchart.setOption(option, notMerge);
      }
    },
    showLoading(title) {
      let opts = {text: title ? title : '正在加载数据'};
      if (this.curChartTheme === 'dark') {
        opts.maskColor = '#1e4175';
      }

      this.loadingOptions = opts;
      this.loading = true;
    },
    hideLoading() {
      this.loading = false;
    },
  },
};

// 这个方法是为了方便Cypress里面拿到图标对象进行测试
window.getChart = function (selector) {
  return echarts.getInstanceByDom(document.querySelector(selector));
};
</script>
