<template>
  <div>
    <template v-if="form.locationMode == 0">
      <a-row :gutter="[{xxl: 10, xl: 4}]">
        <a-col :xxl="8" :xl="7" :lg="6">
          <a-form-item name="xAxis" style="margin-bottom: 0px">
            <a-input-number
              v-model:value="form.xAxis"
              placeholder="经度"
              step="0.000001"
              :disabled="disabled"
              :controls="false"
              style="width: 100%"
              @change="triggerChange"
            />
          </a-form-item>
        </a-col>
        <a-col :xxl="8" :xl="7" :lg="6">
          <a-form-item name="yAxis" style="margin-bottom: 0px">
            <a-input-number
              v-model:value="form.yAxis"
              placeholder="纬度"
              step="0.000001"
              :disabled="disabled"
              :controls="false"
              style="width: 100%"
              @change="triggerChange"
            />
          </a-form-item>
        </a-col>
        <a-col>
          <a-button :disabled="disabled" @click="mapIsVisible()"> 坐标拾取</a-button>
        </a-col>
      </a-row>
      <MapModal
        v-model:x-axis="form.xAxis"
        v-model:y-axis="form.yAxis"
        v-model:full-address="form.fullAddress"
        v-model:address="form.address"
        v-model:open="mapVisible"
        @change="triggerChange"
      />
    </template>
    <template v-else>
      <a-space gap="12" style="flex-wrap: wrap">
        <a-form-item name="locateType" style="margin-bottom: 0px">
          <a-select
            v-model:value="form.locateType"
            :disabled="disabled"
            style="width: 100px"
            :options="$locateTypeOptions"
            @change="locateTypeChange"
          />
        </a-form-item>
        <a-form-item v-if="form.locateType != 0" style="margin-bottom: 0px" name="coordinateSystem">
          <a-select
            v-model:value="form.coordinateSystem"
            :disabled="disabled"
            style="width: 100px"
            :options="$coordinateOptions"
            @change="triggerChange"
          />
        </a-form-item>
        <a-form-item name="lonIdentification" style="margin-bottom: 0px">
          <a-input
            v-model:value="form.lonIdentification"
            :disabled="disabled"
            style="width: 80px"
            placeholder="定位标识"
            @change="triggerChange"
          />
        </a-form-item>
        <a-form-item style="margin-bottom: 0px" name="latIdentification">
          <a-input
            v-model:value="form.latIdentification"
            :disabled="disabled"
            style="width: 80px"
            placeholder="定位标识"
            @change="triggerChange"
          />
        </a-form-item>
      </a-space>
    </template>
  </div>
</template>
<script>
import MapModal from '@/components/map/mapModal';
import {reactive, ref, watch} from 'vue';
import _ from 'lodash';
export default {
  name: 'Coordinate',
  components: {
    MapModal,
    VNodes: (_, {attrs}) => {
      return attrs.vnodes;
    },
  },
  props: {
    modelValue: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      handler(newValue) {
        const tempValue = _.cloneDeep(newValue);
        this.form.xAxis = tempValue.xAxis;
        this.form.yAxis = tempValue.yAxis;
        this.form.fullAddress = tempValue.fullAddress;
        this.form.address = tempValue.address;
        this.form.locationMode = tempValue.locationMode;
        this.form.lonIdentification = tempValue.lonIdentification;
        this.form.latIdentification = tempValue.latIdentification;
        this.form.locateByReportData = tempValue.locateByReportData;
        this.form.locateType = tempValue.locateType;
        this.form.coordinateSystem = tempValue.coordinateSystem;
        if (
          this.form.locationMode == 1 &&
          this.form.locateType == 0 &&
          this.form.lonIdentification == undefined &&
          this.form.latIdentification == undefined
        ) {
          this.form.lonIdentification = 'LAC';
          this.form.latIdentification = 'CELLID';
        }
      },
      deep: true,
    },
  },
  setup(props, context) {
    const tempValue = _.cloneDeep(props.modelValue);
    const form = reactive({
      xAxis: tempValue !== undefined ? (tempValue.xAxis !== '' ? tempValue.xAxis : undefined) : undefined,
      yAxis: tempValue !== undefined ? (tempValue.yAxis !== '' ? tempValue.yAxis : undefined) : undefined,
      fullAddress: tempValue !== undefined ? tempValue.fullAddress : '北京市东城区东华门街道外金水桥天安门',
      address: tempValue !== undefined ? tempValue.address : [],
      locationMode: tempValue !== undefined ? (tempValue.locationMode !== undefined ? tempValue.locationMode : 0) : 0,
      locateType: tempValue !== undefined ? (tempValue.locateType !== undefined ? tempValue.locateType : 0) : 0,
      coordinateSystem: tempValue !== undefined ? (tempValue.coordinateSystem !== undefined ? tempValue.coordinateSystem : 0) : 0,
      lonIdentification: tempValue !== undefined ? tempValue.lonIdentification : undefined,
      latIdentification: tempValue !== undefined ? tempValue.latIdentification : undefined,
    });
    form.locateByReportData =
      form.locationMode == 0
        ? 0
        : tempValue != undefined && tempValue.locateByReportData != undefined
        ? tempValue.locateByReportData
        : 1;
    if (form.locateByReportData == 1 && form.lonIdentification == undefined && form.latIdentification == undefined) {
      form.lonIdentification = 'LAC';
      form.latIdentification = 'CELLID';
    }
    return {form};
  },
  data() {
    return {
      mapVisible: false,
    };
  },
  methods: {
    locateTypeChange() {
      if (this.form.locateType == 1) {
        this.form.coordinateSystem = 0;
      }
      this.triggerChange();
    },
    mapIsVisible() {
      this.mapVisible = true;
    },
    triggerChange() {
      if (this.form.locationMode == 1) {
        const locateByReportDataTypes = [[1], [2, 3, 4]];
        this.form.locateByReportData = locateByReportDataTypes[this.form.locateType][this.form.coordinateSystem];
      }
      this.$emit('update:modelValue', _.cloneDeep(this.form));
    },
  },
};
</script>

<style scoped></style>
