import request from '@/utils/request';

export function getGatewayIndicatorTreeInGroup(groupId, generateDefault, gatewayIds = []) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/gatewayIndicatorTree',
    method: 'get',
    params: {
      gatewayIds: gatewayIds.toString(),
    },
  });
}

export function getHasPermissionGatewayIndicatorTree(groupId, parentId) {
  return request({
    url: '/iot/groups/' + groupId + '/groups/hasPermissionGatewayIndicatorTree',
    method: 'get',
    params: {
      topGroupId: parentId,
    },
  });
}
export function getGatewayIndicatorTreeInGateway(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/gatewayIndicatorTree/inGateway',
    method: 'get',
  });
}

export function getAllGatewaysIndicators(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getAllGatewaysIndicators',
    method: 'get',
  });
}

export function getGatewaysIndicatorsByIds(groupId, ids) {
  return request({
    url: '/iot/groups/' + groupId + '/gateways/getGatewaysIndicatorsByIds',
    method: 'get',
    params: {
      ids: ids.toString(),
    },
  });
}
