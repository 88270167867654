<template>
  <a-modal
    v-model:open="visible"
    title="购买监测项提醒"
    width="360px"
    destroy-on-close
    :mask-closable="false"
    :ant-modal-footer="false"
  >
    <template #footer>
      <div style="width: 100%; text-align: center">
        <a-button type="primary" @click="buyResources">立即购买</a-button>
      </div>
    </template>
    <slot name="content" />
    <SetMealModal
      v-if="showSetMeal"
      type="升级监测项配置"
      :target-id="targetId"
      :after-close="
        () => {
          showSetMeal = false;
          visible = false;
        }
      "
    />
  </a-modal>
</template>

<script>
import SetMealModal from './setMealModal.vue';
export default {
  components: {
    SetMealModal,
  },
  props: {
    remindMessage: String,
    targetId: [String, Number],
  },
  data() {
    return {
      showSetMeal: false,
      indicatorSetMeal: this.$gatewayIndicatorSetMeal.map((a) => a.name),
      messageSetMeal: ['1000条（80元）'],
      visible: true,
    };
  },
  mounted() {},
  methods: {
    buyResources() {
      this.showSetMeal = true;
    },
  },
};
</script>

<style></style>
