<template>
  <a-table ref="table" :columns="columns" :scroll="{x: scrollX, y: scrollY}" :size="size">
    <!-- 循环注册 父级传递下来的slot -->
    <template v-for="(value, key) in $slots" #[key]="slotProps" :key="key">
      <slot :name="key" v-bind="slotProps || {}"></slot>
    </template>
  </a-table>
</template>

<script>
import {ref, nextTick} from 'vue';
import store from '@/store';

export const AH_TYPE = {
  list: 0,
  listWithoutPagination: 1,
  modal: 2,
  modalWithoutPagination: 3,
};

// autoHeightTable 自动计算高度的表格
export default {
  props: {
    type: {type: Number, default: AH_TYPE.list},
    scrollX: {type: String, default: '100%'},
    extraHeight: {type: Number, default: 0},
    columns: Object,
    minHeight: {type: Number, default: 80},
    size: String,
  },
  computed: {
    scrollY() {
      return this.calcHeightFromView(this.top + this.bottom + this.extraHeight, this.minHeight);
    },
  },
  data: function () {
    // full-modal内部含搜索条距上149，距下64（分页）+12
    let top;
    let bottom;
    switch (this.type) {
      case AH_TYPE.list:
        top = 208;
        bottom = 72;
        // 浅色主题下content上下多了16px的留白
        if (store.getters.curTheme === 'light') {
          top += 16;
          bottom += 16;
        }

        break;
      case AH_TYPE.listWithoutPagination:
        top = 208;
        bottom = 24;
        // 浅色主题下content上下多了16px的留白
        if (store.getters.curTheme === 'light') {
          top += 16;
          bottom += 16;
        }
        break;
      case AH_TYPE.modal:
        top = 148;
        bottom = 128;
        break;
      case AH_TYPE.modalWithoutPagination:
        top = 148;
        bottom = 80;
        break;
    }
    return {
      top: top,
      bottom: bottom,
    };
  },
  watch: {
    columns() {
      this.smartUpdateTableScrollHeight();
    },
  },
  mounted() {
    this.smartUpdateTableScrollHeight();
  },
  methods: {
    isAnimated(el) {
      if (el.classList.contains('ant-zoom-enter')) return true;

      if (el.parentElement) return this.isAnimated(el.parentElement);

      return false;
    },
    smartUpdateTableScrollHeight() {
      // 动画结束后计算表格高度，这里只处理了Modal的动画，其他有需要可以增加
      setTimeout(() => {
        if (this.isAnimated(this.$el)) {
          this.smartUpdateTableScrollHeight();
        } else {
          this.updateTableScrollHeight();
        }
      }, 16);
    },
    updateTableScrollHeight() {
      // 用header是因为body在空表格里不存在,tbody滚动会影响top
      const elTableHeader = this.$el.querySelector('.ant-table-header');
      // console.log(
      //   elTableHeader.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
      //     .parentElement.parentElement.parentElement.className
      // );
      if (elTableHeader) {
        this.top = elTableHeader.getBoundingClientRect().bottom + 1;
        //        console.log('top: ' + this.top);
      }
    },
  },
};
</script>
