const app = {
  state: {
    toptooltitle: '',
    subSystem: undefined,
    messageList: [],
    auditList: [],
    listRefreshFlag: undefined,
    // TODO 仅仅是用来控制左侧菜单的显示隐藏（并没有做刷新页面要保持展开折叠状态），应该在layout中
    moveClass: undefined,
    // TODO 仅用来在改变自定义报表的顺序后，在进入统计中心时判断是否需要更新路由。
    // 如果仅是担心改变报表顺序会频繁发送获取获取路由请求，可以看看前端的节流和防抖
    // 或者在调整顺序的组件中，在离开前判断是否要更新路由
    needRefreshMenu: false,
    logoUrl: undefined,
    systemLogoUrl: '/static/pc/img/project-logo.png',
    curTheme: undefined,
    isExceedingGatewayIndicatorLimit: false,
    gatewayIndicatorMaxNum: undefined,
    setMealEndAt: undefined,
    gatewayIndicatorExpired: false,
    sendMessagesTotalNum: undefined,
    canEntryData: false,
    enableMonitor: true,
  },
  mutations: {
    SET_TOPTOOLTITLE: (state, toptooltitle) => {
      state.toptooltitle = toptooltitle;
    },
    SET_SUBSYSTEM: (state, subSystem) => {
      state.subSystem = subSystem;
    },
    SET_MESSAGELIST: (state, data) => {
      state.messageList = JSON.parse(JSON.stringify(data));
    },
    SET_AUDITLIST: (state, data) => {
      state.auditList = JSON.parse(JSON.stringify(data));
    },
    SET_LIST_REFRESH_FLAG: (state, listRefreshFlag) => {
      state.listRefreshFlag = listRefreshFlag;
    },
    SET_MOVE_CLASS: (state, moveClass) => {
      state.moveClass = moveClass;
    },
    SET_NEED_REFRESH_MENU: (state, needRefreshMenu) => {
      state.needRefreshMenu = needRefreshMenu;
    },
    SET_LOGO_URL: (state, logoUrl) => {
      state.logoUrl = logoUrl;
    },
    SET_SYSTEM_LOGO_URL: (state, systemLogoUrl) => {
      state.systemLogoUrl = systemLogoUrl;
    },
    SET_PAY_STATUS: (state, payStatus) => {
      state.payStatus = payStatus;
    },
    SET_SYSTEM_NAME: (state, systemName) => {
      state.systemName = systemName;
    },
    SET_IS_EXCEEDING_GATEWAY_INDICATOR_LIMIT: (state, isExceedingGatewayIndicatorLimit) => {
      state.isExceedingGatewayIndicatorLimit = isExceedingGatewayIndicatorLimit;
    },
    SET_GATEWAY_INDICATOR_MAX_NUM: (state, gatewayIndicatorMaxNum) => {
      state.gatewayIndicatorMaxNum = gatewayIndicatorMaxNum;
    },
    SET_SET_MEAL_END_AT: (state, setMealEndAt) => {
      state.setMealEndAt = setMealEndAt;
    },
    SET_SEND_MESSAGES_TOTAL_NUM: (state, sendMessagesTotalNum) => {
      state.sendMessagesTotalNum = sendMessagesTotalNum;
    },
    SET_GATEWAY_INDICATOR_EXPIRED: (state, gatewayIndicatorExpired) => {
      state.gatewayIndicatorExpired = gatewayIndicatorExpired;
    },
    SET_CUR_THEME: (state, curTheme) => {
      document.body.className = '';
      document.body.classList.add('theme-' + curTheme);
      state.curTheme = curTheme;
    },
    SET_CAN_ENTRY_DATA: (state, canEntryData) => {
      state.canEntryData = canEntryData;
    },
    SET_ENABLE_MONITOR: (state, enableMonitor) => {
      state.enableMonitor = enableMonitor;
    },
    SET_FROM_CORPORATION_BIG_SCREEN: (state, fromCorporationBigScreen) => {
      state.fromCorporationBigScreen = fromCorporationBigScreen;
    },
  },
  actions: {},
};

export default app;
