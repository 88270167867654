<template>
  <div>
    <a-form-item-rest>
      <a-input-number
        v-model:value="date.hour"
        :precision="0"
        :min="0"
        addon-after="时"
        style="width: 33%"
        @change="changeDate()"
      />
      <a-input-number
        v-model:value="date.minute"
        :precision="0"
        :min="0"
        addon-after="分"
        style="width: 33%"
        @change="changeDate()"
      />
      <a-input-number
        v-model:value="date.second"
        :precision="0"
        :min="0"
        addon-after="秒"
        style="width: 33%"
        @change="changeDate()"
      />
    </a-form-item-rest>
  </div>
</template>

<script>
import {Form} from 'ant-design-vue';
export default {
  name: 'DurationInput',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue !== undefined) {
        this.date.hour = Math.floor(this.modelValue / 3600000);
        this.date.minute = Math.floor((this.modelValue % 3600000) / 60000);
        this.date.second = Math.floor((this.modelValue % 60000) / 1000);
      }
    },
  },
  data() {
    return {
      date: {
        hour: undefined,
        minute: undefined,
        second: undefined,
      },
    };
  },
  created() {
    // 解决一个 Form.Item 只能收集一个表单项的数据问题
    Form.useInjectFormItemContext();
    this.changeDate = this.debounceFunc(this.doChangeDate, 200);
  },
  methods: {
    doChangeDate() {
      if (this.date.second !== undefined && this.date.second >= 60) {
        this.date.minute += 1;
        this.date.second -= 60;
      }
      if (this.date.minute !== undefined && this.date.minute >= 60) {
        this.date.hour += 1;
        this.date.minute -= 60;
      }
      if (
        (this.date.second === undefined || this.date.second === null) &&
        (this.date.minute === undefined || this.date.minute === null) &&
        (this.date.hour === undefined || this.date.hour === null)
      ) {
        this.$emit('update:modelValue');
      } else {
        this.$emit(
          'update:modelValue',
          ((this.date.hour ?? 0) * 3600 + (this.date.minute ?? 0) * 60 + (this.date.second ?? 0)) * 1000
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-input-number-group-wrapper:nth-child(-n + 2) .ant-input-number-group-addon {
  border-right: none !important;
}
</style>
