import request from '@/utils/request';

export function getMessagesByParam(likeName, type, page, size, status) {
  return request({
    url: '/iot/messages',
    method: 'get',
    params: {
      projectOrGatewayFirmName: likeName,
      type: type,
      page: page,
      size: size,
      status: status,
    },
  });
}

export function deleteMessages(ids, type, status) {
  return request({
    url: '/iot/messages',
    method: 'delete',
    params: {
      ids: ids,
      type: type,
      status: status,
    },
  });
}

export function updateMessages(ids, type) {
  return request({
    url: '/iot/messages',
    method: 'put',
    params: {
      ids: ids,
      type: type,
    },
  });
}

export function getAllMessageTemplates() {
  return request({
    url: '/iot/messages/getAllMessageTemplates',
    method: 'get',
  });
}

export function getMessageSendTypeConfig(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/messageSendTypeConfigs/getMessageSendTypeConfig',
    method: 'get',
  });
}

export function changeMessageSendTypeConfig(groupId, data) {
  return data.id == undefined
    ? request({
        url: '/iot/groups/' + groupId + '/messageSendTypeConfigs',
        method: 'post',
        data: data,
      })
    : request({
        url: '/iot/groups/' + groupId + '/messageSendTypeConfigs/' + data.id,
        method: 'put',
        data: data,
      });
}

export function getFirmMessageSendTypeConfig(firmId) {
  return request({
    url: '/iot/gatewayFirms/' + firmId + '/messageSendTypeConfigs/getMessageSendTypeConfig',
    method: 'get',
  });
}

export function changeFirmMessageSendTypeConfig(firmId, data) {
  return data.id == undefined
    ? request({
        url: '/iot/gatewayFirms/' + firmId + '/messageSendTypeConfigs',
        method: 'post',
        data: data,
      })
    : request({
        url: '/iot/gatewayFirms/' + firmId + '/messageSendTypeConfigs/' + data.id,
        method: 'put',
        data: data,
      });
}
