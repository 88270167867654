<template>
  <div id="body-layout">
    <a-layout>
      <a-layout-header class="layout-header">
        <Navbar />
        <!-- <iconFont
          type="icon-tianjia"
        /> -->
      </a-layout-header>
      <a-layout>
        <a-layout-sider v-if="shrinkMenu" width="180" theme="light">
          <Sidebar />
        </a-layout-sider>
        <a-layout>
          <a-breadcrumb v-if="!($route.path.split('/').indexOf('hd') > -1)" :key="breadcrumbKey" separator=">" :routes="breadcrumb">
            <template #itemRender="{route, routes}">
              <span v-if="routes.indexOf(route) === routes.length - 1" class="breadcrumb">
                {{
                  $route.query.gatewayAlias == undefined ? route.breadcrumbName : $route.query.gatewayAlias + route.breadcrumbName
                }}
              </span>
              <span v-else class="breadcrumb">
                <a-select
                  v-if="route.isGroup"
                  ref="select"
                  :value="route.breadcrumbName"
                  :options="breadcrumbNameOptions(route)"
                  :dropdown-match-select-width="false"
                  size="small"
                  @select="breadCrumbClick"
                />
                <span v-else @click="toProjectIndex(route)">{{ route.breadcrumbName }}</span>
              </span>
            </template>
          </a-breadcrumb>
          <a-layout-content
            :class="['layoutC', shrinkMenu ? '' : 'collapsed', $route.path.split('/').indexOf('hd') > -1 ? 'hd' : '']"
          >
            <App-main id="main-content" :class="[$route.path.split('/').indexOf('hd') > -1 ? 'hd' : '']" />
          </a-layout-content>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {Navbar, Sidebar, AppMain} from './components';
import {computed, h} from 'vue';
import {findGroupIdHasSubMenuAndPermission} from '@/api/group';
import store from '@/store';
import {gatewayFirmByUserId as firmDetail} from '@/api/gatewayFirm';
import {getNavProject} from '@/api/project';
import {getCorporationById} from '@/api/corporation';
import {findByDomainName} from '@/api/customDomainConfig';
import ArrearageLayout from './arrearageLayout';
import {Button, Modal, notification} from 'ant-design-vue';
import {getLeftAccessGatewayIndicatorByGroupId} from '@/api/projectOverview';
import router from '@/router';
import dayjs from 'dayjs';
let messageCount = 0;
let messageClass = 'notification-class';
export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    ArrearageLayout,
  },
  data() {
    return {
      shrinkMenu: true,
      checkBreadcrumb: {
        首页: '首页',
      },
      curMenus: undefined,
      curGroupMenuTree: undefined,
      breadcrumb: [],
      breadcrumbKey: 1,
    };
  },
  provide() {
    return {
      shrinkMenu: computed(() => this.shrinkMenu),
      switchShrink: this.switchShrink,
    };
  },
  computed: {
    ...mapGetters(['groupMenu', 'projectGroupId']),
    device() {
      return this.$store.state.app.device;
    },
  },
  watch: {
    $route(newVal, oldVal) {
      this.breadcrumb = this.getBreadcrumb();
      this.breadcrumbKey++;
    },
    '$store.getters.groupMenu'(newVal, oldVal) {
      if ((oldVal == undefined || oldVal.length === 0) && newVal && newVal.length > 0) {
        this.breadcrumb = this.getBreadcrumb();
        this.breadcrumbKey++;
      }
    },
    '$store.getters.systemName'(newVal, oldVal) {
      document.title = newVal !== undefined ? newVal : '物Link平台';
    },
  },
  beforeRouteEnter(to, from, next) {
    switch (to.meta.subSystem) {
      case 2:
        if (to.meta.subSystem == from.meta.subSystem && to.params.firmId == from.params.firmId) {
          next();
          store.commit('SET_PAY_STATUS');
          return;
        }
        firmDetail(to.params.firmId).then((response) => {
          store.commit('SET_TOPTOOLTITLE', response.data.gatewayFirm.name);
          next();
          store.commit('SET_PAY_STATUS');
        });
        store.commit('SET_PAY_STATUS');
        store.commit('SET_PROJECT_GROUP_ID');
        break;
      case 3:
        next();
        store.commit('SET_PAY_STATUS');
        break;
      case 4:
        const requests = [];
        let shouldUpdateProject = false;
        let shouldUpdateGroup = false;
        if (to.meta.subSystem != from.meta.subSystem || to.params.groupId != from.params.groupId) {
          requests.push(getNavProject(to.params.groupId), getLeftAccessGatewayIndicatorByGroupId(to.params.groupId));
          shouldUpdateProject = true;
        }
        Promise.all(requests).then((responses) => {
          if (shouldUpdateProject) {
            const navProjectResponse = responses.shift();
            const leftAccessResponse = responses.shift();
            if (navProjectResponse.code == 20_000) {
              if (navProjectResponse.data.accessGatewayIndicatorMaxNum != -1 && leftAccessResponse.data < 0) {
                store.commit('SET_IS_EXCEEDING_GATEWAY_INDICATOR_LIMIT', true);
              } else {
                store.commit('SET_IS_EXCEEDING_GATEWAY_INDICATOR_LIMIT', false);
              }
              store.commit('SET_SET_MEAL_END_AT', dayjs(navProjectResponse.data.setMealEndAt).format('YYYY-MM-DD'));
              store.commit('SET_GATEWAY_INDICATOR_MAX_NUM', navProjectResponse.data.accessGatewayIndicatorMaxNum);
              store.commit('SET_SEND_MESSAGES_TOTAL_NUM', navProjectResponse.data.sendMessagesTotalNum);
              store.commit('SET_TOPTOOLTITLE', navProjectResponse.data.name);
              store.commit('SET_PROJECT_GROUP_ID', navProjectResponse.data.projectGroupId);
              store.commit('SET_PROJECTTYPE_ID', navProjectResponse.data.projectTypeId);
              store.commit('SET_GROUPDISPLAYNAME', navProjectResponse.data.groupDisplayName);
              store.commit('SET_LOGO_URL', navProjectResponse.data.logoUrl);
              store.commit('SET_PAY_STATUS', navProjectResponse.data.payStatus);
              store.commit('SET_CAN_ENTRY_DATA', navProjectResponse.data.canEntryData);
              store.commit('SET_ENABLE_MONITOR', navProjectResponse.data.enableMonitor);
              let now = dayjs(new Date()).format('YYYY-MM-DD');
              store.commit('SET_GATEWAY_INDICATOR_EXPIRED', dayjs(store.getters.setMealEndAt).isBefore(now));
            }
            if (store.getters.isShowMessage && store.getters.payStatus == 'B') {
              messageCount++;
              if (messageCount >= 5) {
                const nodes = document.querySelectorAll('.notification-class');
                if (nodes && nodes.length > 0) {
                  for (const node of nodes) {
                    node.className = node.className.replace('notification-class', 'notification-class-many');
                  }
                }
                messageClass = 'notification-class-many';
              } else {
                const nodes = document.querySelectorAll('.notification-class-many');
                if (nodes && nodes.length > 0) {
                  for (const node of nodes) {
                    node.className = node.className.replace('notification-class-many', 'notification-class');
                  }
                }
                messageClass = 'notification-class';
              }
              let duration = store.getters.otherMessageOperate == 0 ? 4.5 : 0;
              notification.info({
                message: '即将欠费提醒',
                description: "''" + store.getters.toptooltitle + "项目''" + '即将欠费，请您公司商务人员及时安排续费!',
                placement: 'topRight',
                duration: duration,
                class: messageClass,
                onClose: () => {
                  messageCount--;
                },
              });
            }
            if (store.getters.payStatus == 'D' || store.getters.payStatus == 'E' || store.getters.payStatus == 'F') {
              router.push({
                path: '/arrearageProjectPage',
              });
              return;
            }
          }
          next();
        });
        break;
      case 10:
        getCorporationById(to.params.groupId).then((response) => {
          store.commit('SET_TOPTOOLTITLE', response.data.name);
          store.commit('SET_LOGO_URL', response.data.corporationDetail.logoUrl);
          next();
        });
        break;
      default:
        next();
        store.commit('SET_PAY_STATUS');
        break;
    }
  },
  created() {
    this.breadcrumb = this.getBreadcrumb();
    document.title = store.getters.systemName !== undefined ? store.getters.systemName : '物Link平台';
  },
  methods: {
    getBreadcrumb() {
      let arr = [
        {
          breadcrumbName: '首页',
          isFirst: true,
          path: '/project/' + this.$route.params.groupId + '/index/projectHD/hd',
          isStop: true,
        },
      ];
      for (const item of this.$route.matched) {
        if (item.path.includes('/hd')) {
          return [];
        }
      }
      if (this.groupMenu != undefined && this.$store.getters.subSystem == this.$SUBSYSTEM_PROJECT && !this.isProject()) {
        const groupLevel = [...this.groupMenu];
        const array = [];
        for (const route of this.$route.matched) {
          if (route.path.includes(':groupId')) {
            this.getGroupLevelList(groupLevel, array);
            arr = [...arr, ...array];
            break;
          }
        }
      }
      this.$route.matched.map((route) => {
        const path = route.path.replace(':groupId', this.$route.params.groupId);
        if (route.children.length != 1 && !route.meta.isRoot) {
          arr.push({
            breadcrumbName: route.meta.title,
            toPushName: this.getToPushName(route),
            path: path,
            subSystem: route.meta.subSystem,
            isStop: true,
          });
          this.checkBreadcrumb[route.meta.title] = route.meta.title;
        }
      });
      return arr;
    },
    getToPushName(route) {
      return route.children && route.children.length > 0 ? this.getToPushName(route.children[0]) : route.name;
    },
    breadcrumbNameOptions(route) {
      const groupMenuOptions = [];
      let menus = [];
      menus = route.parentId == undefined ? this.groupMenu : this.findChildsByParentId(route.parentId, this.groupMenu);
      if (menus && menus.forEach) {
        for (const iterator of menus) {
          groupMenuOptions.push({
            label: iterator.meta.title,
            value: iterator.id,
            disabled: route.groupId == iterator.groupId,
          });
        }
      }
      return groupMenuOptions;
    },
    findChildsByParentId(parentId, array) {
      for (const iterator of array) {
        if (parentId == iterator.groupId) {
          return iterator.children;
        }
        if (iterator.children && iterator.children.length > 0) {
          const childrens = this.findChildsByParentId(parentId, iterator.children);
          if (childrens != undefined) {
            return childrens;
          }
        }
      }
    },
    getBrotherMenus(route, routeList) {
      for (const iterator of routeList) {
        if (route.subSystem == iterator.meta.subSystem && route.breadcrumbName == iterator.meta.title) {
          return routeList;
        }
        if (iterator.children && iterator.children.length > 0) {
          const child = this.getBrotherMenus(route, iterator.children);
          if (child != undefined) {
            return child;
          }
        }
      }
    },
    getRoutes(routeList) {
      const returnRouteList = [];
      for (const element of routeList) {
        if (element.children && element.children.length > 0) {
          element.children = this.getRoutes(element.children);
        }
        if (element.hidden != 1 && this.isCurRoute(element)) {
          returnRouteList.push(element);
        }
      }
      return returnRouteList;
    },
    isCurRoute(route) {
      if (route.redirect == '/404') {
        return true;
      }
      if (this.$route.params.firmId == -1 && route.meta.firmId == undefined && this.$route.params.groupId == -1) {
        return true;
      }
      if (route.meta.firmId == this.$route.params.firmId && route.meta.groupId == this.$route.params.groupId) {
        return true;
      }
      return false;
    },
    getGroupLevelList(groupMenus, array) {
      for (const groupMenu of groupMenus) {
        if (groupMenu.id == this.$route.params.groupId) {
          if (array[0]) {
            array[0].parentId = groupMenu.id;
          }
          array.unshift({
            breadcrumbName: groupMenu.meta.title,
            path: groupMenu.path,
            isGroup: true,
            groupId: groupMenu.id,
            isLastGroup: array[0] == undefined || !array[0].isGroup,
            isStop: true,
          });
          return true;
        }
        if (groupMenu.children) {
          let flag = this.getGroupLevelList(groupMenu.children, array);
          if (flag) {
            if (array[0]) {
              array[0].parentId = groupMenu.id;
            }
            array.unshift({
              breadcrumbName: groupMenu.meta.title,
              path: groupMenu.path,
              groupId: groupMenu.id,
              isGroup: true,
              isLastGroup: array[0] == undefined || !array[0].isGroup,
              isStop: true,
            });
            return flag;
          }
        }
      }
    },
    switchShrink() {
      this.shrinkMenu = !this.shrinkMenu;
    },
    getFristSite(group) {
      return group.children && group.children.length > 0 ? this.getFristSite(group.children[0]) : group;
    },
    toProjectIndex(route) {
      if (route.isFirst != true) {
        if (this.$store.getters.subSystem == this.$SUBSYSTEM_FIRM) {
          this.routePush(route.toPushName, {
            firmId: this.$route.params.firmId,
          });
          return;
        }
        this.routePush(route.toPushName, {
          groupId: this.$route.params.groupId,
        });
        return;
      }
      let name = 'any';
      if (this.$store.getters.subSystem == this.$SUBSYSTEM_PROJECT) {
        this.$store.dispatch('GenerateRoutes', {type: 'project', id: this.$store.getters.projectGroupId}).then(() => {
          this.routePush(name, {
            groupId: this.$store.getters.projectGroupId,
          });
        });
      } else if (this.$store.getters.subSystem == this.$SUBSYSTEM_FIRM) {
        this.$store.dispatch('GenerateRoutes', {type: 'firm', id: this.$route.params.firmId}).then(() => {
          this.routePush(name, {
            firmId: this.$route.params.firmId,
          });
        });
      }
    },
    getGroupById(route, id) {
      if (route.id == id) {
        return route;
      }
      if (route.children && route.children.length > 0) {
        for (const child of route.children) {
          const childRoute = this.getGroupById(child, id);
          if (childRoute) {
            return childRoute;
          }
        }
      }
    },
    getRoute(menus, id) {
      for (const menu of menus) {
        if (menu.id == id) {
          return menu;
        }
        if (menu.children && menu.children.length > 0) {
          const childRoute = this.getRoute(menu.children, id);
          if (childRoute) {
            return childRoute;
          }
        }
      }
    },
    breadCrumbClick(id) {
      let route = this.getRoute(this.groupMenu, id);
      console.log(route);
      findGroupIdHasSubMenuAndPermission(this.$route.params.groupId, route.id).then((response) => {
        this.verifyAndGetData(response, (data) => {
          const toRoute = this.getGroupById(route, data);
          if (toRoute == undefined) {
            this.$notification.warning({
              message: '该分组下没有可访问的功能菜单!',
            });
            return;
          }
          route = this.getFristSite(toRoute);
          this.$store.dispatch('GenerateRoutes', {type: 'group', id: route.groupId, checkHas: true}).then((response) => {
            if (!response) {
              this.$notification.warning({
                message: '该分组下没有可访问的功能菜单!',
              });
              return;
            }
            let name = this.$route.name;
            if (
              !this.hasRoute(name, {
                groupId: route.groupId,
              })
            ) {
              name = 'any';
            }
            this.routePush(name, {
              groupId: route.groupId,
            });
          });
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ant-breadcrumb {
  margin: 0px 24px;
  padding: 15px 0px;
  border-bottom: 1px solid var(--border-color);
}

.layout-header {
  box-sizing: content-box;
  height: 51px;
  padding-left: 0px;
  padding-right: 24px;
  position: relative;
  line-height: 32px;
  flex-basis: 50px;

  z-index: 996;
  border-bottom: 1px solid transparent;

  &::after {
    box-sizing: border-box;
    content: '';
    position: absolute;
    width: 100%;
    height: 51px;
    z-index: -100;
    border-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 616px,
        rgba(0, 240, 255, 0.34) 617px,
        rgba(72, 231, 255, 1)
      )
      10 10;
    border-bottom: 1px solid;
  }

  .img {
    z-index: -100;
    position: relative;
  }
}

.theme-tech {
  .layout-header {
    background: linear-gradient(to right, rgba(72, 231, 255, 0.5), rgba(0, 88, 119, 0.9) 512px);
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
    &::after {
      background: url('/static/pc/img/navbar_right.png') no-repeat right;
    }
  }
}

.theme-light {
  .layout-header {
    background: linear-gradient(to right, #428eff, #4262ff 512px);
    box-shadow: 0 6px 12px 0 #0d184d33;
    &::after {
      background: url('/static/pc/img/navbar_right_light.png') no-repeat right;
    }
  }
}

/deep/ .breadcrumb {
  cursor: pointer;
}
</style>
<style lang="less">
.notification-class-many {
  margin-bottom: -60px !important;
}
.notification-class {
  margin-bottom: 16px !important;
}
.ant-notification {
  z-index: 1051 !important;
}
</style>
