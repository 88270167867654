<template>
  <div>
    <a-row :gutter="[{xxl: 10, xl: 4}]">
      <a-col :xxl="8" :xl="7" :lg="6"> </a-col>
      <a-col :xxl="8" :xl="7" :lg="6"> </a-col>
      <a-col>
        <a-button :disabled="disabled" @click="mapIsVisible()"> 坐标拾取</a-button>
      </a-col>
    </a-row>
    <MapModal v-model:map-type="mapType" v-model:area="form.area" v-model:open="mapVisible" />
  </div>
</template>
<script>
import MapModal from '@/components/map/mapModal';
import {reactive, ref, watch} from 'vue';
export default {
  name: 'MapArea',
  components: {
    MapModal,
    VNodes: (_, {attrs}) => {
      return attrs.vnodes;
    },
  },
  props: {
    modelValue: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      handler(v) {
        this.form.area = this.modelValue.area;
      },
    },
  },
  created() {},
  setup(props, context) {
    let lngLatArray = [];
    const form = reactive({
      area: props.modelValue !== undefined ? lngLatArray : [],
    });
    watch(
      () => [form.area],
      () => {
        context.emit('update:modelValue', form);
      }
    );
    return {form};
  },
  data() {
    return {
      mapVisible: false,
      mapType: 'area',
    };
  },
  methods: {
    mapIsVisible() {
      this.mapVisible = true;
    },
  },
};
</script>

<style scoped></style>
