<template>
  <a-layout id="body-layout" style="height: 100%">
    <a-layout-content :class="['layoutC', shrinkMenu ? '' : 'collapsed']" style="height: 100%">
      <App-main id="main-content" :class="[$route.path.split('/').indexOf('hd') > -1 ? 'hd' : '']" />
    </a-layout-content>
  </a-layout>
</template>

<script>
import store from '@/store';
import {computed, h} from 'vue';
import {gatewayFirmByUserId as firmDetail} from '@/api/gatewayFirm';
import {getNavProject} from '@/api/project';
import AppMain from './components/appMain.vue';

export default {
  name: 'MLayout',
  components: {
    AppMain,
  },
  data() {
    return {
      shrinkMenu: false,
    };
  },
  provide() {
    return {
      shrinkMenu: computed(() => this.shrinkMenu),
    };
  },
  watch: {},
  beforeRouteEnter(to, from, next) {
    switch (to.meta.subSystem) {
      case 2:
        if (to.meta.subSystem == from.meta.subSystem && to.params.firmId == from.params.firmId) {
          next();
          return;
        }
        store.commit('SET_PROJECT_GROUP_ID');
        firmDetail(to.params.firmId).then((response) => {
          store.commit('SET_TOPTOOLTITLE', response.data.gatewayFirm.name);
          next();
        });
        break;
      case 4:
        const requests = [];
        let shouldUpdateProject = false;
        if (to.meta.subSystem != from.meta.subSystem || to.params.groupId != from.params.groupId) {
          requests.push(getNavProject(to.params.groupId));
          shouldUpdateProject = true;
        }
        Promise.all(requests).then((responses) => {
          if (shouldUpdateProject) {
            const navProjectResponse = responses.shift();
            if (navProjectResponse.code == 20000) {
              store.commit('SET_TOPTOOLTITLE', navProjectResponse.data.name);
              store.commit('SET_PROJECT_GROUP_ID', navProjectResponse.data.projectGroupId);
              store.commit('SET_PROJECTTYPE_ID', navProjectResponse.data.projectTypeId);
              store.commit('SET_GROUPDISPLAYNAME', navProjectResponse.data.groupDisplayName);
              store.commit('SET_LOGO_URL', navProjectResponse.data.logoUrl);
            }
          }
          next();
        });
        break;
      default:
        next();
        break;
    }
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
/deep/ #body-layout {
  height: 100% !important;
}
#main-content:has(.m-drawings) {
  min-width: 700px;
  min-height: 300px;
  padding: 0px;
}
</style>
<style lang="less">
.notification-class-many {
  margin-bottom: -60px !important;
}
.notification-class {
  margin-bottom: 16px !important;
}
.ant-notification {
  z-index: 1051 !important;
}
</style>
