import {createStore} from 'vuex';
import app from './modules/app';
import user from './modules/user';
import getters from './getters';
import createVuexAlong from 'vuex-along';

const store = createStore({
  modules: {
    app,
    user,
  },
  getters,
  plugins: [
    createVuexAlong({
      local: {
        list: ['user.token', 'user.refreshToken', 'app.logoUrl', 'app.systemName'],
      },
      session: {
        list: ['app', 'user'],
      },
    }),
  ],
});
export default store;
