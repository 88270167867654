const Base64 = require('js-base64').Base64;

// 加密
export function base64encode(data) {
  return Base64.encode(data);
}

// 解密
export function base64decode(data) {
  return Base64.decode(data);
}
