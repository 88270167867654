<template>
  <a-upload
    :disable="true"
    name="file"
    :before-upload="
      (file) => {
        return beforeUpload(file);
      }
    "
    :show-upload-list="{showRemoveIcon: true}"
    :file-list="fileList"
    @remove="customRemove"
    @preview="handlePreview"
  >
    <template v-if="!isLimitOne || (isLimitOne && fileList.length === 0)">
      <a-button>
        <upload-outlined></upload-outlined>
        点击上传
      </a-button>
    </template>
  </a-upload>
</template>

<script>
import {UploadOutlined} from '@ant-design/icons-vue';
import {Modal, Upload} from 'ant-design-vue';
export default {
  name: 'UploadFile',
  components: {
    UploadOutlined,
  },
  data() {
    return {
      fileList: this.modelValue,
    };
  },
  props: {
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    isLimitOne: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.fileList = this.modelValue;
      },
    },
  },
  methods: {
    download(downloadUrl, downloadFileName) {
      this.getBlob(downloadUrl).then((blob) => {
        this.saveAs(blob, downloadFileName);
      });
    },
    getBlob(url) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };

        xhr.send();
      });
    },
    saveAs(blob, filename) {
      if (window.navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        const body = document.querySelector('body');

        let binaryData = [];
        binaryData.push(blob);
        link.href = window.URL.createObjectURL(new Blob(binaryData));
        link.download = filename;

        // fix Firefox
        link.style.display = 'none';
        body.append(link);

        link.click();
        link.remove();

        window.URL.revokeObjectURL(link.href);
      }
    },
    handlePreview(file) {
      this.download(file.url, file.name);
    },
    customRemove(file) {
      for (let index = 0; index < this.fileList.length; index++) {
        if (this.fileList[index].uid == file.uid) {
          this.fileList.splice(index, 1);
          break;
        }
      }
    },
    beforeUpload(file) {
      const isAccept = [
        'application/msword',
        'application/pdf',
        'text/plain',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ].includes(file.type);
      const name = file.name.split('.')[1];
      const isSuffix = ['doc', 'pdf', 'docx', 'txt'].includes(name);
      if (!isAccept && !isSuffix) {
        Modal.confirm({
          content: () => '上传的文件格式不正确,请选择.doc .pdf .docx .txt类型的文件',
        });
        return Upload.LIST_IGNORE;
      } else {
        if (this.fileList == undefined) {
          this.fileList = [];
        }
        if (this.fileList == '') {
          this.fileList = [];
        }
        this.fileList.push(file);
        this.$emit('update:modelValue', this.fileList);
        this.key += 1;
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
