﻿import {createApp} from 'vue';

import 'normalize.css/normalize.css'; // A modern alternative to CSS reset

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn.js';

// dayjs().tz('Asia/Shanghai');
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

dayjs.locale('zh-cn');
// 统一转为后端需要的格式
dayjs.prototype.toISOString = function () {
  return this.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
};
// 统一转为后端需要的格式
dayjs.prototype.toString = function () {
  return this.toISOString();
};

import Antd from 'ant-design-vue';

import '@/styles/index.less'; // global css
import '@/styles/iconfont.css'; // global css

import App from './app';
import VueGtag from 'vue-gtag';
import {exception} from 'vue-gtag';

import router from './router';
import store from './store';

import SvgIcon from '@/components/SvgIcon'; // svg组件
import '@/permission'; // permission control

import AMapLoader from '@amap/amap-jsapi-loader';
import QrReader from 'vue3-qr-reader';
import formCreate from '@form-create/ant-design-vue';
import {maker} from '@form-create/ant-design-vue';
import Coordinate from '@/components/divform/coordinate';
import UploadFile from '@/components/divform/uploadFile';
import LocationMode from '@/components/divform/locationMode';
import DurationInput from '@/components/divform/durationInput';
import MapArea from '@/components/divform/mapArea';
AMapLoader.load({
  key: '28f5d82438df931678099e0d56f68c2e', // 申请好的Web端开发者Key，首次调用 load 时必填
  version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  plugins: [
    'AMap.PlaceSearch',
    'AMap.AutoComplete',
    'AMap.PlaceSearch',
    'AMap.Geocoder',
    'AMap.TileLayer',
    'AMap.Geolocation',
    'AMap.CitySearch',
    'AMap.Driving',
  ], // 插件列表
});
import {VueClipboard} from '@soerenmartius/vue3-clipboard';
// 图片截切
import 'vue-cropper/dist/index.css';
import 'qweather-icons/font/qweather-icons.css';

import Kunlun from '@/utils/kunlun';
import JsUtil from '@/utils/jsUtil';

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

// 地图
import OpenLayersMap from 'vue3-openlayers';
import 'vue3-openlayers/dist/vue3-openlayers.css';
import Vue3ColorPicker from 'vue3-colorpicker';
import 'vue3-colorpicker/style.css';
import CScrollbar from 'c-scrollbar';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import VueAMap, {initAMapApiLoader} from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css';
initAMapApiLoader({
  key: '28f5d82438df931678099e0d56f68c2e',
  plugin: ['AMap.MoveAnimation'],
});

const app = createApp(App);
app.component('SvgIcon', SvgIcon);
import '@/icons';

import {createFromIconfontCN} from '@ant-design/icons-vue';
const IconFont = createFromIconfontCN({
  scriptUrl: import('@/../static/js/font_2792505_9en42fyo1st.js'),
});
app.component('IconFont', IconFont);

import KCharts from '@/components/kcharts';
app.component('KCharts', KCharts);

import ahTable from '@/components/ahTable';
app.component('AhTable', ahTable);

import ahTree from '@/components/ahTree';
app.component('AhTree', ahTree);

import VueDOMPurifyHTML from 'vue-dompurify-html';
app.use(VueDOMPurifyHTML);

app.use(store);
app.use(router);
app.use(Antd);
app.use(Vue3ColorPicker);
app.use(CScrollbar);
// app.use(VueCollapse)
app.use(VueClipboard);
app.use(Kunlun);
app.use(VueViewer);
app.use(OpenLayersMap);
app.use(VueVirtualScroller);
// app.use(VueCropper)
app.use(QrReader);
app.use(VueAMap);

app.use(formCreate);
formCreate.component('Coordinate', Coordinate);
formCreate.component('UploadFile', UploadFile);
formCreate.component('LocationMode', LocationMode);
formCreate.component('DurationInput', DurationInput);
formCreate.component('MapArea', MapArea);
// app.use(maker);

// 分环境处理
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'bdd' || process.env.NODE_ENV === 'local') {
  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
    // 这里__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue赋值一个createApp实例
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
  }
  app.config.devtools = true;
  console.log('running dev mode');
  //  app.use(
  //    VueGtag,
  //    {
  //      appName: '物Link平台',
  //      pageTrackerScreenviewEnabled: true,
  //      config: {id: 'G-VB6QH5H3T1'},
  //    },
  //    router
  //  );

  app.config.errorHandler = (error, vm, info) => {
    console.error('捕获错误：');
    console.error(vm);
    console.error(error);
    console.error(info);
    alert('捕获错误(详情看控制台):\n' + error + '\n' + info);
  };
  app.config.warnHandler = (msg, vm, trace) => {
    const ignoreWarn = ['Invalid prop: custom validator check failed for prop "checkedKeys".'];
    console.warn('捕获警告：');
    console.warn(vm);
    console.warn(msg);
    console.warn(trace);
    if (!ignoreWarn.includes(msg)) {
      alert('捕获警告(详情看控制台)：\n' + msg + '\n' + trace);
    }
  };

  window.onerror = function (message, source, lineno, colno, error) {
    const ignoreError = [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      'ResizeObserver loop completed with undelivered notifications.',
    ];
    if (!ignoreError.includes(message)) {
      console.error('捕获错误：' + message);
      console.error(error);
      alert('捕获错误:\n' + message + '\n' + error);
    }
  };
} else {
  console.log('running production mode');
  app.use(
    VueGtag,
    {
      appName: '物Link平台',
      pageTrackerScreenviewEnabled: true,
      config: {id: 'G-JCB2N2CWNK'},
    },
    router
  );
  app.config.errorHandler = (error, vm, info) => {
    console.error('捕获错误：');
    console.error(vm);
    console.error(error);
    exception({
      description: error,
      fatal: false,
    });
  };
  // app.config.warnHandler = () => null;

  window.onerror = function (message, source, lineno, colno, error) {
    exception({
      description: message,
      fatal: false,
    });
  };
}
app.mount('#app');

app.config.productionTip = false;
// app.config.globalProperties.$baseApi = process.env.BASE_API;

app.config.globalProperties.$dayjs = dayjs;
