import menuModule from './menuModule';
import layout from '@/views/layout/layout';
import mLayout from '@/views/layout/mLayout';
import emptyLayout from '@/views/layout/emptyLayout';
import {markRaw} from '@vue/reactivity';
import store from '../store';

export function getMenu(data, option) {
  let routers = [];
  for (const key in data) {
    if (key == 8 || key == 9) {
      continue;
    }
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      routers = [...routers, ...getMenuRoute(element, option?.firstUri)];
    }
  }
  const root = [
    {
      component: getComponent(option?.topLayout ?? 'layout'),
      children: routers,
      path: '/',
      name: 'root',
      meta: {
        title: '',
        isRoot: true,
      },
    },
  ];
  return root;
}
function getMenuRoute(data, firstUri) {
  if (!data) {
    return;
  }
  const routers = [];
  for (const item of data) {
    const menu = {};
    menu.path = item.path = item.path ?? '/';
    menu.hidden = item.hidden;
    menu.meta = {
      title: item.alias ?? item.title,
      icon: item.icon && item.icon != '' ? item.icon : 'icon-quan',
      id: item.id,
      customType: item.customType,
      code: item.code,
      firmId: item.firmId,
      groupId: item.groupId,
      subSystem: item.subSystem,
      permission: item.permission,
      hidden: item.hidden,
      linkUrl: item.linkUrl,
    };
    if (!item.name) {
      menu.component = getComponent();
      menu.children = getMenuRoute(item.children, firstUri);
    } else {
      menu.component = getComponent(item.component);
      if (item.subSystem === 2 && item.firmId != undefined) {
        menu.path = item.path.replace('/gatewayfirms', '/gatewayfirms/:firmId');
        menu.name = item.name + '-f' + item.firmId;
      } else if (item.subSystem === 4 && item.groupId != undefined) {
        menu.path = item.path.replace('/projects', '/projects/groups/:groupId');
        menu.name = item.name + '-g' + item.groupId;
      } else if (item.subSystem === 10 && item.groupId != undefined) {
        menu.path = item.path.replace('/corporations', '/corporations/groups/:groupId');
        menu.name = item.name + '-g' + item.groupId;
      } else {
        menu.path = item.path;
        menu.name = item.name + '-s';
      }
      item.children = undefined;
    }
    if (firstUri) {
      menu.path = firstUri + menu.path;
    }
    item.meta = menu.meta;
    item.name = menu.name;
    if (!JSON.stringify(routers).includes(JSON.stringify(menu))) {
      routers.push(menu);
    }
  }
  return routers;
}

function getComponent(component) {
  if (component == 'layout') {
    return markRaw(layout);
  } else if (component == 'mLayout') {
    return markRaw(mLayout);
  } else if (component == 'emptyLayout' || component == undefined) {
    return markRaw(emptyLayout);
  } else {
    return menuModule(component);
  }
}
