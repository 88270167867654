import request from '@/utils/request';
import axios from 'axios';
import store from '../store';
import Qs from 'qs';

const getMetricsParameter = function (metrics) {
  return encodeURIComponent(JSON.stringify(metrics));
};

export function getMetricsAnyGateway(groupId, auditStatus, metrics, startTime, endTime, page, size) {
  return request({
    url: '/iot/dataRepair/' + groupId + '/queryDataRepairList',
    method: 'get',
    params: {
      auditStatus: auditStatus,
      gatewayIndicatorId: metrics,
      start: startTime,
      end: endTime,
      page: page,
      size: size,
    },
  });
}

export function getDataRepairReason() {
  return request({
    url: '/iot/projects/auditRecordNoAuth/getDataRepairReason',
    method: 'get',
  });
}

export function getDataRepairReasonByGroupId(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/dataRepairReasons/getDataRepairReason',
    method: 'get',
  });
}

export function addDataRepairReason(groupId, data) {
  return request({
    url: '/iot/groups/' + groupId + '/dataRepairReasons',
    method: 'post',
    data: data,
  });
}

export function addDataRepair(groupId, data) {
  return request.postForm('/iot/dataRepair/' + groupId + '/save', data);
}

export function dupNameCheck(groupId, {name, id}) {
  return request({
    url: '/iot/dataRepair/' + groupId + '/dupNameCheck',
    method: 'get',
    params: {
      name: name,
      id: id,
    },
  });
}

export function queryDataRepairFileById(groupId, type, {id}) {
  return type === 'view'
    ? request({
        url: '/iot/projects/auditRecordNoAuth/queryDataRepairFileById/' + id,
        method: 'get',
      })
    : request({
        url: '/iot/dataRepair/' + groupId + '/queryDataRepairFileById/' + id,
        method: 'get',
      });
}

export function deleteDataRepair(groupId, id) {
  return request({
    url: '/iot/dataRepair/' + groupId + '/delete/' + id,
    method: 'delete',
  });
}

export function queryDataRepairAuditPath(groupId) {
  return request({
    url: '/iot/groups/' + groupId + '/dataRepairAuditPaths/queryDataRepairAuditPath',
    method: 'get',
  });
}
export function saveDataRepairAuditPath(groupId, {group, userSet}) {
  return request({
    url: '/iot/groups/' + groupId + '/dataRepairAuditPaths/saveDataRepairAuditPath',
    method: 'post',
    data: {
      group,
      userSet,
    },
  });
}

export function queryAuditResult(groupId, likeName, auditType, page, size) {
  return request({
    url: '/iot/projects/auditRecordNoAuth/queryAuditResult',
    method: 'get',
    params: {
      likeName: likeName,
      auditType: auditType,
      page: page,
      size: size,
    },
  });
}

export function auditRecordNoAuth({id, auditType, auditStatus, relationId, content}) {
  return request({
    url: '/iot/projects/auditRecordNoAuth',
    method: 'post',
    data: {
      id,
      auditType,
      auditStatus,
      relationId,
      content,
    },
  });
}

export function queryAuditRecordByType(groupId, {auditType, relationId}) {
  return request({
    url: '/iot/projects/auditRecordNoAuth/queryAuditRecordByType',
    method: 'get',
    params: {
      auditType,
      relationId,
    },
  });
}
